import React from 'react';
import Button from '@mui/material/Button';
import '../css/components.css';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
    min-width: 200px;
    height: 52px;
    box-shadow: none;
    color: #fff;
    padding: 0 16px;
    font-size: 16px;
    background: #4b4b4b;
    border-radius: 6px;
    border: 2px solid #4b4b4b;
    font-weight: bold;
    font-family: "NX Gothic";
    @media only screen and (max-width: 600px) {
      min-width: auto;
      padding: 0 4px;
    }
    &:hover {
      background: #6e6e6e;
		border-color: #6e6e6e;
      color: #fff;
    }
    ${({ outline }) => {
        if (outline) {
          return `
            background: #fff;
            border: 2px solid #4b4b4b;
            color: #4b4b4b;
            &:hover {
              background: #fff;
              color: #4b4b4b;
            }
          `;
        }
        return ``;
    }}
`;

const ButtonStandard = ({ outline, onClick, children, ...props }) => {
    return (
        <StyledButton onClick={onClick} outline={outline} {...props}>
            {children}
        </StyledButton>
    );
};

export default ButtonStandard;

