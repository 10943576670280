import styled from "styled-components";
function FormList({ children, ...props }) {
  return <Form {...props}>{children}</Form>;
}
const Form = styled.ul`
  width: 348px;
  margin: 60px auto 0;
  @media (max-width: 640px) {
    width: 100%;
    margin: 24px auto 0;
  }
`;
export default FormList;
