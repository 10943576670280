import styled from "styled-components";
import DriverIcon01 from "../../assets/images/icon-driver-pool-01.png";
import DriverIcon02 from "../../assets/images/icon-driver-pool-02.png";
import StyledTooltip from "./Text/StyledTooltip";
import TextBlock from "./Text/TextBlock";
import TooltipContents from "./Text/TooltipContents";
import TooltipTitle from "./Text/TooltipTitle";
function DriverTooltips() {
  return (
    <>
      <StyledTooltip id="driverPoolTip01">
        <TooltipTitle>
          <strong>드라이버 풀</strong>이란?
        </TooltipTitle>
        <TooltipContents>
          <TooltipFlex>
            <TooltipIcons src={DriverIcon01} alt="icon" />
            <TextBlock fontSize="14" fontWeight="700">
              * 드라이버 풀이 1명일 경우
            </TextBlock>
            <TextBlock fontSize="12">1명이 전담으로 운행하게 됩니다.</TextBlock>
          </TooltipFlex>
          <TooltipFlex>
            <TooltipIcons src={DriverIcon02} alt="icon" />
            <TextBlock fontSize="14" fontWeight="700">
              * 드라이버 풀이 3명일 경우
            </TextBlock>
            <TextBlock fontSize="12">
              3명 중 1명이 매칭되어 운행하게 됩니다.
            </TextBlock>
          </TooltipFlex>
        </TooltipContents>
      </StyledTooltip>

      <StyledTooltip id="driverPoolTip02">
        <TooltipTitle>
          <strong>추천 드라이버 수</strong>란?
        </TooltipTitle>
        <TooltipContents>
          <TooltipFlex>
            <TooltipIcons src={DriverIcon02} alt="icon" />
            
            <TextBlock fontSize="12">
              고객님의 서비스 만족도를<br/>높이기 위해 제공하는<br/>기본 드라이버 수 입니다.
            </TextBlock>
          </TooltipFlex>
        </TooltipContents>
      </StyledTooltip>
    </>
  );
}

const TooltipFlex = styled.div`
  position: relative;
  padding-left: 42px;
  @media (max-width: 640px) {
    padding-left: 32px;
  }
  p + p {
    margin-top: 3px;
    @media (max-width: 640px) {
      margin-top: 0;
    }
  }
  & + div {
    margin-top: 30px;
    @media (max-width: 640px) {
      margin-top: 16px;
    }
  }
`;
const TooltipIcons = styled.img`
  width: 34px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -17px;
  @media (max-width: 640px) {
    width: 22px;
    margin-top: -11px;
  }
`;
export default DriverTooltips;
