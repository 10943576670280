import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FloatLabel from '../../components/FloatLabel';
import FloatLabelSelect from '../../components/FloatLabelSelect';
import FloatLabelDate from '../../components/FloatLabelDate'
import FloatLabelFile from '../../components/FloatLabelFile'
import { ETC_ID } from '../../InitConst';
import Loading from '../../components/Loading';
import { 
    getLocalStorageDecrypt, 
    setLocalStorageEncrypt, 
    getSessionStorageDecrypt,
    setSessionStorageEncrypt,
    isChkString, 
    isChkEmail, 
    isChkPhone, 
    isBusanFlag, 
} from '../../lib/Util'
import { uploadFile } from '../../lib/MsAzureApi';
import Header from '../../components/Header';
import Inner from '../../components/Inner';
import TitleWithBorder from '../../components/TitleWithBorder';
import TitleFormLabel, { FormContainer } from '../../components/TitleFormLabel';
import { Grid } from '@mui/material';
import StepButtonGroup from '../../components/StepButtonGroup';
import ButtonStandard from '../../components/ButtonStandard';
import moment from 'moment';
import FirstTitle from '../../components/FirstTitle';
import FloatLabelDiscountSelect from '../../components/FloatLabelDiscountSelect';
import { setEstimatePage02Live } from '../../lib/GoogleSheetApi';
import KakaoButton from '../../components/KakaoButton';
import { BUILD_TARGET_URI } from '../../InitConst';
import { getMMProductList } from '../../lib/GoogleSheetApi'
import FloatLabelSelectMm from '../../components/FloatLabelSelectMm';

const BUILD_TARGET = BUILD_TARGET_URI
const ANNUAL_MOSILER = (document.location.hostname ==='year.mosiler.com' || document.location.hostname ==='yeartest.mosiler.com' || (document.location.href.indexOf('http://mosiler.com/annual') >= 0))

const ContractView = (props) => {
    const [loadingFlag, setLoadingFlag] = useState(true)
    const [formData, setFormData] = useState({
        CustomerType:'1',
        
        ContractName:'',
        ContractAddress:'',
        ContractAddress2:'',
        ContractBLNumber:'',
        BusinessLicense:'',

        BookerName:'',
        BookerEmail:'',
        BookerPhone:'',


        ContractStartDate:'',
        ContractMonth: ANNUAL_MOSILER ? '12' : '0',
        CarModel:'',
        Purpose:'0',
        PurposeEtcStr:'',
        ComplateBusinessLicenseUrl:'',

        CustomerTypeList:[
            {val:1, name:'개인'},
            {val:2, name:'사업자/법인'},
        ],
        PurposeList:[
            { val: 0, name: '-선택해주세요-' },
            {val:1, name:'회사 대표님 or 임원'},
            {val:2, name:'가족+임직원'},
            {val:3, name:'VIP고객'},
            {val:ETC_ID, name:'기타'},
        ],
        
        ContractList:[
            { val: 0, name: '-선택해주세요-' },
            {val:1, name:'1개월', discount:0},
            {val:3, name:'3개월', discount:0},
            {val:6, name:'6개월', discount:5},
            {val:12, name:'12개월', discount:7},
        ],
        // Route:'1',
        // StartArea:'',
        // EndArea:'',
        // RouteList:[
        //     {val:1, name:'인스타그램'},
        //     {val:2, name:'페이스북'},
        //     {val:3, name:'지인소개'},
        //     {val:4, name:'모시러 홈페이지'},
        //     {val:ETC_ID, name:'기타'},
        // ],
    })
    const [userInfo, setUserInfo] = useState({
        UserName:'',
        UserPhone:'',
        UserEmail:''
    })
    const [Intermediate, setIntermediate] = useState(false)
    const [selectedProductString, setSelectedProductString] = useState('')

    const [selectedProduct, setSelectedProduct] = useState({
        SelectedId:'0',
        ProductList: [
            { id: 1, productHour: 80, oneDayHour: 8, hourPrice: 28600, discountFlag: 1, discountPercent: 5, discountHourPrice: 27170, allPrice: 300, },
            { id: 101, productHour: 100, oneDayHour: 8, hourPrice: 28600, discountFlag: 1, discountPercent: 3, discountHourPrice: 29000, allPrice: 290, },
        ]
    })

    const history = useHistory();

    console.log(history.length)
    
    
    useEffect(() => {
        async function test() {
            const htmlTitle = document.querySelector("title");
            //htmlTitle.innerHTML = '월간모시러 웹 견적서'
            htmlTitle.innerHTML = ANNUAL_MOSILER ? '연간모시러 웹 견적서' : '월간모시러 웹 견적서'
            let tmpLSToken0 = getLocalStorageDecrypt('token0');
            let tmpSelectedProductString = localStorage.getItem('selectedProductString');

            // 처음 들어왔고 데이터가 없으니 qs 데이터 받아서 데이터 저장
            if (tmpLSToken0 === null)   {
                setIntermediate(true)
                // Page2의 데이터가 이미 저장되어있다면 
                let tmpLSToken2 = getLocalStorageDecrypt('token2');
                if (tmpLSToken2 !== null) {
                    setFormData({
                        ...formData,
                        CustomerType            :tmpLSToken2.CustomerType,

                        ContractName            :tmpLSToken2.ContractName,
                        ContractAddress         :tmpLSToken2.ContractAddress,
                        ContractAddress2        : tmpLSToken2.ContractAddress2,
                        ContractBLNumber        :tmpLSToken2.ContractBLNumber,
                        BusinessLicense         :tmpLSToken2.BusinessLicense,
                        
                        // 보통 랜딩페이지에서 부커가 처리할 것이기 때문에 부커 사전 정보가 존재할경우 반영.
                        BookerName              : tmpLSToken2.BookerName,
                        BookerEmail             : tmpLSToken2.BookerEmail,
                        BookerPhone             : tmpLSToken2.BookerPhone,
                        
                        ContractStartDate       :tmpLSToken2.ContractStartDate.length >= 1 ? new Date(tmpLSToken2.ContractStartDate) : new Date(),
                        ContractMonth           : ANNUAL_MOSILER ? '12' : tmpLSToken2.ContractMonth,
                        Purpose                 :tmpLSToken2.Purpose,
                        CarModel                :tmpLSToken2.CarModel,
                        
                        ComplateBusinessLicenseUrl : tmpLSToken2.ComplateBusinessLicenseUrl,

                        PurposeEtcStr : tmpLSToken2.PurposeEtcStr,
                    })
                }
            }
            // 기존에 들어왔고, 데이터가 있으니 ls의 데이터를 끌어와서 데이터를 업데이트한다.
            else {
                setUserInfo({
                    UserName:   tmpLSToken0.UserName,
                    UserPhone:  tmpLSToken0.UserPhone,
                    UserEmail:  tmpLSToken0.UserEmail,
                })

                // Page2의 데이터가 이미 저장되어있다면 
                let tmpLSToken2 = getLocalStorageDecrypt('token2');
                if (tmpLSToken2 !== null) {
                    setFormData({
                        ...formData,
                        CustomerType            :tmpLSToken2.CustomerType,

                        ContractName            :tmpLSToken2.ContractName,
                        ContractAddress         :tmpLSToken2.ContractAddress,
                        ContractAddress2        : tmpLSToken2.ContractAddress2,
                        ContractBLNumber        :tmpLSToken2.ContractBLNumber,
                        BusinessLicense         :tmpLSToken2.BusinessLicense,
                        
                        // 보통 랜딩페이지에서 부커가 처리할 것이기 때문에 부커 사전 정보가 존재할경우 반영.
                        BookerName              : tmpLSToken2.BookerName,
                        BookerEmail             : tmpLSToken2.BookerEmail,
                        BookerPhone             : tmpLSToken2.BookerPhone,
                        
                        ContractStartDate       :tmpLSToken2.ContractStartDate.length >= 1 ? new Date(tmpLSToken2.ContractStartDate) : new Date(),
                        ContractMonth           : ANNUAL_MOSILER ? '12' : tmpLSToken2.ContractMonth,
                        Purpose                 :tmpLSToken2.Purpose,
                        CarModel                :tmpLSToken2.CarModel,
                        
                        ComplateBusinessLicenseUrl : tmpLSToken2.ComplateBusinessLicenseUrl,

                        PurposeEtcStr : tmpLSToken2.PurposeEtcStr,
                    })
                }
                // 데이터가 존재하지 않을경우 Booker 데이터 반영
                else {
                    setFormData({
                        ...formData,
                        // 보통 랜딩페이지에서 부커가 처리할 것이기 때문에 부커 사전 정보가 존재할경우 반영.
                        BookerName              : tmpLSToken0.UserName.length >= 1 ? tmpLSToken0.UserName : '',
                        BookerEmail             : tmpLSToken0.UserEmail.length >= 1 ? tmpLSToken0.UserEmail : '',
                        BookerPhone             : tmpLSToken0.UserPhone.length >= 1 ? tmpLSToken0.UserPhone : '',
                    })
                }

            }

            if (tmpSelectedProductString === null) {
                setSelectedProductString('')
            }
            else {
                setSelectedProductString(tmpSelectedProductString)
            }

            let result = null;
            let tempSelectProduct = []
            let tempSelectId = '0'

            // 상품 정보 얻어오기
            let ssProductList = getSessionStorageDecrypt('token-1')
            if (ssProductList === null) {
                result = await getMMProductList();
                tempSelectProduct = [
                    {id:0,productHour:0,oneDayHour:0,discountFlag:0,discountPercent:0,hourPrice:0,discountHourPrice:0,allPrice:0},
                     ...result.MmList, ...result.YmList];
                setSessionStorageEncrypt('token-1', result)
            }
            else {
                tempSelectProduct = [
                    {id:0,productHour:0,oneDayHour:0,discountFlag:0,discountPercent:0,hourPrice:0,discountHourPrice:0,allPrice:0},
                    ...ssProductList.MmList, ...ssProductList.YmList];
                result = ssProductList;
            }

            if (isBusanFlag()) {
                tempSelectProduct = [
                    tempSelectProduct[0],
                    tempSelectProduct[3],
                    tempSelectProduct[4],
                    tempSelectProduct[5],
                ]
            }


            // Step1에서 선택한 상품 정보 취득
            let tmpLSToken1 = getLocalStorageDecrypt('token1');
            if (tmpLSToken1 !== null) {
                tempSelectId = tmpLSToken1.selectedId + ''
            }
            else {
                // No Action
                // setSelectedProductId(tmpLSToken1.selectedId + '')
            }

            setSelectedProduct({
                SelectedId: tempSelectId,
                ProductList: tempSelectProduct
            })

            setLoadingFlag(false)
        }
        test();
    }, [])

    const getListToValue = (val, list) => {
        let retData = ''
        for(let i in list) {
            if (Number(list[i].val) === Number(val)) {
                retData = list[i].name;
                break;
            }
        }
        return retData;

    }
    
    // all blobs in container
    const [blobList, setBlobList] = useState([]);

    // current file to upload into container
    const [fileSelected, setFileSelected] = useState(null);   

    const onFileChange = (event) => {
        // capture file into state
        setFileSelected(event.target.files[0]);
    };

    const onFileUpload = async () => {
        // *** UPLOAD TO AZURE STORAGE ***
        const blobsInContainer = await uploadFile(fileSelected);

        // reset state/form
        setFileSelected(null);

        return blobsInContainer
    };

    return (
        <>
            {loadingFlag ? <Loading /> : null}
            <KakaoButton />
            <div className='main_component'>
                <Header step="3" history={props.history} Intermediate={Intermediate}/>
                <div className='content'>
                    <Inner>
                        <FirstTitle title='계약정보 입력' />
                        <TitleWithBorder title="정보입력" />
                        <FormContainer>
                            <TitleFormLabel title="고객 유형" />
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabelSelect  formId={'CustomerType'} name={'고객유형'} value={formData.CustomerType} originData={formData} onChange={setFormData} list={formData.CustomerTypeList} />
                                </Grid>
                            </Grid>
                        </FormContainer>
                        <FormContainer>
                            <TitleFormLabel title="담당자 정보" />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabel  formId={'BookerName'} name={'담당자 성명'} value={formData.BookerName} originData={formData} onChange={setFormData}  placeholder=''/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabel  formId={'BookerEmail'} name={'담당자 Email'} value={formData.BookerEmail} originData={formData} onChange={setFormData} placeholder='' />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabel  formId={'BookerPhone'} name={'담당자 연락처'} value={formData.BookerPhone} originData={formData} onChange={setFormData} placeholder=''/>
                                </Grid>
                            </Grid>       
                        </FormContainer>
                        <FormContainer>
                            <TitleFormLabel title="계약자 정보" />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabel  formId={'ContractName'} name={'계약자 성함'} value={formData.ContractName} originData={formData} onChange={setFormData}  placeholder={formData.CustomerType === '2' ? '법인명, 대표자 성함' : ''}/>
                                </Grid>
                                {formData.CustomerType === '2' && (
                                    <>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                        <FloatLabel formId={'ContractBLNumber'} name={'사업자 등록번호'} value={formData.ContractBLNumber} originData={formData} onChange={setFormData} placeholder={'10자리 숫자만 입력'} />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                        {formData.ComplateBusinessLicenseUrl.length >= 1 ? 
                                            <FloatLabel formId={'ContractBLNumber'} name={'사업자 등록증 첨부'} value={'[업로드 완료]'} /> : 
                                            <FloatLabelFile  formId={'BusinessLicense'} name={'사업자 등록증 첨부'} onChange={setFileSelected} placeholder=' '/>
                                        }
                                    </Grid>
                                    </>
                                )}
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabel
                                        formId={'ContractAddress'}
                                        name={formData.CustomerType === '2' ? '사업자 등록증상의 주소' : '계약자 상세주소'}
                                        value={formData.ContractAddress}
                                        originData={formData}
                                        onChange={() => { }}
                                       
                                        onClick={(e) => {
                                            new window.daum.Postcode({
                                                oncomplete: function (data) {
                                                    // console.log(data)
                                                    setFormData({
                                                        ...formData,
                                                        ContractAddress: `${data.roadAddress.length >= 1 ? data.roadAddress : data.jibunAddress}`
                                                    })
                                                }
                                            }).open();
                                        }} placeholder='클릭/터치하여 상세주소입력' />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabel
                                        formId={'ContractAddress2'}
                                        name={'나머지 주소'}
                                        value={formData.ContractAddress2}
                                        originData={formData}
                                        onChange={setFormData}
                                        placeholder={'나머지 상세주소입력'} />
                                </Grid>
                                
                            </Grid>
                        </FormContainer>
                        
                        <FormContainer>
                            <TitleFormLabel title="이용관련 정보" />
                            <Grid container spacing={2}>
                                {/* 중간에 들어왔다면 비노출, page1부터 접근했다면 계약요청상품 노출 */}
                                {/* {(Intermediate) ? null : <>
                                    {selectedProductString.length >= 1 ? 
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                        <FloatLabel formId={'CarModel'} name={'계약 요청 상품'} value={selectedProductString} disabled />
                                    </Grid> : null}
                                </>
                                } */}
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabelSelectMm formId={'SelectedId'} name={'계약 요청 상품'} value={selectedProduct.SelectedId} originData={selectedProduct} onChange={setSelectedProduct} list={selectedProduct.ProductList}/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabelDate formId={'ContractStartDate'} name={'이용시작 예정일'} value={formData.ContractStartDate} originData={formData} onChange={setFormData} placeholder='클릭하여 날짜 지정'/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    {/* 프로모션 할인율 표시 당분간 비활성화
                                        <FloatLabelDiscountSelect formId={'ContractMonth'} name={'계약기간'} value={formData.ContractMonth} originData={formData} onChange={setFormData} list={formData.ContractList} /> 
                                    */}
                                    <FloatLabelSelect formId={'ContractMonth'} name={'계약기간'} value={formData.ContractMonth} originData={formData} onChange={setFormData} list={formData.ContractList} disabled={ANNUAL_MOSILER ? true : false }/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabel formId={'CarModel'} name={'차량모델명'} value={formData.CarModel} originData={formData} onChange={setFormData} placeholder='벤츠 S580' />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabelSelect formId={'Purpose'} name={'탑승자'} value={formData.Purpose} originData={formData} onChange={setFormData} list={formData.PurposeList} inputFormCondition={5} inputFormPlaceHolder='탑승자를 입력해주세요' />
                                </Grid>
                                {Number(formData.Purpose) === ETC_ID ? (
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabel formId={'PurposeEtcStr'} name={'탑승자 기타'} value={formData.PurposeEtcStr} originData={formData} onChange={setFormData} placeholder='' />
                                </Grid>
                            ) : null}
                            </Grid>
                        </FormContainer>
                    </Inner>
                    <Inner>
                    <StepButtonGroup flex={true} alignItems="center" justifyContent="flexEnd">
                        {Intermediate ? 
                            <ButtonStandard id='Step3Prev' outline onClick={() => {
                                props.history.replace(`${BUILD_TARGET}`);
                                props.history.push(`${BUILD_TARGET}/estimate`);
                            }}>이전</ButtonStandard> : 
                            <ButtonStandard id='Step3Prev' outline onClick={() => {
                                // history stack에 남아있는게 있는지 확인하려면? 보안문제땜에 못연다고함.
                                // history.length가 0~2까지일경우(이 경우는 알림톡에서 버튼을 눌러 페이지를 표시했다고 가정.) 
                                // token0, token1 데이터가 존재한다면
                                if (history.length >= 0 && history.length <= 2) {
                                    let tmpLSToken0 = getLocalStorageDecrypt('token0');
                                    let tmpLSToken1 = getLocalStorageDecrypt('token1');
                                    if (tmpLSToken0 !== null && tmpLSToken1 !== null) {
                                        props.history.replace(`${BUILD_TARGET}`);
                                        props.history.push(`${BUILD_TARGET}/estimate`);
                                        props.history.push(`${BUILD_TARGET}/estimate_result`);
                                    }
                                    else {
                                        props.history.replace(`${BUILD_TARGET}`);
                                        props.history.push(`${BUILD_TARGET}/estimate`);
                                    }
                                }
                                // 본 페이지에서 3번 이상 왔다갔다 했을 경우 뒤로가기 처리 한다.
                                else {
                                    props.history.goBack()
                                }
                            }}>이전</ButtonStandard>
                        }
                        
                        <ButtonStandard id='Step3Next' onClick={async ()=>{

                            const getMmProductStr = () => {
                                let retData = ''
                                let tmpSelectedId = Number(selectedProduct.SelectedId)
                                let tmpProduct = selectedProduct.ProductList.filter((data) => data.id === tmpSelectedId)
                                if (tmpSelectedId >= 1 && tmpSelectedId <= 1000) {
                                    retData += `[월간]`
                                }
                                else {
                                    retData += `[연간]`
                                }

                                retData += `${tmpProduct[0].productHour}시간 상품 1일 최소 ${tmpProduct[0].oneDayHour}시간 단가:${tmpProduct[0].discountHourPrice}`
                                return retData;
                            }

                            // Validation Check
                            let checkArticleList = [];

                            // 개인일때
                            if (Number(formData.CustomerType) === 1) {
                                if (!isChkString(formData.ContractName)) {
                                    checkArticleList.push('계약자 성함')
                                }
                                if (!isChkString(formData.ContractAddress)) {
                                    checkArticleList.push('계약자 상세주소')
                                }
                                if (!isChkString(formData.ContractAddress2)) {
                                    checkArticleList.push('나머지 주소')
                                }
                                
                                // 계약자 성함
                                // 계약자 상세주소
                                
                            }
                            // 사업자/법인일때
                            else {
                                
                                if (!isChkString(formData.ContractName)) {
                                    checkArticleList.push('계약자 성함')
                                }
                                if (!isChkString(formData.ContractAddress)) {
                                    checkArticleList.push('사업자 등록증상의 주소')
                                }
                                if (!isChkString(formData.ContractAddress2)) {
                                    checkArticleList.push('나머지 주소')
                                }
                                if (!isChkString(formData.ContractBLNumber) || formData.ContractBLNumber.length !== 10) {
                                    checkArticleList.push('사업자 등록번호')
                                }
                                if (formData.ComplateBusinessLicenseUrl.length) {
                                    // 미처리
                                }
                                else {
                                    if (fileSelected === null || fileSelected === undefined) {
                                        checkArticleList.push('사업자 등록증 첨부')
                                    }
                                }
                                
                                
                                
                                // 계약자 성함
                                // 사업자 등록증상의 주소
                                // 사업자 등록번호
                                // 파일 선택
                                // 담당자 성명
                                // 담당자 Email
                                // 담당자 연락처
                            }
                            
                            // 공통 체크
                            
                            if (!isChkString(formData.BookerName)) {
                                checkArticleList.push('담당자 성명')
                            }
                            if (!isChkEmail(formData.BookerEmail)) {
                                checkArticleList.push('담당자 Email')
                            }
                            if (!isChkPhone(formData.BookerPhone)) {
                                checkArticleList.push('담당자 연락처')
                            }
                            if (selectedProduct.SelectedId === '0') {
                                checkArticleList.push('계약 요청 상품')
                            }
                            if (!moment(formData.ContractStartDate).isValid()) {
                                checkArticleList.push('이용시작 예정일')
                            }
                            if (formData.ContractMonth === '0') {
                                checkArticleList.push('계약기간')
                            }
                            
                            if (!isChkString(formData.CarModel)) {
                                checkArticleList.push('차량모델명')
                            }
                            if (formData.Purpose === '0') {
                                checkArticleList.push('탑승자')
                            }
                            if ((Number(formData.Purpose) === ETC_ID) && (!isChkString(formData.PurposeEtcStr))) {
                                checkArticleList.push('탑승자 기타')
                            }
                            
                            if (checkArticleList.length >= 1) {
                                let msg = '아래 항목을 입력해주세요.\n'
                                for(let i in checkArticleList) {
                                    msg += `  - ${checkArticleList[i]}\n`
                                }
                                alert(msg)
                                return;
                            }
                            console.log(selectedProduct)


                            setLoadingFlag(true)
                            // 개인일때
                            if (Number(formData.CustomerType) === 1) {
                                // Contract Process
                                
                                let infoPage2 = {
                                    CustomerType: formData.CustomerType,

                                    ContractName: formData.ContractName,
                                    ContractAddress: formData.ContractAddress,
                                    ContractAddress2: formData.ContractAddress2,
                                    ContractBLNumber: formData.ContractBLNumber,
                                    BusinessLicense: formData.BusinessLicense,

                                    BookerName: formData.BookerName,
                                    BookerEmail: formData.BookerEmail,
                                    BookerPhone: formData.BookerPhone,

                                    ContractStartDate: formData.ContractStartDate,
                                    ContractMonth: formData.ContractMonth,
                                    Purpose: formData.Purpose,
                                    CarModel: formData.CarModel,

                                    ComplateBusinessLicenseUrl: '',

                                    PurposeEtcStr: formData.PurposeEtcStr,

                                    CustomerTypeString: getListToValue(formData.CustomerType, formData.CustomerTypeList),
                                    PurposeString: getListToValue(formData.Purpose, formData.PurposeList),
                                    ContractMonthString: getListToValue(formData.ContractMonth, formData.ContractList),
                                    RouteString: getListToValue(formData.Route, formData.RouteList),
                                    ContractReqProductString: getMmProductStr()
                                    //...result
                                }
                                
                                await setEstimatePage02Live(infoPage2)

                                setLocalStorageEncrypt('token2', infoPage2)
                            }
                            // 사업자일때
                            else {
                                // Contract Process
                                let result = await onFileUpload();
                                let infoPage2 = {
                                    CustomerType: formData.CustomerType,

                                    ContractName: formData.ContractName,
                                    ContractAddress: formData.ContractAddress,
                                    ContractAddress2: formData.ContractAddress2,
                                    ContractBLNumber: formData.ContractBLNumber,
                                    BusinessLicense: formData.BusinessLicense,

                                    BookerName: formData.BookerName,
                                    BookerEmail: formData.BookerEmail,
                                    BookerPhone: formData.BookerPhone,

                                    ContractStartDate: formData.ContractStartDate,
                                    ContractMonth: formData.ContractMonth,
                                    Purpose: formData.Purpose,
                                    CarModel: formData.CarModel,

                                    ComplateBusinessLicenseUrl: formData.ComplateBusinessLicenseUrl.length === 0 ? result.url : formData.ComplateBusinessLicenseUrl,

                                    PurposeEtcStr: formData.PurposeEtcStr,

                                    CustomerTypeString: getListToValue(formData.CustomerType, formData.CustomerTypeList),
                                    PurposeString: getListToValue(formData.Purpose, formData.PurposeList),
                                    ContractMonthString: getListToValue(formData.ContractMonth, formData.ContractList),
                                    RouteString: getListToValue(formData.Route, formData.RouteList),
                                    ContractReqProductString: getMmProductStr()
                                    //...result
                                }
                                
                                await setEstimatePage02Live(infoPage2)

                                setLocalStorageEncrypt('token2', infoPage2)
                            }

                            let tmpToken1 = getLocalStorageDecrypt('token1')
                            if (tmpToken1 != null) {
                                let tmpSelectedId = Number(selectedProduct.SelectedId)
                                let tmpProduct = selectedProduct.ProductList.filter((data) => data.id === tmpSelectedId)
                                tmpToken1 = {
                                    ...tmpToken1,
                                    productCategory: (tmpSelectedId >= 1 && tmpSelectedId <= 1000) ? 0 : 1,
                                    selectedId: tmpSelectedId,
                                    customHour: 0,
                                    customPrice: 0,
                                    selectedHour: tmpProduct[0].productHour,
                                    selectedHourPrice: tmpProduct[0].discountHourPrice,
                                    selectedOneDayHour: tmpProduct[0].oneDayHour
                                }
                                setLocalStorageEncrypt('token1', tmpToken1)
                            }

                            setLoadingFlag(false)


                            // 중도 입력했다면 계약 내용 확인 화면에는 다르게 나와야 한다.
                            if (Intermediate)
                                props.history.push(`${BUILD_TARGET}/contract_result?intermediate=1`)
                            // 기존 프로세스대로 입력했다면 최종 견적서가 나와야함.
                            else 
                                props.history.push(`${BUILD_TARGET}/contract_result`)
                        }}>계약 요청하기</ButtonStandard>
                    </StepButtonGroup>
                    </Inner>
                </div>
            </div>
        </>
    );
};

export default ContractView;

