import React from 'react';
import Button from '@mui/material/Button';
import '../css/components.css';
import styled from '@emotion/styled';
import IconChecked from '../img/icon_check24x24.png';

const StyledButtonCheck = styled(Button)`
    width: 100%;
    height: 90px;
    box-shadow: none;
    background-color: #fff;
    color: #ababab;
    padding: 0 30px;
    border: 2px solid #d9d9d9;
    border-radius: 12px;
    @media only screen and (max-width: 600px) {
        padding: 0 4px;
    }
    .button-text {
        font-weight: bold;
        font-size: 20px;
        @media only screen and (max-width: 600px) {
            font-size: 16px;
        }
    }
    .button-subtext {
        font-size: 16px;
        @media only screen and (max-width: 600px) {
            font-size: 12px;
        }
    }
    &:hover {
        background-color: inherit;
    }
    &.button_selected {
        background-color: #4b4b4b;
        border: 2px solid #4b4b4b;
        color: #fff;
        .button-text {
            background: url(${IconChecked}) no-repeat left center;
            background-size: 24px;
            padding-left: 32px;
        }
    }
    ${({ flex }) => {
        if (flex) {
            return `
            display: flex;
            align-items: center;
            justify-content: space-between;
          `;
        }
        return ``;
    }}
    @media only screen and (max-width: 1440px) {
        text-align: center;
        .button-subtext {
            display: block;
        }
        ${({ flex }) => {
        if (flex) {
            return `
                display: block;
            `;
        }
        return ``;
    }}
    }
`;

const ButtonWithCheck = ({ variant, isSelected, onClick, children, flex, ...props }) => {
    return (
        <StyledButtonCheck variant={variant || `contained`} onClick={onClick} flex={flex} {...props}>
            {children}
        </StyledButtonCheck>
    );
};

export default ButtonWithCheck;

