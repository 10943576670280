import React from 'react';
import '../css/components.css';
import styled from '@emotion/styled';
import ImgLogo from '../img/logo.png';
import IconStep1 from '../img/icon_nav_01.png';
import IconStep2 from '../img/icon_nav_02.png';
import IconStep3 from '../img/icon_nav_03.png';
import IconStep4 from '../img/icon_nav_04.png';
import IconCheck from '../img/icon_check24x24.png';
import { NavLink } from 'react-router-dom';
import { getLocalStorageDecrypt } from '../lib/Util'
import { BUILD_TARGET_URI } from '../InitConst';
const BUILD_TARGET = BUILD_TARGET_URI

const HeaderWithNavigation = styled.header`
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #4b4b4b;
    @media only screen and (max-width: 900px) {
        position: static;
        width: 100%;
        height: 64px;
        align-items: center;
        justify-content: center;
    }
`;
const HeaderLogo = styled.h1`
    text-align: center;
    margin: 32px 0;
    @media only screen and (max-width: 900px) {
        width: 48px;
        margin: 0 auto;
        img {
            max-width: 100%;
        }
    }
`;
const StepNavigationLists = styled.ul`
    @media only screen and (max-width: 900px) {
        display: none;
    }
`;
const StepNavigationItem = styled.li`
    height: 56px;
    padding: 0 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    letter-spacing: -0.5px;
    ${({ isActive }) => {
        if (isActive) {
            return `
            background: #393939;
            `
        }
        return ``;
    }}
`;
const NaviIcon = styled.span`
    padding-right: 12px;
    display: inline-block;
    img {
        width: 16px;
    }
`;
const NaviText = styled.span``;

const Header = ({ step, isActive, children, ...props }) => {
    // 어느 스텝으로 넘어가려할때, 관련 데이터가 존재하는지를 확인해야하고
    // 관련 데이터가 존재하면 페이지이동, 데이터가 없으면 이동시키면 안된다.
    return (
        <HeaderWithNavigation {...props}>
            <HeaderLogo>
                <img src={ImgLogo} alt="Mosiler" />
            </HeaderLogo>
            <StepNavigationLists>
                {props.Intermediate ? null :
                    <NavLink to="/" onClick={(e) => {
                        e.preventDefault();
                        let tokenChkResult = false;
                        let token0 = getLocalStorageDecrypt('token0');      // 고객정보
                        // let token1 = getLocalStorageDecrypt('token1');      // page1 정보
                        // let token2 = getLocalStorageDecrypt('token2');      // page1 정보
                        if (token0 !== null) {
                            tokenChkResult = true;
                        }

                        if (tokenChkResult) {
                            props.history.push(`${BUILD_TARGET}/estimate`)
                        }



                    }}>
                        <StepNavigationItem isActive={step === `1`}>
                            <NaviIcon>
                                <img src={IconStep1} alt="step1" />
                            </NaviIcon>
                            <NaviText>상품 선택 및 기본정보 입력</NaviText>
                        </StepNavigationItem>
                    </NavLink>

                }

                {props.Intermediate ? null :
                    <NavLink to="/" onClick={(e) => {
                        e.preventDefault();
                        let tokenChkResult = false;
                        let token0 = getLocalStorageDecrypt('token0');      // 고객정보
                        let token1 = getLocalStorageDecrypt('token1');      // page1 정보
                        // let token2 = getLocalStorageDecrypt('token2');      // page1 정보
                        if (token0 !== null && token1 !== null) {
                            tokenChkResult = true;
                        }

                        if (tokenChkResult) {
                            props.history.push(`${BUILD_TARGET}/estimate_result`)
                        }
                        else {
                            alert('페이지를 이동할 수 없습니다.\n"상품 선택 및 기본정보 입력" 화면에\n 내용 입력후 견적 확인하기 버튼을 눌러주세요.')
                        }

                    }}>
                        <StepNavigationItem isActive={step === `2`}>
                            <NaviIcon>
                                <img src={IconStep2} alt="step2" />
                            </NaviIcon>
                            <NaviText>견적 내용 확인</NaviText>
                        </StepNavigationItem>
                    </NavLink>
                }



                <NavLink to="/" onClick={(e) => {
                    e.preventDefault();
                    let tokenChkResult = false;
                    let token0 = getLocalStorageDecrypt('token0');      // 고객정보
                    let token1 = getLocalStorageDecrypt('token1');      // page1 정보
                    // let token2 = getLocalStorageDecrypt('token2');      // page1 정보
                    if (token0 !== null && token1 !== null) {
                        tokenChkResult = true;
                    }

                    if (tokenChkResult) {
                        props.history.push(`${BUILD_TARGET}/contract`)
                    }
                    else {
                        alert('페이지를 이동할 수 없습니다.\n"상품 선택 및 기본정보 입력" 화면에\n 내용 입력후 견적 확인하기 버튼을 눌러주세요.')
                    }


                }}>
                    <StepNavigationItem isActive={step === `3`}>
                        <NaviIcon>
                            <img src={IconStep3} alt="step3" />
                        </NaviIcon>
                        <NaviText>계약정보 입력</NaviText>
                    </StepNavigationItem>
                </NavLink>


                <NavLink to="/" onClick={(e) => {
                    e.preventDefault();
                    let tokenChkResult = false;
                    let token0 = getLocalStorageDecrypt('token0');      // 고객정보
                    let token1 = getLocalStorageDecrypt('token1');      // page1 정보
                    let token2 = getLocalStorageDecrypt('token2');      // page1 정보
                    if (token0 !== null && token1 !== null && token2 !== null) {
                        tokenChkResult = true;
                    }

                    if (tokenChkResult) {
                        props.history.push(`${BUILD_TARGET}/contract_result`)
                    }
                    else {
                        alert('페이지를 이동할 수 없습니다.\n"계약정보 입력" 화면에\n 내용 입력후 계약 요청하기 버튼을 눌러주세요.')
                    }

                }}>
                    <StepNavigationItem isActive={step === `4`}>
                        <NaviIcon>
                            <img src={IconStep4} alt="step4" />
                        </NaviIcon>
                        <NaviText>계약 내용 확인</NaviText>
                    </StepNavigationItem>
                </NavLink>

                <StepNavigationItem isActive={step === `5`}>
                    <NaviIcon>
                        <img src={IconCheck} alt="step5" />
                    </NaviIcon>
                    <NaviText>완료</NaviText>
                </StepNavigationItem>

            </StepNavigationLists>
        </HeaderWithNavigation>
    );
};

export default Header;