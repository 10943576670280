import React from 'react';
import styled from '@emotion/styled';
import IconDownload from '../img/icon_download.png';

const StyledButtonDownload = styled.button`
    outline: 0;
    border: 0;
    font-size: 16px;
    color: #4b4b4b;
    padding-right: 28px;
    background: url(${IconDownload}) no-repeat right center;
    background-size: 24px;
    @media only screen and (max-width: 600px) {
        font-size: 14px;
        background-size: 20px;
        padding-right: 24px;
    }
`;

const ButtonDownload = ({ onClick, children, ...props }) => {
    return (
        <StyledButtonDownload onClick={onClick} {...props}>
            {children}
        </StyledButtonDownload>
    );
};

export default ButtonDownload;