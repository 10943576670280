import React from 'react';
import Button from '@mui/material/Button';
import '../css/components.css';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
    width: 100%;
    box-shadow: none;
    color: #2d2d2d;
    padding: 0;
    border-radius: 12px;
    display: block;
    font-size: 16px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    border: 3px solid transparent;
    overflow: hidden;
    position: relative;
    &.button_selected {
        border: ${({ borderColor }) => `3px solid ${borderColor}`};
    }
    &:hover {
        background-color: inherit;
    }
    .pd_header {    
        background-color: ${({ bgColor }) => bgColor};
    }
    @media only screen and (max-width: 600px) {
        padding-left: 46px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .pd_header {
            padding: 0 6px;
            font-size: 18px;
            width: 90px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            line-height: normal;
        }
        .pd_body {
            margin: 16px 0;
            padding: 0 12px;
            border-right: 1px solid #DCDBFC;          
            width: 100%;  
        }
        .pd_content {
            font-size: 14px;
            line-height: normal;
            .pd_oneDayHour {
                margin: 0;
            }
        }
        .pd_content3 {
            border-bottom: none;
            padding-bottom: 0;
            strong {
                font-size: 12px;
            }
            .price_container {
                .delete_price {
                    font-size: 12px;
                }
                .result_price {
                    font-size: 22px;
                    margin-top: 0;
                }
                .discount_percent {
                    font-size: 12px;
                    top: 8px;
                }
            }
        }
        .pd_footer {
            text-align: center;
            padding: 0 24px;
            font-size: 18px;
            span {
                display: block;
                font-size: 30px;
            }
        }
    }
`;

const ButtonWithProduct = ({ variant, isActive, bgColor, borderColor, onClick, children, ...props }) => {
    return (
        <StyledButton variant={variant || `contained`} onClick={onClick} bgColor={bgColor} borderColor={borderColor} {...props}>
            {children}
        </StyledButton>
    );
};

export default ButtonWithProduct;

