import React from 'react';
import styled from '@emotion/styled';

const ResultContainer = styled.div`
    height: 80px;
    padding: 0 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 20px;
    border: 2px solid #4b4b4b;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    @media only screen and (max-width: 600px) {
        font-size: 18px;
        padding: 0 20px;
    }
`;
const ResultTitle = styled.h4``;
const ResultContents = styled.div`
    color: #888;
    .price {
        color: #706ed1;
    }
`;

const ResultPrice = ({  children, ...props }) => {
    return (
        <ResultContainer>
            <ResultTitle>
                {/* productData.productCategory === 0 ? '월간모시러' : '연간모시러' */} 
                {props.ProductCategory === 0 ? '월 금액' : '총 금액'}
            </ResultTitle>
            <ResultContents><span className="price">{children}</span> 원</ResultContents>
        </ResultContainer>
    );
};

export default ResultPrice;
