import React from 'react';

const KakaoButton = (props) => {
    const onKakaoButton = () => {
        window.open('http://pf.kakao.com/_uxmjlM/chat', '_blank', 'width=400,height=600')
    }

    return (
        <div className="scroll-to-top">
            <button id="channel-add-button" className="btn-kakao-qna" onClick={()=>onKakaoButton()}></button>
        </div>
    );
};

export default KakaoButton;