import "aos/dist/aos.css";
import React from "react";
import styled from "styled-components";
import ProcessImageMobile from "../../assets/images/img-partner-process-mobile.png";
import ProcessImage from "../../assets/images/img-partner-process.png";
import { Mobile, Pc, Tablet } from "../../hooks/useMediaQuery";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import SubTitle from "./Text/SubTitle";
import TextBlock from "./Text/TextBlock";

function PartnerContainer({ type, ...props }) {
  return (
    <Section padding="100px 0" background="#FAFAFA">
      <InnerSection>
        <SubTitle fontSize="40" data-aos="fade-up">
        {type === 'ym' ? '연간 모시러' : '월간 모시러'} 파트너 교육
        </SubTitle>
        <TextBlock fontSize="24" data-aos="fade-up">
          최고의 서비스를 제공해 드리는 모시러의 파트너를 소개합니다.
        </TextBlock>
        <Box data-aos="fade-up">
          <Pc>
            <PartnerProcessImage src={ProcessImage} />
          </Pc>
          <Tablet>
            <PartnerProcessImage src={ProcessImage} />
          </Tablet>
          <Mobile>
            <PartnerProcessImage src={ProcessImageMobile} />
          </Mobile>
        </Box>
        <Pc>
          <TextBlock textAlign="center" data-aos="fade-up">
            <strong>모시러의 자체 검증 시스템</strong>을 통과한 드라이버들이
            서비스를 제공합니다. <br />
            의전 교육 뿐만 아니라 범죄 이력 조회 및 고객 비밀 유지 서약서 작성
            등 까다로운 과정을 거쳐야만 모시러의 파트너가 될 수 있습니다.
          </TextBlock>
        </Pc>
        <Mobile>
          <TextBlock textAlign="center" data-aos="fade-up">
            <strong>모시러의 자체 검증 시스템</strong>을 통과한 <br />
            드라이버들이 서비스를 제공합니다. <br />
            의전 교육 뿐만 아니라 범죄 이력 조회 및 <br />
            고객 비밀 유지 서약서 작성 등 <br />
            까다로운 과정을 거쳐야만 <br />
            모시러의 파트너가 될 수 있습니다.
          </TextBlock>
        </Mobile>
      </InnerSection>
    </Section>
  );
}
const Box = styled.div`
  text-align: center;
  margin: 60px 0 28px 0;
  @media (max-width: 640px) {
    margin: 40px 0 32px 0;
  }
`;
const PartnerProcessImage = styled.img`
  max-width: 100%;
  @media (max-width: 640px) {
    width: 179px;
  }
`;
export default PartnerContainer;
