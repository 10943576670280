import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    width: 100%;
    padding: 0 46px 30px 46px;
    @media only screen and (max-width: 900px) {
        padding: 0 0 55px 0;
    }
`;

const Inner = ({ children }) => {
    return (
        <Container>{children}</Container>
    );
};

export default Inner;

