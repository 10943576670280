import axios from 'axios';
import moment from 'moment'
import qs from 'qs'
import { ETC_ID } from '../InitConst';
import { getEnglishDayToKorean } from './Util';

const sheetDocId = '1ImPhKIqNr1-VQdlY88CKRvIeBeZduTG23MKvsLGztvw';
// https://docs.google.com/spreadsheets/d/1uJjCCxCU41rN_9TQGXEz97gnjVx6kHfmlhI06ifXMFM/gviz/tq?sheet=customeraddress&tq=select D,E where B=17762
//const saveGoogleSheet = 'https://script.google.com/macros/s/AKfycby7MjtczlnvfSTUr0rpZ_aVBu19HOWBa8eXDU9Io5G8V5i9xie16z9yI0negUBghnKO/exec'
const saveGoogleSheet = 'https://script.google.com/macros/s/AKfycbxjIcch35acFwYe3pRxJFSqTiV6kOO-KB9ZMnbzhfUsHpdczpwDPZ7NseeRGOPpFoHWPw/exec'

const parseGoogleSheet = (str) => {
    const firstSplit = str.split('google.visualization.Query.setResponse(')[1]
    const jsonData = JSON.parse(firstSplit.slice(0, firstSplit.length - 2)) 
    return jsonData.table;
}

export const getMMProductList = () => {
    let query1 = `select A,B,C,D,E order by A asc`;     // 월간모시러 상품
    let query2 = `select G,H,I,J order by G asc`;       // 월간모시러 커스터마이징 상품
    let query3 = `select L,M,N,O,P,Q order by G asc`;   // 연간모시러 상품
    let sheet = '상품'
       
    return new Promise(async (resolve, reject)=>{
        const uri = `https://docs.google.com/spreadsheets/d/${sheetDocId}/gviz/tq?sheet=${sheet}&tq=${query1}`
        const uri2 = `https://docs.google.com/spreadsheets/d/${sheetDocId}/gviz/tq?sheet=${sheet}&tq=${query2}`
        const uri3 = `https://docs.google.com/spreadsheets/d/${sheetDocId}/gviz/tq?sheet=${sheet}&tq=${query3}`
        
        let result = await axios.get(uri);
        let result2 = await axios.get(uri2);
        let result3 = await axios.get(uri3);
        
        let tmpArr = [];
        let calcArr = [];
        let ymArr  = [];
        let arrList = parseGoogleSheet(result.data).rows;
        let arrList2 = parseGoogleSheet(result2.data).rows;
        let arrList3 = parseGoogleSheet(result3.data).rows;

        for(let i in arrList2) {
            // 커스텀 불가라고 적힌 것이라던가 null인 경우는 다음idx로 넘김
            if (arrList2[i].c[0] === null || arrList2[i].c[0] === undefined || arrList2[i].c[3].v === null) {
                continue;
            }

            calcArr.push({
                id:     arrList2[i].c[0].v,
                min:    arrList2[i].c[1].v,
                max:    arrList2[i].c[2].v,
                price:  arrList2[i].c[3].v,
            })
        }

        for(let i in arrList3) {
            // 커스텀 불가라고 적힌 것이라던가 null인 경우는 다음idx로 넘김
            if (arrList3[i].c[0] === null || arrList3[i].c[0] === undefined || arrList3[i].c[3].v === null) {
                continue;
            }
            if (Number(arrList3[i].c[5].v) === 1) { 
                let productHourData = Number(arrList3[i].c[1].v)
                let hourPriceData = Number(arrList3[i].c[4].v)

                ymArr.push({
                    id                  : arrList3[i].c[0].v,
                    productHour         : productHourData,
                    oneDayHour          : arrList3[i].c[2].v,
                    discountFlag        : (Number(arrList3[i].c[3].v) !== 0) ? 1 : 0,
                    discountPercent     : Number(arrList3[i].c[3].v),
                    hourPrice           : 30000,
                    discountHourPrice   : hourPriceData,
                    allPrice            : ((hourPriceData * productHourData)+'').substring(0,3)
                })
            }

            
        }

        const getCalcCustomToPrice = (tcArr, hour) => {
            let retData = 0;
            for (let i in tcArr) {
                if (hour >=  tcArr[i].min  && hour <= tcArr[i].max-1  ) {
                    retData = tcArr[i].price
                }
            }
            return retData;
        }

        
        for(let i in arrList) {
            if (arrList[i].c[0] === null || arrList[i].c[0] === undefined ) {
                continue;
            }
            
            // 월간모시러 상품 시트상 E열의 값이 1이면 노출, 0이면 비노출
            if (Number(arrList[i].c[4].v) === 1) {
                let productHourData = Number(arrList[i].c[1].v) * Number(arrList[i].c[2].v)
                let hourPriceData = getCalcCustomToPrice(calcArr, productHourData)
                tmpArr.push({
                    id              : Number(arrList[i].c[0].v),
                    productHour     : productHourData,
                    oneDayHour      : Number(arrList[i].c[2].v),
                    discountFlag    : (Number(arrList[i].c[3].v) !== 0) ? 1 : 0,
                    discountPercent : Number(arrList[i].c[3].v),
                    hourPrice       : 30000,
                    discountHourPrice: hourPriceData,
                    allPrice        : ((hourPriceData * productHourData)+'').substring(0,3)
                })
                
            }
        }
        /*
        {
            id: 1,
            productHour: 80,
            oneDayHour: 8,
            discountFlag: 1,
            discountPercent: 5,
            
            hourPrice: 28600,
            discountHourPrice: 27170,
            allPrice: 300,
        }
         */
        
        
        resolve({
            Custom:calcArr,
            MmList:tmpArr,
            YmList:ymArr,
        })
    })
}

export const getDateAvaliableDate = () => {
    let query = `select A order by A asc`;
    let sheet = '허용'
    
    return new Promise(async (resolve, reject)=>{
        const uri = `https://docs.google.com/spreadsheets/d/${sheetDocId}/gviz/tq?sheet=${sheet}&tq=${query}`
        let result = await axios.get(uri);
        
        let tmpArr = [];
        let arrList = parseGoogleSheet(result.data).rows;

        for(let i in arrList) {
            let tmpData = arrList[i].c
            tmpArr.push(tmpData[0].v)
        }
        
        resolve(tmpArr)
    })
}

export const data2 = () => {
    return new Promise(async (resolve, reject)=>{
        resolve()
    })
}

export const getAvaliableTime = () => {
    let query = `select A, B order by A asc`;
    let sheet = '교육시간'
    
    return new Promise(async (resolve, reject)=>{
        const uri = `https://docs.google.com/spreadsheets/d/${sheetDocId}/gviz/tq?sheet=${sheet}&tq=${query}`
        let result = await axios.get(uri);
        
        let tmpArr = [];
        let arrList = parseGoogleSheet(result.data).rows;

        for(let i in arrList) {
            let tmpData = arrList[i].c
            tmpArr.push({
                date:tmpData[0].f,
                flag:tmpData[1].f,
            })
        }
        
        resolve(tmpArr)
    })
}

/*
    UserName:'',
    UserPhone:'',
    UserEmail:''
    selectedHour: Number(customHour),
    selectedHourPrice: Number(customPrice),
    selectedOneDayHour: 8
*/
//예약 완료시 쇼퍼드리븐 Reserve 시트에 내용 추가
export const setEstimatePage01 = (obj, userinfo) => {
    const saveSheet = '견적'
    let form = qs.stringify({
        '고객이름': userinfo.UserName,
        '전화번호': userinfo.UserPhone,
        '이메일주소': userinfo.UserEmail,

        '상품카테고리': obj.productCategory === 0 ? '월간모시러' : '연간모시러',
        '월시간': obj.selectedHour,
        '최소이용시간': obj.selectedOneDayHour,
        '단가': obj.selectedHourPrice,

        '출발지,종료지': obj.formData.StartArea + "/" + obj.formData.EndArea,
        '서비스 이용 시점': obj.UseServiceString,
        '알게된 경로': obj.RouteString,
        '알게된 경로(기타)': obj.formData.RouteEtcStr,
        '추가 요청 내용': obj.formData.EtcRequest,
        'timestamp': moment().format('yyyy-MM-DD HH:mm')
    });





    /*
    1. 성함	
    2. 연락처	
    3. 이메일 주소	
    4. 출발지와 종료지 주소를 알려주세요	
    5. 월간 모시러의 어떤 상품 사용을 원하시나요? 	
    6. 서비스 이용을 하신다면 시점은 언제인가요? 	
    7. 월간 모시러를 알게 된 경로는 어떻게 되시나요? 	
    8. 특별히 요청할 내용이 있으면 기재해 주시기 바랍니다.
    9. 컨시어지 상담을 원하시면 알려주세요.
    RouteString: getListToValue(formData.Route, formData.RouteList),
                                        UseServiceString: getListToValue(formData.UseService, formData.UseServiceList),
    */

    return new Promise( (resolve, reject)=>{
        axios({
            url : `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then( async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve('fail')
        });
    })
}

export const setEstimatePage01Live = (obj, userinfo) => {
    const saveSheet = '견적응답(웹)'
    let form = qs.stringify({
        '1. 성함': userinfo.UserName,
        '2. 연락처': userinfo.UserPhone,
        '3. 이메일 주소': userinfo.UserEmail,
        '4. 출발지와 종료지 주소를 알려주세요': obj.formData.StartArea + "/" + obj.formData.EndArea,
        '5. 월간 모시러의 어떤 상품 사용을 원하시나요? ' : (obj.productCategory === 0 ? '[월간]' : '[연간]') + (obj.selectedHour + '시간 상품 ') + ('1일 최소 ' + obj.selectedOneDayHour + '이상') + ('단가:' + obj.selectedHourPrice),
        '6. 서비스 이용을 하신다면 시점은 언제인가요? ' : obj.UseServiceString,
        '7. 월간 모시러를 알게 된 경로는 어떻게 되시나요? ' : Number(obj.formData.Route) === ETC_ID ?  obj.formData.RouteEtcStr : obj.RouteString, 
        '8. 특별히 요청할 내용이 있으면 기재해 주시기 바랍니다.':obj.formData.EtcRequest,
        '9. 컨시어지 상담을 원하시면 알려주세요.' : '(웹)' + obj.ConciergeString,
        '타임스탬프' : moment().format('yyyy-MM-DD HH:mm'),
        '이용목적' : obj.NeedsString,
    });

    return new Promise( (resolve, reject)=>{
        axios({
            url : `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then( async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve('fail')
        });
    })
}




export const setEstimatePage02 = (obj, userinfo) => {
    const saveSheet = '계약초안'
   
    let form = qs.stringify({
    
        "고객이름" : userinfo.UserName,
        "전화번호" : userinfo.UserPhone,
        "이메일주소" : userinfo.UserEmail,
        "고객유형" : obj.CustomerTypeString,
        "계약자 성함" : obj.ContractName,
        "상세주소" : obj.ContractAddress,
        "사업자등록번호" : (obj.CustomerType === '1') ? '없음(개인고객)' : obj.ContractBLNumber,
        "차량모델명" : obj.CarModel,
        "이용시작예정일" : `${moment(obj.ContractStartDate).format('yyyy-MM-DD')} (${getEnglishDayToKorean(moment(obj.ContractStartDate).format('ddd'))})`,
        "계약기간" : obj.ContractMonthString,
        "이용목적" : obj.PurposeString,
        "담당자 성명" : (obj.CustomerType === '1') ? '없음(개인고객)' : obj.BookerName,
        "담당자 연락처" : (obj.CustomerType === '1') ? '없음(개인고객)' : obj.BookerPhone,
        "담당자 Email" : (obj.CustomerType === '1') ? '없음(개인고객)' : obj.BookerEmail,
        "사업자 등록증" : (obj.CustomerType === '1') ? '없음(개인고객)' : obj.ComplateBusinessLicenseUrl,
        "timestamp" : moment().format('yyyy-MM-DD HH:mm'),
    });

    
    return new Promise( (resolve, reject)=>{
        axios({
            url : `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then( async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve('fail')
        });
    })
}

export const setEstimatePageIntroduce = (userinfo, inflow) => {
    const saveUrl = 'https://script.google.com/macros/s/AKfycbyJeVzlvCYwPhqcny1ISEryrDEjqf9q5XE0r-ujh71xMTV03iM/exec'
    let form = qs.stringify({
        '이름': userinfo.UserName,
        '연락처': userinfo.UserPhone,
        '이메일': userinfo.UserEmail,
        '상품': inflow,
        '타임스탬프' : new Date().toLocaleDateString(),
    });

    return new Promise( (resolve, reject)=>{
        axios({
            url : saveUrl,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then( async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve('fail')
        });
    })
}

/*
let today = new Date();
			var time = today.toLocaleDateString();
*/


export const setEstimatePage02Live = (obj) => {
    const saveSheet = '3. 계약응답(웹)'
   
    let form = qs.stringify({
    
        "1. 계약자의 성함을 알려주세요." : obj.ContractName,
        "2. 계약자의 상세 주소를 알려주세요." : obj.ContractAddress + ' ' + obj.ContractAddress2,
        "3. 차량의 모델명을 알려주세요" : obj.CarModel,
        "4. 이용 시작일은 언제 부터 인가요?  " : `${moment(obj.ContractStartDate).format('yyyy-MM-DD')} (${getEnglishDayToKorean(moment(obj.ContractStartDate).format('ddd'))})`,
        "5. 원하시는 계약 기간은 어떻게 되시나요? " : obj.ContractMonthString,
        "6. 서비스 이용의 목적은 어떻게 되시나요?" : obj.PurposeString === '기타' ? `기타(${obj.PurposeEtcStr})` : obj.PurposeString,
        "7. 담당자님의 성함을 알려주세요" : obj.BookerName,
        "8. 담당자님의 연락처를 알려주세요" : obj.BookerPhone,
        "9. 담당자님의 이메일 주소를 알려주세요" : obj.BookerEmail,
        '10. 컨시어지 상담을 원하시면 알려주세요.' : '웹견적서',
        '타임스탬프' : moment().format('yyyy-MM-DD HH:mm'),
        // "전화번호" : userinfo.UserPhone,
        // "이메일주소" : userinfo.UserEmail,
        "고객유형" : obj.CustomerTypeString,
        "계약자 성함" : obj.ContractName,
        "사업자등록번호" : (obj.CustomerType === '1') ? '없음(개인고객)' : obj.ContractBLNumber,        
        "사업자 등록증" : (obj.CustomerType === '1') ? '없음(개인고객)' : obj.ComplateBusinessLicenseUrl,
        "계약요청상품" : (obj.ContractReqProductString === undefined || obj.ContractReqProductString === null) ? '' : obj.ContractReqProductString
    });

    
    return new Promise( (resolve, reject)=>{
        axios({
            url : `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then( async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve('fail')
        });
    })
}

export const setEstimatePage03 = (obj, CustomerNo) => {
    const saveSheet = '계약중'
    let form = qs.stringify({
        '고객이름'                  : obj.UserName,
        '전화번호'                  : obj.UserPhone,
        '이메일주소'                : obj.UserEmail,
        '고객번호'                  : CustomerNo,
        'timestamp'                 : moment().format('yyyy-MM-DD HH:mm'),
    });

    return new Promise( (resolve, reject)=>{
        axios({
            url : `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then( async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve('fail')
        });
    })
}