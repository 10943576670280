import styled from "styled-components";
function TooltipContents({ children, ...props }) {
  return <TooltipBody {...props}>{children}</TooltipBody>;
}
const TooltipBody = styled.div`
  padding: 20px;
  background: #fff;
  color: #000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 7px 7px;
  @media (max-width: 640px) {
    padding: 16px 12px;
  }
`;
export default TooltipContents;
