import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import styled from "styled-components";
import PhilosophyImage01 from "../../assets/images/img-philosophy-01.png";
import PhilosophyImage02 from "../../assets/images/img-philosophy-02.png";
import PhilosophyImage03 from "../../assets/images/img-philosophy-03.png";
import { Mobile, Pc, Tablet } from "../../hooks/useMediaQuery";
import GridItem from "./Layout/GridItem";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import ThreeColumnGrid from "./Layout/ThreeColumnGrid";
import TextBlock from "./Text/TextBlock";
function PhilosophyContainer({ children }) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <Section padding="120px 0">
      <InnerSection>
        <Pc>
          <ThreeColumnGrid>
            <GridItem data-aos="fade-up">
              <PhilosophyImage src={PhilosophyImage01} alt="image" />
            </GridItem>
            <GridItem data-aos="fade-up" data-aos-delay="50">
              <PhilosophyImage src={PhilosophyImage02} alt="image" />
            </GridItem>
            <GridItem data-aos="fade-up" data-aos-delay="100">
              <PhilosophyImage src={PhilosophyImage03} alt="image" />
            </GridItem>
          </ThreeColumnGrid>
        </Pc>
        <Tablet>
          <ThreeColumnGrid>
            <GridItem data-aos="fade-up">
              <PhilosophyImage src={PhilosophyImage01} alt="image" />
            </GridItem>
            <GridItem data-aos="fade-up">
              <PhilosophyImage src={PhilosophyImage02} alt="image" />
            </GridItem>
            <GridItem data-aos="fade-up">
              <PhilosophyImage src={PhilosophyImage03} alt="image" />
            </GridItem>
          </ThreeColumnGrid>
        </Tablet>
        <Mobile>
          <ThreeColumnGrid>
            <GridItem data-aos="fade-up">
              <PhilosophyImage src={PhilosophyImage02} alt="image" />
            </GridItem>
          </ThreeColumnGrid>
        </Mobile>
        <PhilosophyText data-aos="fade-up">
          <Pc>
            모시러는 이미 B2B 프리미엄 기사 서비스로 국내 자산가들의 열렬한
            성원을 받아 왔습니다. <br />
            프리미엄의 특성상,{" "}
            <strong>작지만 큰 서비스의 차이를 만드는 모시러의 철학</strong>이
            성공을 만들어 냈습니다.
          </Pc>
          <Tablet>
            모시러는 이미 B2B 프리미엄 기사 서비스로 국내 자산가들의 열렬한
            성원을 받아 왔습니다. <br />
            프리미엄의 특성상,{" "}
            <strong>작지만 큰 서비스의 차이를 만드는 모시러의 철학</strong>이
            성공을 만들어 냈습니다.
          </Tablet>
          <Mobile>
            모시러는 이미 B2B 프리미엄 기사 서비스로 <br />
            국내 자산가들의 열렬한 성원을 받아 왔습니다. <br />
            <strong>
              작지만 큰 서비스의 차이를 만드는 모시러의 철학
            </strong>이 <br />
            성공을 만들어 냈습니다.
          </Mobile>
        </PhilosophyText>
      </InnerSection>
    </Section>
  );
}
const PhilosophyImage = styled.img`
  width: 378px;
  max-width: 100%;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
const PhilosophyText = styled(TextBlock).attrs({ textAlign: `center` })`
  margin-top: 60px;
  @media (max-width: 640px) {
    margin-top: 16px;
  }
`;
export default PhilosophyContainer;
