import React, { useEffect, useState } from 'react';
import moment from 'moment';
// import image01 from '../../img/img_auto/01.jpg'
// import image02 from '../../img/img_auto/02.jpg'
// import image03 from '../../img/img_auto/03.jpg'
// import image04 from '../../img/img_auto/04.jpg'
// import image05 from '../../img/img_auto/05.jpg'
// import image06 from '../../img/img_auto/06.jpg'
// import image07 from '../../img/img_auto/07.jpg'
// import image08 from '../../img/img_auto/08.jpg'
// import image09 from '../../img/img_auto/09.jpg'
// import image10 from '../../img/img_auto/10.jpg'
// import image11 from '../../img/img_auto/11.jpg'
// import image12 from '../../img/img_auto/12.jpg'
// import image13 from '../../img/img_auto/13.jpg'
// import image14 from '../../img/img_auto/14.jpg'
// import landing_image from '../../img/landing_image.png'

import KakaoButton from '../../components/KakaoButton';
import { getQueryStringObject, isBusanFlag,  } from '../../lib/Util';
import { setEstimatePageIntroduce } from '../../lib/GoogleSheetApi';
import { SendEztoc } from '../../lib/EzTocApi';
import { uploadTxt } from '../../lib/MsAzureApi';

import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import "./../../App.css";
import ExclusiveSystemContainer from "../../components/Introduce/ExclusiveSystemContainer";
import IntroductionContainer from "../../components/Introduce/IntroductionContainer";
import MainContainer from "../../components/Introduce/MainContainer";
import MonthlyMosilerContainer from "../../components/Introduce/MonthlyMosilerContainer";
import MosilerBenefitContainer from "../../components/Introduce/MosilerBenefitContainer";
import PartnerContainer from "../../components/Introduce/PartnerContainer";
import PhilosophyContainer from "../../components/Introduce/PhilosophyContainer";
import RegisterContainer from "../../components/Introduce/RegisterFormContainer";
import TargetContainer from "../../components/Introduce/TargetContainer";
import TeamSystemContainer from "../../components/Introduce/TeamSystemContainer";
import AnnualContainer from "../../components/Introduce/AnnualContainer";
import AnnualPromotionContainer from "../../components/Introduce/AnnualPromotionContainer";
import TeamSystemIntegratedContainer from '../../components/Introduce/TeamSystemIntegratedContainer';
import TeamSystemIntegratedBusanContainer from '../../components/Introduce/TeamSystemIntegratedBusanContainer';

import { BUILD_TARGET_URI } from '../../InitConst';

const BUILD_TARGET = BUILD_TARGET_URI

const ANNUAL_MOSILER = (document.location.hostname ==='year.mosiler.com' || document.location.hostname ==='yeartest.mosiler.com' || (document.location.href.indexOf('http://mosiler.com/annual') >= 0))
const MONTHLY_MOSILER = (document.location.hostname ==='estimate.mosiler.com' || document.location.hostname ==='estimatetest.mosiler.com')

const IntroduceView = (props) => {
    
    const [userInfo, setUserInfo] = useState({
        UserName: '',
        UserPhone: '',
        UserEmail: ''
    })

    //const [scrollData, setScrollData] = useState(0)
    //const prevScrollY = useRef(0);
    //const [goingUp, setGoingUp] = useState(false);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const currentScrollY = window.scrollY;
    //         const viewBtnData = document.querySelector('#first').clientHeight
    //         const finalScrollData = 
    //         (document.querySelector('.introduce_component').scrollHeight - document.querySelector('.introduce_component').clientHeight) - document.querySelector('#end').clientHeight - 50
    //         // console.log(finalScrollData)
    //         // clientHeight         
    //         if (prevScrollY.current < currentScrollY && goingUp) {
    //             setGoingUp(false);
    //         }
    //         if (prevScrollY.current > currentScrollY && !goingUp) {
    //             setGoingUp(true);
    //         }
    //         prevScrollY.current = currentScrollY;
    //         if (currentScrollY >= viewBtnData) {
    //             if (currentScrollY >= finalScrollData) {
    //                 document.querySelector('#button_fixed').className='button_fixed fadeout_cn'    
    //             }
    //             else {
    //                 document.querySelector('#button_fixed').className='button_fixed fadein_cn'
    //             }
    //         }
    //         else {
    //             document.querySelector('#button_fixed').className='button_fixed fadeout_cn'
    //         }
    //         //  console.log(goingUp, currentScrollY);
    //     };
    //     window.addEventListener("scroll", handleScroll, { passive: true });
    //     return () => window.removeEventListener("scroll", handleScroll);
    // }, [goingUp]);

    useEffect(() => {
        AOS.init({
            startEvent: "load",
            duration: 1000,
            once: true,
        });
        AOS.refresh();
    }, []);

    useEffect(()=>{
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerHTML = ANNUAL_MOSILER ? '연간모시러 상품 소개서' : '월간모시러 상품 소개서'

        try{
            

            let tokenData = getQueryStringObject();
            /* token 매개변수가 있다면 */
            if (tokenData!==undefined && tokenData.token !== undefined) {
                // localstorage에 저장
                localStorage.setItem('token0', tokenData.token);
                // url 변경하여 token값 없애기.
                // window.history.pushState("", "", `${props.history.location.pathname}`)
                setUserInfo(getUserInfoDecrypt(tokenData.token))
                
            }
            /* token 매개변수가 있다면 */
            if (tokenData !== undefined && tokenData.inflow !== undefined) {
                // localstorage에 저장
                localStorage.setItem('inflow', tokenData.inflow);
                // url 변경하여 token값 없애기.
                // window.history.pushState("", "", `${props.history.location.pathname}`)
            }
            // 없으면 없는대로 진행.
            // else if (ANNUAL_MOSILER) {
            //     localStorage.setItem('inflow', 'annual');
            // }
        }
        catch(e) {
            // if (ANNUAL_MOSILER) {
            //     localStorage.setItem('inflow', 'annual');
            // }
        }
    },[])

    
    const setUserInfoTarget = (e) => {
        let formId = e.target.id
        setUserInfo({
            ...userInfo,
            [formId]: e.target.value
        })
    }

    const isValidKoreanPhoneNumber = (phoneNumber) => {
        var regex = /^01[0|1|6|7|8|9][0-9]{7,8}$/;
        return regex.test(phoneNumber);
    }
    const isValidEmail = (email) => {
        var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }
    const isValidKoreanName = (name) => {
        var regex = /^[가-힣a-zA-Z]+$/;
        return regex.test(name) && name.length >= 2;
    }
    const getUserInfoEncrypt = (info1) => {
        var saveData = JSON.stringify(info1)
        return window.btoa(unescape(encodeURIComponent(saveData)))
    }
    const getUserInfoDecrypt = (info1) => {
        let decrypt = decodeURIComponent(escape(window.atob(info1)));
        // console.log(JSON.parse(decrypt))
        return JSON.parse(decrypt)
    }
    
    const onButton = () => {
        if (!userInfo.UserName) {
            alert("이름을 입력해 주세요.");
            return false;
        }
        if (!isValidKoreanName(userInfo.UserName)) {
            alert("올바른 이름을 입력해 주세요.");
            return false;
        }
        
        if (!userInfo.UserPhone) {
            alert("휴대전화번호를 입력해 주세요.");
            return false;
        }
        if (!isValidKoreanPhoneNumber(userInfo.UserPhone)) {
            alert("올바른 휴대전화번호를 입력해주세요.");
            return false;
        }
        
        if (!userInfo.UserEmail) {
            alert("이메일을 입력해 주세요.");
            return false;
        }
        if (!isValidEmail(userInfo.UserEmail)) {
            alert("올바른 이메일을 입력해 주세요.");
            return false;
        }

        // 쿼리스트링 파싱
        let tokenData2 = getQueryStringObject();
        let tmpInflow = ''
        try {
            if (tokenData2 !== undefined && tokenData2.inflow !== undefined) {
                tmpInflow = tokenData2.inflow
            }
        }
        catch(e) {
            tmpInflow = ''
        }
        
        if (tmpInflow === null || tmpInflow === undefined || tmpInflow === '') {
            tmpInflow = '알수없음'
            if (document.location.href.indexOf('estimate.mosiler.com') >= 0) {
                tmpInflow = 'monthly_estimate'
            }
            else if (document.location.href.indexOf('year.mosiler.com') >= 0) {
                tmpInflow = 'annual_year'
            }
        }

        uploadTxt(moment().format('yyyy-MM-DD hh:mm:ss') + '\n\n' + JSON.stringify(document.location) + '\n\n' + navigator.userAgent + '\n\ntmpInflow : ' + tmpInflow + '\n\n' + JSON.stringify(userInfo));

        localStorage.setItem('token0', getUserInfoEncrypt(userInfo));
        setEstimatePageIntroduce(userInfo, tmpInflow);
        
        // 소개서1 알림톡은 최초 1번만 발송.
        let tmpeztocFlag = localStorage.getItem('eztocFlag');
        if (tmpeztocFlag === null) {
            SendEztoc({
                eztocType:'introduce',
                UserName : userInfo.UserName,
                UserPhone : userInfo.UserPhone,
                inflow: tmpInflow
            })
            localStorage.setItem('eztocFlag', 1);
        }

        // validate check
        window.open(`${BUILD_TARGET}/estimate`, '_blank')
    }
    

    return (
        <>
            <div>
                {ANNUAL_MOSILER ? (
                    <AppWrapper>
                        <MainContainer type={'ym'} />
                        <IntroductionContainer type={'ym'} />
                        <PhilosophyContainer />
                        <PartnerContainer type={'ym'}/>
                        {/* <MonthlyMosilerContainer /> */}
                        {/* <MosilerBenefitContainer /> */}
                        <TargetContainer />
                        <AnnualContainer  />
                        <AnnualPromotionContainer />
                        <RegisterContainer 
                            userNameId={'UserName'} onChangeUserName={setUserInfoTarget} userNameValue={userInfo.UserName} 
                            userPhoneId={'UserPhone'} onChangeUserPhone={setUserInfoTarget} userPhoneValue={userInfo.UserPhone} 
                            userEmailId={'UserEmail'} onChangeUserEmail={setUserInfoTarget} userEmailValue={userInfo.UserEmail} 
                            onClickButton = {onButton}
                        />
                    </AppWrapper>
                    // <div className="introduce_component"> 
                    //     <img src={landing_image} alt="" />
                    //     <div className='subtitle' id='subtitle'>
                    //         아래 사항을 입력해서<br />
                    //         견적서를 확인해보세요.
                    //     </div>
                    //     <div className='input-text-custom'>
                    //         <input type="text" id="UserName" onChange={setUserInfoTarget} value={userInfo.UserName} placeholder='이름을 입력해주세요' />
                    //     </div>
                    //     <div className='input-text-custom'>
                    //         <input type="text" id="UserPhone" onChange={setUserInfoTarget} value={userInfo.UserPhone} placeholder='전화번호를 입력해주세요' />
                    //     </div>
                    //     <div className='input-text-custom'>
                    //         <input type="text" id="UserEmail" onChange={setUserInfoTarget} value={userInfo.UserEmail} placeholder='이메일 주소를 입력해주세요.' />
                    //     </div>
                    //     <div className='introduce_component2'>
                    //         <button id='Step0' onClick={() => onButton()}>
                    //             <span>견적 확인하기</span><br />
                    //             {/* <span style="font-size: 1em;">(카카오톡)</span> */}
                    //         </button>
                    //     </div>
                    // </div>
                ) : (
                    <>
                            <AppWrapper>
                                <MainContainer type={'mm'} />
                                <IntroductionContainer type={'mm'} />
                                <PhilosophyContainer />
                                <PartnerContainer type={'mm'}/>
                                <MonthlyMosilerContainer />
                                <MosilerBenefitContainer />
                                <TargetContainer />
                                {/* <TeamSystemContainer />
                                <ExclusiveSystemContainer /> */}
                                {isBusanFlag() ? <TeamSystemIntegratedBusanContainer /> : <TeamSystemIntegratedContainer />}
                                <RegisterContainer 
                                    userNameId={'UserName'} onChangeUserName={setUserInfoTarget} userNameValue={userInfo.UserName} 
                                    userPhoneId={'UserPhone'} onChangeUserPhone={setUserInfoTarget} userPhoneValue={userInfo.UserPhone} 
                                    userEmailId={'UserEmail'} onChangeUserEmail={setUserInfoTarget} userEmailValue={userInfo.UserEmail} 
                                    onClickButton = {onButton}
                                />
                            </AppWrapper>
                        {/* <div className="introduce_component"> <img src={image01} alt="" /> </div>
                        <div className="introduce_component"> <img src={image02} alt="" /> </div>
                        <div className="introduce_component"> <img src={image03} alt="" /> </div>
                        <div className="introduce_component"> <img src={image04} alt="" /> </div>
                        <div className="introduce_component"> <img src={image05} alt="" /> </div>
                        <div className="introduce_component"> <img src={image06} alt="" /> </div>
                        <div className="introduce_component"> <img src={image07} alt="" /> </div>
                        <div className="introduce_component"> <img src={image08} alt="" /> </div>
                        <div className="introduce_component"> <img src={image09} alt="" /> </div>
                        <div className="introduce_component"> <img src={image10} alt="" /> </div>
                        <div className="introduce_component"> <img src={image11} alt="" /> </div>
                        <div className="introduce_component"> <img src={image12} alt="" /> </div>
                        <div className="introduce_component"> <img src={image13} alt="" /> </div>
                        <div className="introduce_component final"> <img src={image14} alt="" /> </div>
                        <div className="introduce_component3">
                            <button id='Step0' onClick={() => onButton2()}>
                                <span>견적 확인하기</span><br />
                            </button>
                        </div> */}

                    </>
                )}                             
                <KakaoButton />

            </div>

        </>
    );
};
const AppWrapper = styled.div``;
export default IntroduceView;


/*
월간모시러 소개서 (estimate.mosiler.com 버전) 업로드 시 유의사항
1. 기존 mosiler.com/monthlymosiler/index2.html에서도 접근이 가능해야 하기에 estimate.mosiler.com로 접속해도 나올 수 있도록 호환
1. send_mail_monthly_auto.php에 링크를 변경 (http://estimate.mosiler.com/introduce)
2. 


*/