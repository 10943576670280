import React, { useState, forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker'
import ko from 'date-fns/locale/ko';
import moment from 'moment'
import IconPrevMM from '../img/icon_prev_mm.png';
import IconNextMM from '../img/icon_next_mm.png';
registerLocale('ko', ko)

const FloatLabelDate = ({ formId, placeholder, name, value, onChange, originData }) => {
    const [isOpenFlag, setIsOpenFlag] = useState(false);
    const CustomComponent = forwardRef(({ value, onClick }, ref) => {
        const chkValidate = (value) => {
            return value.length === 0  ? 'validate calendar' : 'calendar'
        }
        const getAutoComplateOptionKeyword = ()=> {
            const UA = navigator.userAgent;
            let retData = 'off'
            if (UA.indexOf('Edg') >= 0) {
                retData = 'new-password'
            }
            return retData
        }
        
        return(<>
            <input
                ref={ref}
                type="text"
                id={formId}
                name={formId}
                placeholder={placeholder}
                onClick={onClick}
                defaultValue={value}
                className={chkValidate(value)}
                autoComplete={getAutoComplateOptionKeyword()}
            />
            <span>{name}</span>
        </>)
    });

    
    return (
        <>
            <div className='floatlabel_component type-datepicker' >
                <label htmlFor={formId}>
                    <DatePicker
                        selected={value}
                        locale="ko"
                        dateFormat="yyyy-MM-dd(eee)"
                        onChange={(date) => {
                            onChange({
                                ...originData,
                                [formId]: date
                            })

                        }}
                        customInput={<CustomComponent />}
                        minDate={moment().add(1, 'days').toDate()}
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div style={{ margin: 10, display: "flex", }} >
                                <div style={{ flex: "1" }}>
                                    <button
                                        style={{ fontSize: "1.2em", border: "none", }}
                                        onClick={decreaseMonth}
                                        disabled={prevMonthButtonDisabled}>
                                        <img src={IconPrevMM} alt="이전 달" width={16} />
                                    </button>
                                </div>
                                <div style={{ flex: "10" }}>
                                    <div style={{ fontSize: "1.2em", fontWeight: "bold", }} >
                                        {moment(date).format("YYYY")} 년{" "} {moment(date).format("MM")} 월
                                    </div>
                                </div>
                                <div style={{ flex: "1" }}>
                                    <button style={{ fontSize: "1.2em", border: "none", }}
                                        onClick={increaseMonth}
                                        disabled={nextMonthButtonDisabled} >
                                        <img src={IconNextMM} alt="다음 달" width={16} />
                                    </button>
                                </div>
                            </div>
                        )}
                    />
                </label>
            </div>
        </>
    );
};

export default FloatLabelDate;
