import React from 'react';


const FloatLabelSelect = ({ formId, placeholder, name, value, onChange, originData, list, inputFormCondition, inputFormPlaceHolder, disabled }) => {
    const listToOptionComponent = (arr) => {
        return arr.map((data,idx)=>{
            return (
                <option value={data.val} key={idx}>{data.name}</option>
            )
        })
    }
    // // <FloatLabelSelect  formId={'CustomerType'} name={'고객유형'} value={formData.CustomerType} originData={formData} onChange={setFormData} />
    return (
        <>
            <div className='floatlabel_component' >
                <label htmlFor={formId}>
                    <select 
                        className={`${Number(value) !== 0 ? '' : 'validate'}`}
                        disabled={disabled}
                        value={value} 
                        onChange={(e)=>{
                        onChange({
                            ...originData,
                            [formId]: e.target.value
                        })
                    }}>
                        {listToOptionComponent(list)}
                    </select>
                    <span>{name}</span>
                    {Number(value) === inputFormCondition ? (
                        <input type="text" placeholder={inputFormPlaceHolder} className='floatlabelselect_input '/>
                    ) : null}
                </label>
            </div>
        </>
    );
};

export default FloatLabelSelect;

