import React from "react";
import styled from "styled-components";

function Title({ color, children, ...props }) {
  return (
    <Headline color={color ? color : `inherit`} {...props}>
      {children}
    </Headline>
  );
}
const Headline = styled.h1`
  color: ${({ color }) => color};
  font-size: 68px;
  margin-bottom: 12px;
  @media (max-width: 1200px) {
    font-size: 48px;
  }
  @media (max-width: 640px) {
    font-size: 32px;
    margin-bottom: 0;
  }
`;
export default Title;
