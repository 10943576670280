import React from "react";
import moment from 'moment'
import styled from "styled-components";
import IconTooltip from "../../assets/images/icon-tooltip.png";
import { Mobile, Pc, Tablet } from "../../hooks/useMediaQuery";
import DriverTooltips from "./DriverTooltips";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import Table from "./Table/Table";
import SubTitle from "./Text/SubTitle";
function ExclusiveSystemContainer({ children }) {

  const getEnglishDayToKorean = (daysName) => {
    let retData = ''
    switch (daysName) {
      case 'Mon':
        retData = '월'
        break;
      case 'Tue':
        retData = '화'
        break;
      case 'Wed':
        retData = '수'
        break;
      case 'Thu':
        retData = '목'
        break;
      case 'Fri':
        retData = '금'
        break;
      case 'Sat':
        retData = '토'
        break;
      case 'Sun':
        retData = '일'
        break;
      default:
        retData = '0'
        break;
    }
    return retData;
  }

  const getLastDayOfMonthAndWeekday = () => {
    const inputDate = moment();
    const lastDayOfMonth = inputDate.endOf('month');

    return `${lastDayOfMonth.format('YYYY.MM.DD')} (${getEnglishDayToKorean(lastDayOfMonth.format('ddd'))})`;
  }


  return (
    <Section padding="100px 0">
      <InnerSection>
        <SubTitle fontSize="40" data-aos="fade-up" data-aos-duration="1000">
          연간모시러 구독 상품(Promotion)
        </SubTitle>
        <PromotionEventContainer>
          프로모션 마감 기한 : {getLastDayOfMonthAndWeekday()} 까지
        </PromotionEventContainer>
        <TableContainer data-aos="fade-up" data-aos-duration="1000">
          <Table>
            <colgroup>
              <Pc>
                <col style={{ width: `200px` }} />
              </Pc>
              <Tablet>
                <col style={{ width: `200px` }} />
              </Tablet>
              <Mobile>
                <col style={{ width: `120px` }} />
              </Mobile>
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>상품</th>
                <Pc>
                  <th>250시간</th>
                </Pc>

                <Tablet>
                  <th>250시간</th>
                </Tablet>

                <Mobile>
                  <th>250시간</th>
                </Mobile>

                <Pc>
                  <th>500시간</th>
                </Pc>

                <Tablet>
                  <th>500시간</th>
                </Tablet>

                <Mobile>
                  <th>500시간</th>
                </Mobile>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>기본시간</th>
                <td>250시간</td>
                <td>500시간</td>
              </tr>
              <tr>
                <th>최소 이용 시간</th>
                <td>10</td>
                <td>10</td>
              </tr>
            </tbody>
          </Table>
        </TableContainer>
      </InnerSection>
      <DriverTooltips />
    </Section>
  );
}
const TableContainer = styled.div`
  margin-top: 40px;
  @media (max-width: 640px) {
    margin-top: 24px;
  }
`;
const PromotionEventContainer = styled.div`
  color:#e79c2b;
  font-weight:bold;
  font-size: 20px;
  @media (max-width: 1200px) {
    font-size: 20px;
  }
  @media (max-width: 640px) {
    font-size: 14px;
  }
`;
const ButtonTooltip = styled.button`
  background: transparent;
  width: 18px;
  outline: none;
  border: none;
  vertical-align: middle;
  cursor: pointer;
`;
const Image = styled.img`
  width: 100%;
`;
export default ExclusiveSystemContainer;
