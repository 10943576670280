import styled from "styled-components";
function TooltipTitle({ children, ...props }) {
  return <TooltipHeader {...props}>{children}</TooltipHeader>;
}
const TooltipHeader = styled.div`
  padding: 14px 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 7px 7px 0 0;
  background-color: #3e3e3e;
  color: #fff;
  @media (max-width: 640px) {
    padding: 8px 12px;
  }
`;
export default TooltipTitle;
