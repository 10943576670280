import React from 'react';

const FloatLabel = ({ formId, placeholder, name, value, onChange, originData, onClick, onKeyDown, noValidate, disabled }) => {
    const chkValidate = (value) => {
        if (noValidate) {
            return 'float_label'
        }

        return value === undefined || value.length === 0  ? 'validate float_label' : 'float_label'
    }
    

    const getAutoComplateOptionKeyword = ()=> {
        const UA = navigator.userAgent;
        let retData = 'off'
        if (UA.indexOf('Edg') >= 0) {
            retData = 'new-password'
        }
        return retData
    }

    return (
        <>
            <div className='floatlabel_component' >
                <label htmlFor={formId}>
                    <input 
                        type="text" 
                        className={chkValidate(value)}
                        id={formId} 
                        name={formId} 
                        placeholder={placeholder} 
                        onChange={(e)=>{
                            onChange({
                                ...originData,
                                [formId]: e.target.value
                            })
                        }} 
                        onClick={(e)=>{
                            if (onClick !== undefined)
                                onClick(e)
                        }} 
                        onKeyDown={(e)=>{
                            if (onKeyDown !== undefined)
                            onKeyDown(e)
                        }} 
                        autoComplete={getAutoComplateOptionKeyword()}
                        value={value} 
                        disabled={disabled}
                        />
                    <span>{name}</span>
                </label>
            </div>
        </>
    );
};

export default FloatLabel;

