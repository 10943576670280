import axios from "axios";
import { getEnglishDayToKorean } from "./Util";
import moment from "moment";
import { SLACK_URL, MANAGE_ADMIN_URL } from "../InitConst";



export const SendSlack = (info, CustomerNo) => {
    /*
 UserName: token0.UserName,
UserPhone: token0.UserPhone,
UserEmail: token0.UserEmail,
ContractStartDate: token2.ContractStartDate,
ComplateBusinessLicenseUrl : token2.ComplateBusinessLicenseUrl,
StartArea: token2.StartArea,
EndArea: token2.EndArea,
CarModel: token2.CarModel,
productCategoryName  : token1.productCategory === 0 ? '월간모시러':'연간모시러',
oneDayHour           : token1.selectedOneDayHour,
UseHour              : token1.selectedHour,
HourPrice            : token1.selectedHourPrice,
AllPrice             : (Number(token1.selectedHour) * Number(token1.selectedHourPrice)),
    */
    let text = `[${info.productCategoryName} 계약중]
    ★ 모두싸인을 통해 계약을 진행해주세요 ★
    - 고객구분 : ${info.CustomerType === '1' ? '개인' : '사업자'}
    - 상품명 : ${info.UseHour}시간
    - 고객명 : ${info.UserName}
    - 담당자 성명 : ${info.BookerName}
    - 담당자 Email : ${info.BookerEmail}
    - 담당자 연락처 : ${info.BookerPhone}
    - 출발지 : ${info.StartArea}
    - 종료지 : ${info.EndArea}
    - 차량 : ${info.CarModel}
    - 이용희망일정 : ${moment(info.ContractStartDate).format('yyyy-MM-DD')} (${getEnglishDayToKorean(moment(info.ContractStartDate).format('ddd'))})
    ${info.CustomerType === '1' ? `` : `- 사업자등록증링크 : ${info.ComplateBusinessLicenseUrl}`}
    `
/*
    let text = `[${info.productCategoryName} 계약중]
    ★ 모두싸인을 통해 계약을 진행해주세요 ★
    - 고객구분 : ${info.CustomerType === '1' ? '개인' : '사업자'}
    - 상품명 : ${info.UseHour}시간
    - 고객명 : ${info.UserName}
    - 출발지 : ${info.StartArea}
    - 종료지 : ${info.EndArea}
    - 차량 : ${info.CarModel}
    - 이용희망일정 : ${moment(info.ContractStartDate).format('yyyy-MM-DD')} (${getEnglishDayToKorean(moment(info.ContractStartDate).format('ddd'))})
    ${info.CustomerType === '1' ? `` : `- 사업자등록증링크 : ${info.ComplateBusinessLicenseUrl}`}
    - 관리시스템고객링크 : ${MANAGE_ADMIN_URL}/customer/modify?no=${CustomerNo}
    `
  */  
    let json = JSON.stringify({
        username:'월간모시러봇',
        text,
        channel:'#월간모시러-계약중'
    })
    
    return new Promise(async (resolve, reject)=>{
        let result = await axios({
            method: 'post',
            url: SLACK_URL,
            data : json
        })
        
        resolve(result)
    }) 
}

export const SendSlack2 = (info, CustomerNo) => {
    /*
 UserName: token0.UserName,
UserPhone: token0.UserPhone,
UserEmail: token0.UserEmail,
ContractStartDate: token2.ContractStartDate,
ComplateBusinessLicenseUrl : token2.ComplateBusinessLicenseUrl,
StartArea: token2.StartArea,
EndArea: token2.EndArea,
CarModel: token2.CarModel,
productCategoryName  : token1.productCategory === 0 ? '월간모시러':'연간모시러',
oneDayHour           : token1.selectedOneDayHour,
UseHour              : token1.selectedHour,
HourPrice            : token1.selectedHourPrice,
AllPrice             : (Number(token1.selectedHour) * Number(token1.selectedHourPrice)),
    */
    let text = `✨ ${info.ContractName}님 월간모시러 계약문의 응답 ✨
    - 담당자 이름 : ${info.BookerName}
    - 담당자 Email : ${info.BookerEmail}
    - 담당자 연락처 : ${info.BookerPhone}`
    
    
/*
    let text = `[${info.productCategoryName} 계약중]
    ★ 모두싸인을 통해 계약을 진행해주세요 ★
    - 고객구분 : ${info.CustomerType === '1' ? '개인' : '사업자'}
    - 상품명 : ${info.UseHour}시간
    - 고객명 : ${info.UserName}
    - 출발지 : ${info.StartArea}
    - 종료지 : ${info.EndArea}
    - 차량 : ${info.CarModel}
    - 이용희망일정 : ${moment(info.ContractStartDate).format('yyyy-MM-DD')} (${getEnglishDayToKorean(moment(info.ContractStartDate).format('ddd'))})
    ${info.CustomerType === '1' ? `` : `- 사업자등록증링크 : ${info.ComplateBusinessLicenseUrl}`}
    - 관리시스템고객링크 : ${MANAGE_ADMIN_URL}/customer/modify?no=${CustomerNo}
    `
  */  
    let json = JSON.stringify({
        username:'월간모시러봇',
        text,
        channel:'#월간모시러-계약중'
    })
    
    return new Promise(async (resolve, reject)=>{
        let result = await axios({
            method: 'post',
            url: SLACK_URL,
            data : json
        })
        
        resolve(result)
    }) 
}