import styled from "styled-components";
function InputText({ label, name, type, placeholder, children, ...props }) {
  
  return (
    <InputContainer>
      <Input 
        type={type} 
        name={name} 
        placeholder={placeholder} 
        onChange={(e)=>props.onChange(e)} 
        value={props.value} 
        id={props.id} />
      <label htmlFor={name}>{label}</label>
    </InputContainer>
  );
}
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: relative;
  label {
    position: absolute;
    left: 20px;
    top: 12px;
    color: #4b4b4b;
    font-size: 12px;
  }
`;
const Input = styled.input`
  width: 100%;
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #eceef2;
  height: 63px;
  padding: 31px 20px 8px;
  outline: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  &:focus {
    border-color: #706ed1;
    & + label {
      color: #706ed1;
    }
  }
`;
export default InputText;
