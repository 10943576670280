
const key = 'mm'

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getLocalStorage = (id) => {
    return localStorage.getItem(id) !== null ? JSON.parse(localStorage.getItem(id)) : null;
}

export const setLocalStorage = (id, jsondata) => {
    localStorage.setItem(id, JSON.stringify(jsondata));
}



export const getQueryStringObject = () => {
    var a = window.location.search.substr(1).split('&');
    if (a === "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i) {
        var p = a[i].split('=', 2);
        if (p.length === 1)
            b[p[0]] = "";
        else
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
}

export const isChkUserInfo = (info1, info2) => {
    //info1 : qs로 들어온 데이터를 암호화한 데이터
    //info2 : ls데이터
    //true : qs쪽 데이터와 ls 데이터가 동일 | false : 동일하지 않음
    return (info1 === info2)
}

export const getUserInfoEncrypt = (info1) => {
    let saveData = JSON.stringify(info1)
    return window.btoa(unescape(encodeURIComponent(saveData)))
}

export const getLocalStorageDecrypt = (id) => {
    if (localStorage.getItem(id) !== null) {
        let decrypt = decodeURIComponent(escape(window.atob(localStorage.getItem(id))));
        // console.log(JSON.parse(decrypt))
        return JSON.parse(decrypt)
    }
    return null;
    
    //return localStorage.getItem(id) !== null ? JSON.parse( atob(localStorage.getItem(id)) ) : null;
}

export const setLocalStorageEncrypt = (id, jsondata) => {
    let saveData = JSON.stringify(jsondata)
    let convert = window.btoa(unescape(encodeURIComponent(saveData)))
    localStorage.setItem(id, convert) ;
}

export const getSessionStorageDecrypt = (id) => {
    if (sessionStorage.getItem(id) !== null) {
        let decrypt = decodeURIComponent(escape(window.atob(sessionStorage.getItem(id))));
        // console.log(JSON.parse(decrypt))
        return JSON.parse(decrypt)
    }
    return null;
    
    //return localStorage.getItem(id) !== null ? JSON.parse( atob(localStorage.getItem(id)) ) : null;
}

export const setSessionStorageEncrypt = (id, jsondata) => {
    let saveData = JSON.stringify(jsondata)
    let convert = window.btoa(unescape(encodeURIComponent(saveData)))
    sessionStorage.setItem(id, convert) ;
}

export const getEnglishDayToKorean = (daysName) => {
    let retData = ''
    switch(daysName) {
        case 'Mon':
            retData = '월'
            break;
        case 'Tue':
            retData = '화'
            break;
        case 'Wed':
            retData = '수'
            break;
        case 'Thu':
            retData = '목'
            break;
        case 'Fri':
            retData = '금'
            break;
        case 'Sat':
            retData = '토'
            break;
        case 'Sun':
            retData = '일'
            break;
        default:
            retData = '0'
            break;
    }
    return retData;
}


// Email Address Validation
export const isChkEmail = (data) => {
    let exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
    return exptext.test(data);
}

// String Validation
// length >= 1 : true | <= 0 : false
export const isChkString = (data) => {
    return data.length;
}

export const isChkPhone = (phone) => {
    return (/^[0-9]{3}[0-9]{3,4}[0-9]{4}/g.test(phone)) && (phone.length>=1);
}

export const isValidation = (type, data, msg) => {
    if (type === 'email') {
        if (!isChkEmail(data)) {
            alert(msg)
            return false;
        }
        return true;
    }
    else {
        if (!isChkString(data)) {
            alert(msg)
            return false;
        }
        return true;
    }
}

export const promotionDisCountMonth = (month) => {
    let retData = 0;
    if (Number(month) === 6) {
        retData = 5
    }
    else if (Number(month) === 12) {
        retData = 7
    }
    else {
        retData = 0;
    }
    return retData
}

export const isBusanFlag = () => {
    return (document.location.href.indexOf('busan') >= 0)
}