import React from "react";
import styled from "styled-components";
import IconTooltip from "../../assets/images/icon-tooltip.png";
import { Mobile, Pc, Tablet } from "../../hooks/useMediaQuery";
import DriverTooltips from "./DriverTooltips";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import Table from "./Table/Table";
import SubTitle from "./Text/SubTitle";
function ExclusiveSystemContainer({ children }) {
  return (
    <Section padding="100px 0">
      <InnerSection>
        <SubTitle fontSize="40" data-aos="fade-up" data-aos-duration="1000">
          월간모시러 전담 상품
        </SubTitle>
        <TableContainer data-aos="fade-up" data-aos-duration="1000">
          <Table>
            <colgroup>
              <Pc>
                <col style={{ width: `200px` }} />
              </Pc>
              <Tablet>
                <col style={{ width: `200px` }} />
              </Tablet>
              <Mobile>
                <col style={{ width: `120px` }} />
              </Mobile>
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>상품</th>
                <Pc>
                  <th>30일_8시간</th>
                </Pc>
                <Tablet>
                  <th>30일_8시간</th>
                </Tablet>
                <Mobile>
                  <th>
                    30일
                    <br />
                    8시간
                  </th>
                </Mobile>
                <Pc>
                  <th>30일_12시간</th>
                </Pc>
                <Tablet>
                  <th>30일_12시간</th>
                </Tablet>
                <Mobile>
                  <th>
                    30일
                    <br />
                    12시간
                  </th>
                </Mobile>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>기본시간</th>
                <td>240시간</td>
                <td>360시간</td>
              </tr>
              <tr>
                <th>최소 이용 시간</th>
                <td>8</td>
                <td>12</td>
              </tr>
              <tr>
                <th>
                  드라이버 풀{" "}
                  <ButtonTooltip data-tooltip-id="driverPoolTip01">
                    <Image src={IconTooltip} alt="tooltip" />
                  </ButtonTooltip>
                </th>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <th>
                  추천 드라이버 수{" "}
                  <ButtonTooltip data-tooltip-id="driverPoolTip02">
                    <Image src={IconTooltip} alt="tooltip" />
                  </ButtonTooltip>
                </th>
                <td>10</td>
                <td>10</td>
              </tr>
            </tbody>
          </Table>
        </TableContainer>
      </InnerSection>
      <DriverTooltips />
    </Section>
  );
}
const TableContainer = styled.div`
  margin-top: 40px;
  @media (max-width: 640px) {
    margin-top: 24px;
  }
`;
const ButtonTooltip = styled.button`
  background: transparent;
  width: 18px;
  outline: none;
  border: none;
  vertical-align: middle;
  cursor: pointer;
`;
const Image = styled.img`
  width: 100%;
`;
export default ExclusiveSystemContainer;
