import FormItem from "./Form/FormItem";
import FormList from "./Form/FormList";
import InputText from "./Form/InputText";
import SubmitButton from "./Form/SubmitButton";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import SubTitle from "./Text/SubTitle";

function RegisterContainer({ bgFlag, children, ...props }) {
  return (
    <Section padding="100px 0" background={bgFlag === true ? "#FAFAFA" : ""}>
      <InnerSection>
        <SubTitle
          fontSize="40"
          fontWeight="300"
          textAlign="center"
          data-aos="fade-up"
        >
          아래 사항을 입력해서 <br />
          <strong>지금 바로 견적서를</strong><br/>
          확인해 보세요.
        </SubTitle>
        {/* userNameId={'UserName'} onChangeUserName={setUserInfoTarget} userNameValue={userInfo.UserName} 
                                    userPhoneId={'UserPhone'} onChangeUserPhone={setUserInfoTarget} userPhoneValue={userInfo.UserPhone} 
                                    userEmailId={'UserEmail'} onChangeUserEmail={setUserInfoTarget} userEmailValue={userInfo.UserEmail}  */}
        <FormList>
          <FormItem data-aos="fade-up">
            <InputText
              name="name"
              placeholder="이름을 입력해 주세요."
              label="성함"
              value={props.userNameValue}
              onChange={props.onChangeUserName}
              id={props.userNameId}
            />
          </FormItem>
          <FormItem data-aos="fade-up">
            <InputText
              name="tel"
              placeholder="전화번호를 입력해주세요."
              label="연락처"
              value={props.userPhoneValue}
              onChange={props.onChangeUserPhone}
              id={props.userPhoneId}
            />
          </FormItem>
          <FormItem data-aos="fade-up">
            <InputText
              name="email"
              placeholder="이메일 주소를 입력해 주세요."
              label="이메일 주소"
              value={props.userEmailValue}
              onChange={props.onChangeUserEmail}
              id={props.userEmailId}
            />
          </FormItem>
          <FormItem style={{ marginTop: `60px` }} data-aos="fade-up">
            <SubmitButton onClick={props.onClickButton}>견적 확인하기</SubmitButton>
          </FormItem>
        </FormList>
      </InnerSection>
    </Section>
  );
}
export default RegisterContainer;
