import React from 'react';


const FloatLabelSelectMm = ({ formId, placeholder, name, value, onChange, originData, list, inputFormCondition, inputFormPlaceHolder, disabled }) => {
    const getProductNameStr = (data) => {
        let retData = ''
        // 월간모시러
        if (data.id === 0){
            retData = `--선택해주세요--`
        }
        else if (data.id >= 1 && data.id <= 1000) {
            if (data.productHour >= 240) {
                retData = `월간모시러 ${data.productHour / data.oneDayHour}일 ${data.oneDayHour}시간 상품`
            }
            else {
                retData = `월간모시러 ${data.productHour / data.oneDayHour}일 상품`
            }
        }
        else {
            if (data.id === 1004 || data.id === 1005) {
                retData = `연간모시러 ${data.productHour}시간 상품 (프로모션)`
            }
            else {
                retData = `연간모시러 ${data.productHour}시간 상품`
            }
        }
        return retData;
    }
    const listToOptionComponent = (arr) => {
        return arr.map((data,idx)=>{
            return (
                <option value={data.id} key={idx}>
                    {getProductNameStr(data)}
                </option>
            )
        })
    }
    // // <FloatLabelSelect  formId={'CustomerType'} name={'고객유형'} value={formData.CustomerType} originData={formData} onChange={setFormData} />
    return (
        <>
            <div className='floatlabel_component' >
                <label htmlFor={formId}>
                    <select 
                        className={`${Number(value) !== 0 ? 'mm_select' : 'mm_select validate'}`}
                        style={{fontSize:'15px'}}
                        disabled={disabled}
                        value={value} 
                        onChange={(e)=>{
                        onChange({
                            ...originData,
                            [formId]: e.target.value
                        })
                    }}>
                        {listToOptionComponent(list)}
                    </select>
                    <span>{name}</span>
                    {Number(value) === inputFormCondition ? (
                        <input type="text" placeholder={inputFormPlaceHolder} className='floatlabelselect_input '/>
                    ) : null}
                </label>
            </div>
        </>
    );
};

export default FloatLabelSelectMm;

