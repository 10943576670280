import React, { useEffect, useState } from 'react';
import { getLocalStorageDecrypt, setLocalStorageEncrypt, numberWithCommas, getEnglishDayToKorean, promotionDisCountMonth, getQueryStringObject } from '../../lib/Util'
import { SendSlack, SendSlack2 } from '../../lib/SlackApi';
import { JoinFormMmExternal } from '../../lib/MosilerJoinApi';
import { setEstimatePage02, setEstimatePage03, setEstimatePage02Live } from '../../lib/GoogleSheetApi';
import { ETC_ID } from '../../InitConst';
import Loading from '../../components/Loading';
import moment from 'moment';
import Header from '../../components/Header';
import TitleWithBorder from '../../components/TitleWithBorder';
import ButtonDownload from '../../components/ButtonDownload';
import Inner from '../../components/Inner';
import { Grid } from '@mui/material';
import ListInformation, { InfoContainer } from '../../components/ListInformation';
import ResultPrice from '../../components/ResultPrice';
import StepButtonGroup from '../../components/StepButtonGroup';
import ButtonStandard from '../../components/ButtonStandard';
import FirstTitle from '../../components/FirstTitle';
import KakaoButton from '../../components/KakaoButton';
import { BUILD_TARGET_URI } from '../../InitConst';

const BUILD_TARGET = BUILD_TARGET_URI
const ANNUAL_MOSILER = (document.location.hostname ==='year.mosiler.com' || document.location.hostname ==='yeartest.mosiler.com' || (document.location.href.indexOf('http://mosiler.com/annual') >= 0))

const ContractResultView = (props) => {
    const [loadingFlag, setLoadingFlag] = useState(true)
    const [productData, setProductData] = useState({
        productCategory: 0,
        selectedId: 0,
        customHour: 0,
        customPrice: 0,
        selectedHour: 0,
        selectedHourPrice: 0,
        selectedOneDayHour: 0
    })
    const [formData, setFormData] = useState({
        CustomerType: '1',
        ContractMonth: '12',
        Purpose: '1',
        Route: '1',
        BookerName: '',
        BookerEmail: '',
        BookerPhone: '',
        BusinessLicense: '',
        ContractStartDate: '',
        CarModel: '',
        StartArea: '',
        EndArea: '',
        ComplateBusinessLicenseUrl: '',
        CustomerTypeList: [
            { val: 1, name: '개인' },
            { val: 2, name: '사업자/법인' },
        ],
        PurposeList:[
            {val:1, name:'회사 대표님 or 임원'},
            {val:2, name:'가족+임직원'},
            {val:3, name:'VIP고객'},
            {val:ETC_ID, name:'기타'},
        ],
        RouteList: [
            { val: 1, name: '인스타그램' },
            { val: 2, name: '페이스북' },
            { val: 3, name: '지인소개' },
            { val: 4, name: '모시러 홈페이지' },
            { val: ETC_ID, name: '기타' },
        ],
        ContractList: [
            { val: 1, name: '1개월' },
            { val: 3, name: '3개월' },
            { val: 6, name: '6개월' },
            { val: 12, name: '12개월' },
        ]
    })
    const [formData1Page, setFormData1Page] = useState({
        StartArea: '',
        EndArea: '',
        UseService: '0',
        UseServiceList: [
            { val: 0, name: '가능하다면 빠르게' },
            { val: 1, name: '1개월 이내' },
            { val: 2, name: '2개월 이내' },
        ],
        Route: '1',
        RouteList: [
            { val: 1, name: '인스타그램' },
            { val: 2, name: '페이스북' },
            { val: 3, name: '지인소개' },
            { val: 4, name: '모시러 홈페이지' },
            { val: ETC_ID, name: '기타' },
        ],
        EtcRequest: '',
        RouteEtcStr:'',
        Concierge:'0',
        ConciergeList: [
            { val: 0, name: '-선택해주세요-' },
            { val: 1, name: '오전' },
            { val: 2, name: '오후' },
            { val: 3, name: '상관없음' },
        ],
        Needs:'0',
        NeedsList: [
            { val: 0, name: '-선택시, 적합한 상품을 추천드립니다.-' },
            { val: 1, name: '비즈니스' },
            { val: 2, name: '골프' },
            { val: 3, name: '키즈/실버' },
        ],
    })
    const [formData2Page, setFormData2Page] = useState({
        CustomerType:'1',
        
        ContractName:'',
        ContractAddress:'',
        ContractAddress2:'',
        ContractBLNumber:'',
        BusinessLicense:'',

        BookerName:'',
        BookerEmail:'',
        BookerPhone:'',


        ContractStartDate:'',
        ContractMonth:'12',
        CarModel:'',
        Purpose:'1',

        ComplateBusinessLicenseUrl:'',
        PurposeEtcStr:'',

        CustomerTypeList:[
            {val:1, name:'개인'},
            {val:2, name:'사업자/법인'},
        ],
        PurposeList:[
            { val: 0, name: '-선택해주세요-' },
            {val:1, name:'회사 대표님 or 임원'},
            {val:2, name:'가족+임직원'},
            {val:3, name:'VIP고객'},
            {val:ETC_ID, name:'기타'},
        ],
        
        ContractList:[
            {val:1, name:'1개월'},
            {val:3, name:'3개월'},
            {val:6, name:'6개월'},
            {val:12, name:'12개월'},
        ],
    })
    const [userInfo, setUserInfo] = useState({
        UserName: '',
        UserPhone: '',
        UserEmail: ''
    })
    const [Intermediate, setIntermediate] = useState(false)
    
    useEffect(() => {
        async function test() {
            const htmlTitle = document.querySelector("title");
            //htmlTitle.innerHTML = '월간모시러 웹 견적서'
            htmlTitle.innerHTML = ANNUAL_MOSILER ? '연간모시러 웹 견적서' : '월간모시러 웹 견적서'
            let tokenChkResult = false;
            let token0 = getLocalStorageDecrypt('token0');      // 고객정보
            let token1 = getLocalStorageDecrypt('token1');      // page1 정보
            let token2 = getLocalStorageDecrypt('token2');      // page1 정보
            if (token0 !== null && token1 !== null) {
                tokenChkResult = true;
            }

            if (tokenChkResult) {
                setUserInfo(token0)
                setProductData(token1)
                setFormData1Page(token1.formData)
                setFormData2Page({
                    ...formData2Page,
                    ...token2
                })
            }
            else if (!tokenChkResult && token2 !== null) {
                setIntermediate(true)
                setFormData2Page({
                    ...formData2Page,
                    ...token2
                })
            }
            else {
                let obj = {}
                try {
                    obj = getQueryStringObject();
                    // 중도 입력자라면
                    if (obj.intermediate !== undefined && obj.intermediate === '1') {
                        setIntermediate(true)
                        if (token2 !== null)
                        setFormData2Page({
                            ...formData2Page,
                            ...token2
                        })
                    }
                        
                }
                catch (e) {
                    setIntermediate(false)
                }
                
                //intermediate
                if (token2 === null) {
                    alert('기본 정보가 존재 하지 않습니다.\n"상품 선택 및 기본정보 입력", "계약정보 입력"의\n내용을 입력해주세요.')
                    props.history.replace(`${BUILD_TARGET}/estimate`)
                    // 
                }                
            }

            setLoadingFlag(false)
        }
        test();
    }, [])

    const getListToValue = (val, list) => {
        let retData = ''
        for (let i in list) {
            if (Number(list[i].val) === Number(val)) {
                retData = list[i].name;
                break;
            }
        }
        return retData;
    }

    const paddingRightData = { paddingRight: '10px' }
    const paddingLeftData = { paddingLeft: '10px' }
    //console.log(formData2Page.ContractAddress2)
    return (
        <>
            {loadingFlag ? <Loading /> : null}
            <KakaoButton />
            <div className='main_component'>
                <Header step="4" history={props.history} Intermediate={Intermediate}/>
                <div className='content'>
                    
                    {Intermediate ? (
                        <>
                            <Inner>
                                <FirstTitle title='계약 내용 확인' />
                                <TitleWithBorder title="계약 정보" />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <InfoContainer>
                                            <ListInformation name="고객 유형">
                                                {formData2Page.CustomerType === '1' ? '개인' : '사업자/법인'}
                                            </ListInformation>
                                            <ListInformation name="계약자 성함">
                                                {formData2Page.ContractName}
                                            </ListInformation>
                                            <ListInformation name="주소">
                                                {formData2Page.ContractAddress} {formData2Page.ContractAddress2}
                                            </ListInformation>
                                            <ListInformation name="사업자 등록번호">
                                                {formData2Page.CustomerType === '1' ? '없음(개인)' : formData2Page.ContractBLNumber}

                                            </ListInformation>
                                            <ListInformation name="계약기간">
                                                {formData2Page.ContractMonth}개월
                                            </ListInformation>
                                            <ListInformation name="탑승자">
                                                {getListToValue(formData2Page.Purpose, formData2Page.PurposeList)}

                                                {Number(formData2Page.Purpose) === ETC_ID ? ` (${formData2Page.PurposeEtcStr})` : null}
                                            </ListInformation>
                                        </InfoContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <InfoContainer>
                                            <ListInformation name="담당자 성명">
                                                {formData2Page.BookerName}
                                                {/* {formData2Page.CustomerType === '1' ? '없음(개인)' : formData2Page.BookerName} */}
                                            </ListInformation>
                                            <ListInformation name="담당자 이메일">
                                                {formData2Page.BookerEmail}
                                                {/* {formData2Page.CustomerType === '1' ? '없음(개인)' : formData2Page.BookerEmail} */}
                                            </ListInformation>
                                            <ListInformation name="담당자 연락처">
                                                {formData2Page.BookerPhone}
                                                {/* {formData2Page.CustomerType === '1' ? '없음(개인)' : formData2Page.BookerPhone} */}
                                            </ListInformation>
                                            <ListInformation name="이용시작 예정일">
                                                {`${moment(formData2Page.ContractStartDate).format('yyyy-MM-DD')} (${getEnglishDayToKorean(moment(formData2Page.ContractStartDate).format('ddd'))})`}
                                            </ListInformation>
                                            <ListInformation name="차량 모델명">
                                                {formData2Page.CarModel}
                                            </ListInformation>
                                            <ListInformation name="">
                                                &nbsp;
                                            </ListInformation>
                                            
                                            {/* 추후 프로모션 할인 할때까지 비활성화
                                            
                                            {productData.productCategory === 0 ?
                                                <ListInformation name="프로모션 할인">
                                                    {promotionDisCountMonth(formData2Page.ContractMonth)}%
                                                </ListInformation> :
                                                <ListInformation name="">
                                                    &nbsp;
                                                </ListInformation>} */}

                                        </InfoContainer>
                                    </Grid>
                                </Grid>
                            </Inner>
                        </>
                    ) : (
                        <>
                            <Inner>
                                <FirstTitle title='계약 내용 확인' />
                                <TitleWithBorder title="견적 정보">
                                    <ButtonDownload id="Step4Download" onClick={() => window.open(`${BUILD_TARGET}/estimate_export?contract=1`)}>최종 견적서 다운로드</ButtonDownload>
                                </TitleWithBorder>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <InfoContainer>
                                            <ListInformation name="이용 서비스">
                                                {productData.productCategory === 0 ? '월간모시러' : '연간모시러'}
                                            </ListInformation>
                                            <ListInformation name="이용 상품">
                                                {/* {productData.selectedHour}시간 상품 */}
                                                {productData.productCategory === 0 ? `${productData.selectedHour / productData.selectedOneDayHour}일 ${productData.selectedOneDayHour}시간 상품` : `${productData.selectedHour}시간 상품`}
                                            </ListInformation>
                                            <ListInformation name="이용가능시간">
                                                월 {productData.selectedHour}시간
                                            </ListInformation>
                                            <ListInformation name="1일 최소 이용시간">
                                                {productData.selectedOneDayHour}시간
                                            </ListInformation>
                                            <ListInformation name="시간당 단가">
                                                {numberWithCommas(productData.selectedHourPrice)}원
                                            </ListInformation>
                                            <ListInformation name="이용목적">
                                                {getListToValue(formData1Page.Needs, formData1Page.NeedsList)}
                                            </ListInformation>
                                        </InfoContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <InfoContainer>
                                            <ListInformation name="서비스 이용시점">
                                                {getListToValue(formData1Page.UseService, formData1Page.UseServiceList)}
                                            </ListInformation>
                                            <ListInformation name="알게된 경로">
                                                {getListToValue(formData1Page.Route, formData1Page.RouteList)}
                                                {Number(formData1Page.Route) === ETC_ID ? ` (${formData1Page.RouteEtcStr})` : null}
                                            </ListInformation>
                                            <ListInformation name="예상 출발지역">
                                                {formData1Page.StartArea}
                                            </ListInformation>
                                            <ListInformation name="예상 종료지역">
                                                {formData1Page.EndArea}
                                            </ListInformation>
                                            <ListInformation name="그 외 요청사항">
                                                {formData1Page.EtcRequest}
                                            </ListInformation>
                                            <ListInformation name="상담가능시간대">
                                                {getListToValue(formData1Page.Concierge, formData1Page.ConciergeList)}
                                            </ListInformation>
                                        </InfoContainer>
                                    </Grid>
                                </Grid>
                            </Inner>
                            <Inner>
                                <TitleWithBorder title="계약 정보" />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <InfoContainer>
                                            <ListInformation name="고객 유형">
                                                {formData2Page.CustomerType === '1' ? '개인' : '사업자/법인'}
                                            </ListInformation>
                                            <ListInformation name="계약자 성함">
                                                {formData2Page.ContractName}
                                            </ListInformation>
                                            <ListInformation name="주소">
                                            {formData2Page.ContractAddress} {formData2Page.ContractAddress2}
                                            </ListInformation>
                                            <ListInformation name="사업자 등록번호">
                                                {formData2Page.CustomerType === '1' ? '없음(개인)' : formData2Page.ContractBLNumber}

                                            </ListInformation>
                                            <ListInformation name="계약기간">
                                                {formData2Page.ContractMonth}개월
                                            </ListInformation>
                                            <ListInformation name="탑승자">
                                                {getListToValue(formData2Page.Purpose, formData2Page.PurposeList)}

                                                {Number(formData2Page.Purpose) === ETC_ID ? ` (${formData2Page.PurposeEtcStr})` : null}
                                            </ListInformation>
                                        </InfoContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <InfoContainer>
                                            <ListInformation name="담당자 성명">
                                                {formData2Page.BookerName}
                                                {/* {formData2Page.CustomerType === '1' ? '없음(개인)' : formData2Page.BookerName} */}
                                            </ListInformation>
                                            <ListInformation name="담당자 이메일">
                                                {formData2Page.BookerEmail}
                                                {/* {formData2Page.CustomerType === '1' ? '없음(개인)' : formData2Page.BookerEmail} */}
                                            </ListInformation>
                                            <ListInformation name="담당자 연락처">
                                                {formData2Page.BookerPhone}
                                                {/* {formData2Page.CustomerType === '1' ? '없음(개인)' : formData2Page.BookerPhone} */}
                                            </ListInformation>
                                            <ListInformation name="이용시작 예정일">
                                                {`${moment(formData2Page.ContractStartDate).format('yyyy-MM-DD')} (${getEnglishDayToKorean(moment(formData2Page.ContractStartDate).format('ddd'))})`}
                                            </ListInformation>
                                            <ListInformation name="차량 모델명">
                                                {formData2Page.CarModel}
                                            </ListInformation>
                                            <ListInformation name="">
                                                &nbsp;
                                            </ListInformation>
                                            
                                            {/* 추후 프로모션 할인 할때까지 비활성화
                                            
                                            {productData.productCategory === 0 ?
                                                <ListInformation name="프로모션 할인">
                                                    {promotionDisCountMonth(formData2Page.ContractMonth)}%
                                                </ListInformation> :
                                                <ListInformation name="">
                                                    &nbsp;
                                                </ListInformation>} */}

                                        </InfoContainer>
                                    </Grid>
                                </Grid>
                            </Inner>
                        </>
                    )}
                    
                    {Intermediate ? null : 
                    <Inner>
                        {/* 프로모션 할인 관련 비활성화
                        <ResultPrice ProductCategory={productData.productCategory}>
                            {((promotionDisCountMonth(formData2Page.ContractMonth) >= 1)  && (productData.productCategory === 0)) ? 
                            <>
                                ({promotionDisCountMonth(formData2Page.ContractMonth)}% 할인적용)&nbsp;
                                {numberWithCommas( (productData.selectedHour * productData.selectedHourPrice) - Math.floor((productData.selectedHourPrice * (promotionDisCountMonth(formData2Page.ContractMonth) / 100)) *  productData.selectedHour ) )}
                                
                            </>
                            :
                            <>
                                {numberWithCommas(productData.selectedHour * productData.selectedHourPrice)}
                            </>
                            }
                        </ResultPrice> */}
                        <ResultPrice ProductCategory={productData.productCategory}>
                            {numberWithCommas(productData.selectedHour * productData.selectedHourPrice)}
                        </ResultPrice>
                    </Inner>}
                    
                    <Inner>
                        <StepButtonGroup flex={true} alignItems="center" justifyContent="flexEnd">
                            <ButtonStandard id='Step4Prev' outline onClick={() => {props.history.goBack()}}>이전</ButtonStandard>
                            <ButtonStandard id='Step4Next' onClick={async () => {
                                if (window.confirm('계약서를 요청합니다.\n계속하시겠습니까?')) {
                                    if (Intermediate) {
                                        setLoadingFlag(true)
                                        let token0 = 0
                                        let token2 = getLocalStorageDecrypt('token2');      // page2 정보
                                        let basicData = {
                                            
                                            ContractName : formData2Page.ContractName,
                                            ContractStartDate: token2.ContractStartDate,
                                            ComplateBusinessLicenseUrl: token2.ComplateBusinessLicenseUrl,                                        
                                            CarModel: token2.CarModel,
                                            CustomerType: token2.CustomerType,
                                            ContractMonth: token2.ContractMonth,
                                            BookerName: token2.BookerName,
                                            BookerEmail: token2.BookerEmail,
                                            BookerPhone: token2.BookerPhone,
                                            PurposeEtcStr: formData2Page.PurposeEtcStr
                                        }
                                        try {
                                            // let joinResult = await JoinFormMmExternal(basicData)
                                            // let slackResult = await SendSlack(basicData, joinResult.Message)
                                            let slackResult = await SendSlack2(basicData)
                                            
                                            if (true) {
                                                // await setEstimatePage02Live(token2,token0, basicData)
                                            }
                                            
                                                    
                                            // let sheetResult = await setEstimatePage03(basicData, joinResult.Message)
    
                                            // console.log('joinResult')
                                            // console.log(joinResult)
                                            // console.log('slackResult')
                                            // console.log(slackResult)
                                            
                                            setLoadingFlag(false)
                                            
                                            props.history.push(`${BUILD_TARGET}/complate?complateflag=1&intermediate=1`)
                                        }
                                        catch (e) {
                                            alert('처리중 오류가 발생했습니다. 다시 시도 부탁드립니다.')
                                        }
                                    }
                                    else {
                                        setLoadingFlag(true)
                                        let tokenChkResult = false;
                                        let token0 = getLocalStorageDecrypt('token0');      // 고객정보
                                        let token1 = getLocalStorageDecrypt('token1');      // page1 정보
                                        let token2 = getLocalStorageDecrypt('token2');      // page2 정보
                                        if (token0 !== null && token1 !== null && token2 !== null) {
                                            tokenChkResult = true;
                                        }
        
                                        if (tokenChkResult) {
                                            // console.log(token0)
                                            // console.log(token1)
                                            // console.log(token2)
        
                                            let basicData = {
                                                UserName: token0.UserName,
                                                UserPhone: token0.UserPhone,
                                                UserEmail: token0.UserEmail,
                                                ContractName : formData2Page.ContractName,
                                                ContractStartDate: token2.ContractStartDate,
                                                ComplateBusinessLicenseUrl: token2.ComplateBusinessLicenseUrl,
                                                StartArea: token1.formData.StartArea,
                                                EndArea: token1.formData.EndArea,
                                                CarModel: token2.CarModel,
                                                productCategory: token1.productCategory,
                                                CustomerType: token2.CustomerType,
                                                productCategoryName: token1.productCategory === 0 ? '월간모시러' : '연간모시러',
                                                oneDayHour: token1.selectedOneDayHour,
                                                UseHour: token1.selectedHour,
                                                HourPrice: token1.selectedHourPrice,
                                                AllPrice: (Number(token1.selectedHour) * Number(token1.selectedHourPrice)),
                                                ContractMonth: token2.ContractMonth,
                                                BookerName: token2.BookerName,
                                                BookerEmail: token2.BookerEmail,
                                                BookerPhone: token2.BookerPhone,
                                                PurposeEtcStr: formData2Page.PurposeEtcStr
                                            }
        
                                            try {
                                                // let joinResult = await JoinFormMmExternal(basicData)
                                                // let slackResult = await SendSlack(basicData, joinResult.Message)
                                                let slackResult = await SendSlack(basicData)
                                                
                                                if (true) {
                                                    // await setEstimatePage02Live(token2,token0, basicData)
                                                }
                                                
                                                        
                                                // let sheetResult = await setEstimatePage03(basicData, joinResult.Message)
        
                                                // console.log('joinResult')
                                                // console.log(joinResult)
                                                // console.log('slackResult')
                                                // console.log(slackResult)
                                                
                                                setLoadingFlag(false)
                                                //localStorage.clear();
                                                props.history.push(`${BUILD_TARGET}/complate?complateflag=1`)
                                            }
                                            catch (e) {
                                                alert('처리중 오류가 발생했습니다. 다시 시도 부탁드립니다.')
                                            }
        
                                        }
                                        else {
                                            alert('견적서를 작성할 수 없습니다. \n기본정보 입력 후 다시 시도해주세요.')
                                            //document.location.href = INIT_MOSILER_URL
                                        }
                                    }
                                }
                                
                                
                                

                            }}>계약서 요청하기</ButtonStandard>
                        </StepButtonGroup>
                    </Inner>
                </div>
            </div>
        </>
    );
};

export default ContractResultView;

