import styled from "styled-components";
function SubmitButton({ onSubmit, children, ...props }) {
  return (
    <Button onSubmit={onSubmit} {...props}>
      {children}
    </Button>
  );
}
const Button = styled.button`
  border: none;
  outline: 0;
  background: #191919;
  color: #fff;
  font-size: 16px;
  height: 52px;
  font-weight: 700;
  display: block;
  width: 100%;
`;
export default SubmitButton;
