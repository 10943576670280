import React from "react";
import styled from "styled-components";

function SubTitle({
  color,
  fontSize,
  fontWeight,
  textAlign,
  children,
  ...props
}) {
  return (
    <Headline
      color={color ? color : `inherit`}
      fontSize={fontSize ? fontSize : `inherit`}
      fontWeight={fontWeight ? fontWeight : `700`}
      textAlign={textAlign ? textAlign : `left`}
      {...props}
    >
      {children}
    </Headline>
  );
}
const Headline = styled.h3`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  @media (max-width: 1200px) {
    font-size: ${({ fontSize }) => fontSize === `40` && `32`}px;
  }
  @media (max-width: 640px) {
    font-size: ${({ fontSize }) => fontSize === `40` && `20`}px;
  }
`;
export default SubTitle;
