import React, { useEffect, useState } from 'react';
import { getLocalStorageDecrypt, setLocalStorageEncrypt, numberWithCommas } from '../../lib/Util'
import { SendSlack } from '../../lib/SlackApi';
import { JoinFormMmExternal } from '../../lib/MosilerJoinApi';
import { setEstimatePage03 } from '../../lib/GoogleSheetApi';
import { ETC_ID } from '../../InitConst';
import Loading from '../../components/Loading';
import moment from 'moment';
import Header from '../../components/Header';
import Inner from '../../components/Inner';
import TitleWithBorder from '../../components/TitleWithBorder';
import ButtonDownload from '../../components/ButtonDownload';
import { Grid } from '@mui/material';
import ListInformation, { InfoContainer } from '../../components/ListInformation';
import ResultPrice from '../../components/ResultPrice';
import ButtonStandard from '../../components/ButtonStandard';
import StepButtonGroup from '../../components/StepButtonGroup';
import FirstTitle from '../../components/FirstTitle';
import KakaoButton from '../../components/KakaoButton';
import { BUILD_TARGET_URI } from '../../InitConst';

const BUILD_TARGET = BUILD_TARGET_URI
const ANNUAL_MOSILER = (document.location.hostname ==='year.mosiler.com' || document.location.hostname ==='yeartest.mosiler.com' || (document.location.href.indexOf('http://mosiler.com/annual') >= 0))

const EstimateResultView = (props) => {
    const [loadingFlag, setLoadingFlag] = useState(true)
    const [productData, setProductData] = useState({
        productCategory: 0,
        selectedId: 0,
        customHour: 0,
        customPrice: 0,
        selectedHour: 0,
        selectedHourPrice: 0,
        selectedOneDayHour: 0
    })
    const [formData, setFormData] = useState({
        CustomerType: '1',
        ContractMonth: '12',
        Purpose: '1',
        Route: '1',
        BookerName: '',
        BookerEmail: '',
        BookerPhone: '',
        BusinessLicense: '',
        ContractStartDate: '',
        CarModel: '',
        StartArea: '',
        EndArea: '',
        ComplateBusinessLicenseUrl: '',
        CustomerTypeList: [
            { val: 1, name: '개인' },
            { val: 2, name: '사업자/법인' },
        ],
        PurposeList: [
            { val: 1, name: '회사 대표님 or 임원 수행' },
            { val: 2, name: '가정+회사 함께 사용' },
            { val: 3, name: 'VIP고객 의전' },
            { val: ETC_ID, name: '기타' },
        ],
        RouteList: [
            { val: 1, name: '인스타그램' },
            { val: 2, name: '페이스북' },
            { val: 3, name: '지인소개' },
            { val: 4, name: '모시러 홈페이지' },
            { val: ETC_ID, name: '기타' },
        ],
        ContractList: [
            { val: 1, name: '1개월' },
            { val: 3, name: '3개월' },
            { val: 6, name: '6개월' },
            { val: 12, name: '12개월' },
        ]
    })
    const [formData1Page, setFormData1Page] = useState({
        StartArea: '',
        EndArea: '',
        UseService: '0',
        UseServiceList: [
            { val: 0, name: '가능하다면 빠르게' },
            { val: 1, name: '1개월 이내' },
            { val: 2, name: '2개월 이내' },
        ],
        Route: '1',
        RouteList: [
            { val: 1, name: '인스타그램' },
            { val: 2, name: '페이스북' },
            { val: 3, name: '지인소개' },
            { val: 4, name: '모시러 홈페이지' },
            { val: ETC_ID, name: '기타' },
        ],
        EtcRequest: '',
        RouteEtcStr: '',
        Concierge:'0',
        ConciergeList: [
            { val: 0, name: '-선택해주세요-' },
            { val: 1, name: '오전' },
            { val: 2, name: '오후' },
            { val: 3, name: '상관없음' },
        ],
        Needs:'0',
        NeedsList: [
            { val: 0, name: '-선택시, 적합한 상품을 추천드립니다.-' },
            { val: 1, name: '비즈니스' },
            { val: 2, name: '골프' },
            { val: 3, name: '키즈/실버' },
        ],
    })
    const [userInfo, setUserInfo] = useState({
        UserName: '',
        UserPhone: '',
        UserEmail: ''
    })

    useEffect(() => {
        async function test() {
            const htmlTitle = document.querySelector("title");
            //htmlTitle.innerHTML = '월간모시러 웹 견적서'
            htmlTitle.innerHTML = ANNUAL_MOSILER ? '연간모시러 웹 견적서' : '월간모시러 웹 견적서'
            
            let tokenChkResult = false;
            let token0 = getLocalStorageDecrypt('token0');      // 고객정보
            let token1 = getLocalStorageDecrypt('token1');      // page1 정보
            if (token0 !== null && token1 !== null) {
                tokenChkResult = true;
            }

            if (tokenChkResult) {
                setUserInfo(token0)
                setProductData(token1)
                setFormData1Page(token1.formData)
            }
            else {
                alert('기본 정보가 존재 하지 않습니다.\n"상품 선택 및 기본정보 입력"의 내용을 입력해주세요.')
                props.history.replace(`${BUILD_TARGET}/estimate`)
            }
            setLoadingFlag(false)
        }
        test();
    }, [])

    const getListToValue = (val, list) => {
        let retData = ''
        for (let i in list) {
            if (Number(list[i].val) === Number(val)) {
                retData = list[i].name;
                break;
            }
        }
        return retData;
    }

    return (
        <>
            {loadingFlag ? <Loading /> : null}
            <KakaoButton />
            <div className='main_component'>
                <Header step="2" history={props.history}/>
                <div className='content'>
                    <Inner>
                        <FirstTitle title='견적 내용 확인' />
                        <TitleWithBorder title="견적 정보">
                            <ButtonDownload id="Step2Download" onClick={() => window.open(`${BUILD_TARGET}/estimate_export`)}>견적서 다운로드</ButtonDownload>
                        </TitleWithBorder>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <InfoContainer>
                                    <ListInformation name="이용 서비스">
                                        {productData.productCategory === 0 ? '월간모시러' : '연간모시러'}
                                    </ListInformation>
                                    <ListInformation name="이용 상품">
                                        {productData.productCategory === 0 ? `${productData.selectedHour / productData.selectedOneDayHour}일 ${productData.selectedOneDayHour}시간 상품` : `${productData.selectedHour}시간 상품`}
                                    </ListInformation>
                                    <ListInformation name="이용가능시간">
                                        월 {productData.selectedHour}시간
                                    </ListInformation>
                                    <ListInformation name="1일 최소 이용시간">
                                        {productData.selectedOneDayHour}시간
                                    </ListInformation>
                                    <ListInformation name="시간당 단가">
                                        {numberWithCommas(productData.selectedHourPrice)}원
                                    </ListInformation>
                                    <ListInformation name="이용목적">
                                        {getListToValue(formData1Page.Needs, formData1Page.NeedsList)}
                                    </ListInformation>
                                </InfoContainer>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <InfoContainer>
                                    <ListInformation name="서비스 이용시점">
                                        {getListToValue(formData1Page.UseService, formData1Page.UseServiceList)}
                                    </ListInformation>
                                    <ListInformation name="알게된 경로">
                                        {getListToValue(formData1Page.Route, formData1Page.RouteList)}
                                        {Number(formData1Page.Route) === ETC_ID ? ` (${formData1Page.RouteEtcStr})` : null}
                                    </ListInformation>
                                    <ListInformation name="예상 출발지역">
                                        {formData1Page.StartArea}
                                    </ListInformation>
                                    <ListInformation name="예상 종료지역">
                                        {formData1Page.EndArea}
                                    </ListInformation>
                                    <ListInformation name="그 외 요청사항">
                                        {formData1Page.EtcRequest}
                                    </ListInformation>
                                    <ListInformation name="상담가능시간대">
                                        {getListToValue(formData1Page.Concierge, formData1Page.ConciergeList)}
                                    </ListInformation>
                                </InfoContainer>
                            </Grid>
                        </Grid>
                    </Inner>
                    <Inner>
                        
                        <ResultPrice ProductCategory={productData.productCategory}>{numberWithCommas(productData.selectedHour * productData.selectedHourPrice)}</ResultPrice>
                    </Inner>
                    <Inner>
                        <StepButtonGroup flex={true} alignItems="center" justifyContent="flexEnd">
                            <ButtonStandard  id='Step2Prev' outline onClick={() => {props.history.goBack()}}>이전</ButtonStandard>
                            <ButtonStandard  id='Step2Next' onClick={async () => {
                                props.history.push(`${BUILD_TARGET}/contract`)
                                // setLoadingFlag(true)
                                // let tokenChkResult = false;
                                // let token0 = getLocalStorageDecrypt('token0');      // 고객정보
                                // let token1 = getLocalStorageDecrypt('token1');      // page1 정보
                                // let token2 = getLocalStorageDecrypt('token2');      // page2 정보
                                // if (token0 !== null && token1 !== null && token2 !== null) {
                                //     tokenChkResult = true;
                                // }

                                // if (tokenChkResult) {
                                //     console.log(token0)
                                //     console.log(token1)
                                //     console.log(token2)

                                //     let basicData = {
                                //         UserName: token0.UserName,
                                //         UserPhone: token0.UserPhone,
                                //         UserEmail: token0.UserEmail,
                                //         ContractStartDate: token2.ContractStartDate,
                                //         ComplateBusinessLicenseUrl: token2.ComplateBusinessLicenseUrl,
                                //         StartArea: token2.StartArea,
                                //         EndArea: token2.EndArea,
                                //         CarModel: token2.CarModel,
                                //         productCategory: token1.productCategory,
                                //         CustomerType: token2.CustomerType,
                                //         productCategoryName: token1.productCategory === 0 ? '월간모시러' : '연간모시러',
                                //         oneDayHour: token1.selectedOneDayHour,
                                //         UseHour: token1.selectedHour,
                                //         HourPrice: token1.selectedHourPrice,
                                //         AllPrice: (Number(token1.selectedHour) * Number(token1.selectedHourPrice)),
                                //         ContractMonth: token2.ContractMonth,
                                //         BookerName: token2.BookerName,
                                //         BookerEmail: token2.BookerEmail,
                                //         BookerPhone: token2.BookerPhone,
                                //     }

                                //     try {
                                //         let joinResult = await JoinFormMmExternal(basicData)
                                //         let slackResult = await SendSlack(basicData, joinResult.Message)
                                //         let sheetResult = await setEstimatePage03(basicData, joinResult.Message)

                                //         console.log('joinResult')
                                //         console.log(joinResult)
                                //         console.log('slackResult')
                                //         console.log(slackResult)
                                //         alert('계약서 초안 작성하기')
                                //         setLoadingFlag(false)
                                //     }
                                //     catch (e) {

                                //     }

                                // }
                                // else {
                                //     alert('견적서를 작성할 수 없습니다. \n기본정보 입력 후 다시 시도해주세요.')
                                //     //document.location.href = INIT_MOSILER_URL
                                // }

                            }}>다음</ButtonStandard>
                        </StepButtonGroup>
                    </Inner>
                </div>
            </div>
        </>
    );
};

export default EstimateResultView;

