import React, { useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';

import {
    IntroduceView,
    EstimateView,
    EstimateResultView,
    ContractView,
    ContractResultView,
    EstimateExportView,
    ComplateView
} from './pages';
import { BUILD_TARGET_URI } from './InitConst';
import './css/init.css'
import './css/common.css'
import './css/floatlabel.css'
import "react-datepicker/dist/react-datepicker.css";

const BUILD_TARGET = BUILD_TARGET_URI

function App() {
    return (
        <Switch>
            <Route exact path={`${BUILD_TARGET}/introduce`} component={IntroduceView} />
            <Route exact path={`${BUILD_TARGET}/annual`} component={IntroduceView} />
            <Route exact path={`${BUILD_TARGET}/`} component={EstimateView} />
            <Route exact path={`${BUILD_TARGET}/estimate`} component={EstimateView} />
            <Route exact path={`${BUILD_TARGET}/estimate_result`} component={EstimateResultView} />
            <Route exact path={`${BUILD_TARGET}/contract`} component={ContractView} />
            <Route exact path={`${BUILD_TARGET}/contract_result`} component={ContractResultView} />
            <Route exact path={`${BUILD_TARGET}/estimate_export`} component={EstimateExportView} />
            <Route exact path={`${BUILD_TARGET}/complate`} component={ComplateView} />
        </Switch>
    );
}

export default App;
