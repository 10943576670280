import React from "react";
import styled from "styled-components";

function TextBlock({
  color,
  fontSize,
  fontWeight,
  textAlign,
  children,
  ...props
}) {
  return (
    <Text
      color={color ? color : `inherit`}
      fontSize={fontSize ? fontSize : `20`}
      fontWeight={fontWeight ? fontWeight : `normal`}
      textAlign={textAlign ? textAlign : `left`}
      {...props}
    >
      {children}
    </Text>
  );
}
const Text = styled.p`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  @media (max-width: 640px) {
    font-size: ${({ fontSize }) => {
      if (fontSize === `24`) return `16`;
      if (fontSize === `20`) return `14`;
      if (fontSize === `16`) return `14`;
    }}px;
  }
`;
export default TextBlock;
