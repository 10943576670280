import React, { useEffect, useState } from 'react';
import { INIT_MOSILER_URL, CUSTOM_ID, ETC_ID } from '../../InitConst';

import {
    numberWithCommas,
    getQueryStringObject,
    setLocalStorageEncrypt,
    getLocalStorageDecrypt,
    setSessionStorageEncrypt,
    getSessionStorageDecrypt,
    isChkString,
    isChkEmail,
    isChkPhone,
    isChkUserInfo,
    getUserInfoEncrypt,
    isBusanFlag
} from '../../lib/Util'
import { SendEztoc } from '../../lib/EzTocApi';
import { getMMProductList, setEstimatePage01, setEstimatePage01Live } from '../../lib/GoogleSheetApi'

import FloatLabel from '../../components/FloatLabel';
import FloatLabelSelect from '../../components/FloatLabelSelect';

import Loading from '../../components/Loading';
import ButtonWithCheck from '../../components/ButtonWithCheck';
import { Grid, getInputBaseUtilityClass } from '@mui/material';
import TitleWithBorder from '../../components/TitleWithBorder';
import Inner from '../../components/Inner';
import ButtonWithProduct from '../../components/ButtonProduct';
import IconPrdChecked from '../../img/icon_prd_check.png';
import ButtonStandard from '../../components/ButtonStandard';
import StepButtonGroup from '../../components/StepButtonGroup';
import Header from '../../components/Header';
import FirstTitle from '../../components/FirstTitle';
import KakaoButton from '../../components/KakaoButton';
import { BUILD_TARGET_URI } from '../../InitConst';

const BUILD_TARGET = BUILD_TARGET_URI
const CUSTOM_INIT_HOUR = 80
const ANNUAL_MOSILER = (document.location.hostname ==='year.mosiler.com' || document.location.hostname ==='yeartest.mosiler.com' || (document.location.href.indexOf('/annual') >= 0))

const EstimateView = (props) => {

    const [productCategory, setProductCategory] = useState(
        ANNUAL_MOSILER ? 1 : 0
    )
    const [selectedId, setSelectedId] = useState(
        ANNUAL_MOSILER ? 1001 : 1
    )
    const [customHour, setCustomHour] = useState(CUSTOM_INIT_HOUR)
    const [customPrice, setCustomPrice] = useState(0)
    const [loadingFlag, setLoadingFlag] = useState(true)
    const [userInfo, setUserInfo] = useState({
        UserName: '',
        UserPhone: '',
        UserEmail: ''
    })
    const [formData, setFormData] = useState({
        StartArea: '',
        EndArea: '',
        UseService: '0',
        UseServiceList: [
            { val: 0, name: '-선택해주세요-' },
            { val: 1, name: '가능하다면 빠르게' },
            { val: 2, name: '1개월 이내' },
            { val: 3, name: '2개월 이내' },
        ],
        Route: '0',
        RouteList: [
            { val: 0, name: '-선택해주세요-' },
            { val: 1, name: '인스타그램' },
            { val: 2, name: '페이스북' },
            { val: 3, name: '지인소개' },
            { val: 4, name: '모시러 홈페이지' },
            { val: ETC_ID, name: '기타' },
        ],
        Concierge:'0',
        ConciergeList: [
            { val: 0, name: '-선택해주세요-' },
            { val: 1, name: '오전' },
            { val: 2, name: '오후' },
            { val: 3, name: '상관없음' },
        ],
        Needs:'0',
        NeedsList: [
            { val: 0, name: '-선택시, 적합한 상품을 추천드립니다.-' },
            { val: 1, name: '비즈니스' },
            { val: 2, name: '골프' },
            { val: 3, name: '키즈/실버' },
        ],
        RouteEtcStr: '',
        EtcRequest: '',
    })

    const [productListMm, setProductListMm] = useState({
        Custom: [{ id: 0, min: 0, max: 0, price: 0 }],
        MmList: [
            { id: 1, productHour: 80, oneDayHour: 8, hourPrice: 28600, discountFlag: 1, discountPercent: 5, discountHourPrice: 27170, allPrice: 300, },
            { id: 2, productHour: 90, oneDayHour: 8, hourPrice: 30000, discountFlag: 1, discountPercent: 5, discountHourPrice: 28500, allPrice: 286, },
            { id: 3, productHour: 90, oneDayHour: 8, hourPrice: 30000, discountFlag: 1, discountPercent: 5, discountHourPrice: 28500, allPrice: 286, },
            { id: 4, productHour: 90, oneDayHour: 8, hourPrice: 30000, discountFlag: 1, discountPercent: 5, discountHourPrice: 28500, allPrice: 286, },
            { id: 5, productHour: 90, oneDayHour: 8, hourPrice: 30000, discountFlag: 1, discountPercent: 5, discountHourPrice: 28500, allPrice: 286, },
        ],
        YmList: [
            { id: 101, productHour: 100, oneDayHour: 8, hourPrice: 28600, discountFlag: 1, discountPercent: 3, discountHourPrice: 29000, allPrice: 290, },
            { id: 101, productHour: 100, oneDayHour: 8, hourPrice: 28600, discountFlag: 1, discountPercent: 3, discountHourPrice: 29000, allPrice: 290, },
            { id: 101, productHour: 100, oneDayHour: 8, hourPrice: 28600, discountFlag: 1, discountPercent: 3, discountHourPrice: 29000, allPrice: 290, }
        ]
    })

    const [productListColor, setProductListColor] = useState({
    MmList: [
      { id: 1, color: "#ABABAB", borderColor: "#ABABAB" },
      { id: 2, color: "#8E8E8E", borderColor: "#8E8E8E" },
      { id: 3, color: "#787878", borderColor: "#787878" },
      { id: 4, color: "#666666", borderColor: "#666666" },
      { id: 5, color: "#4B4B4B", borderColor: "#4B4B4B" },
    ],
    YmList: [
      { id: 1, color: "#ABABAB", borderColor: "#ABABAB" },
      { id: 2, color: "#787878", borderColor: "#787878" },
      { id: 3, color: "#4B4B4B", borderColor: "#4B4B4B" },
      { id: 4, color: "#666666", borderColor: "#666666" },
      { id: 5, color: "#4B4B4B", borderColor: "#4B4B4B" },
    ],
  });

    useEffect(() => {
        async function test() {
            const htmlTitle = document.querySelector("title");
            //htmlTitle.innerHTML = '월간모시러 웹 견적서'
            htmlTitle.innerHTML = ANNUAL_MOSILER ? '연간모시러 웹 견적서' : '월간모시러 웹 견적서'

            if (false) {

                let obj = {}
                try {
                    obj = getQueryStringObject();
    
                }
                catch (e) {
    
                }
    
                /*
                최초로 넘어올때는 아래와 같은 형식으로 데이터가 반드시 와야한다.
                http://localhost:3000/?UserName=test&UserPhone=01011223344&UserEmail=test@test.com
    
                데이터가 하나라도 없을 경우는 아래 메시지가 나오고, 
                "잘못된 접근입니다. \n이전 화면에서 기본정보를 한 후 견적하기 버튼을 눌러주세요"로 표시하고, 뒤로 가기 해야할듯.
                앞에 입력한 데이터가 없다면 이력
    
                qs 데이터가 잘 넘어왔다면 다음단계는
                token0이 동일한지를 체크한다.
                Step4Next  /complate
                */
    
    
    
    
                // 데이터가 잘 넘어왔고, token0이 null이 아닐때
                if ((
                    (obj.UserPhone !== undefined && obj.UserPhone.length >= 1) &&
                    (obj.UserEmail !== undefined && obj.UserEmail.length >= 1) &&
                    (obj.UserName !== undefined && obj.UserName.length >= 1))) {
    
                    let tmpLSToken0 = getLocalStorageDecrypt('token0');
                    let tmpUserData = getUserInfoEncrypt({
                        UserName: obj.UserName,
                        UserPhone: obj.UserPhone,
                        UserEmail: obj.UserEmail,
                    })
    
                    // 데이터가 동일하다면
                    if (isChkUserInfo(tmpUserData, localStorage.getItem('token0'))) {
                        // 기존데이터를 보여준다
                        setUserInfo({
                            UserName: tmpLSToken0.UserName,
                            UserPhone: tmpLSToken0.UserPhone,
                            UserEmail: tmpLSToken0.UserEmail,
                        })
                    }
                    // 동일하지 않다면
                    else {
                        // 기존에 저장된 데이터 삭제.
                        // 변경된것으로 업데이트
                        localStorage.removeItem('token0')
                        localStorage.removeItem('token1')
                        localStorage.removeItem('token2')
                        setLocalStorageEncrypt('token0', {
                            UserName: obj.UserName,
                            UserPhone: obj.UserPhone,
                            UserEmail: obj.UserEmail,
                        })
                        setUserInfo({
                            UserName: obj.UserName,
                            UserPhone: obj.UserPhone,
                            UserEmail: obj.UserEmail,
                        })
                        // 초기 작업이 끝나고 qs 없애는 처리를 실시
                        // props.history.replace('/estimate')
    
                    }
    
    
    
                    // 처음 들어왔고 데이터가 없으니 qs 데이터 받아서 데이터 저장
                    // if (tmpLSToken0 === null) {
                    //     setLocalStorageEncrypt('token0', {
                    //         UserName: obj.UserName,
                    //         UserPhone: obj.UserPhone,
                    //         UserEmail: obj.UserEmail,
                    //     })
                    //     setUserInfo({
                    //         UserName: obj.UserName,
                    //         UserPhone: obj.UserPhone,
                    //         UserEmail: obj.UserEmail,
                    //     })
                    // }
                    // 기존에 들어왔고, 데이터가 있으니 ls의 데이터를 끌어와서 데이터를 업데이트한다.
                    // else {
                    //     setUserInfo({
                    //         UserName: tmpLSToken0.UserName,
                    //         UserPhone: tmpLSToken0.UserPhone,
                    //         UserEmail: tmpLSToken0.UserEmail,
                    //     })
                    // }
    
                    // session storage를 활용한다.
                    // 데이터는 localstorage와 동일하게 암호화.
    
                    let result = null;
    
                    let ssProductList = getSessionStorageDecrypt('token-1')
                    if (ssProductList === null) {
                        result = await getMMProductList();
                        setProductListMm(result)
                        setSessionStorageEncrypt('token-1', result)
                    }
                    else {
                        setProductListMm(ssProductList)
                        result = ssProductList;
                    }
    
    
                    // 커스터마이징 초기화
                    setCustomHour(CUSTOM_INIT_HOUR)
                    setCustomPrice(getCalcCustomToPrice(result.Custom, CUSTOM_INIT_HOUR))
    
                    // LocalStorage NullCheck                
                    let tmpLSToken1 = getLocalStorageDecrypt('token1');
                    if (tmpLSToken1 !== null) {
                        setProductCategory(tmpLSToken1.productCategory)
                        setSelectedId(tmpLSToken1.selectedId)
                        setCustomHour(tmpLSToken1.customHour)
                        setCustomPrice(getCalcCustomToPrice(result.Custom, tmpLSToken1.customHour))
                        setFormData(tmpLSToken1.formData)
                    }
    
    
                    /*
                    고객정보는 최초로 넘어올때에 1회만 저장하도록 해야하고, 
                    중간중간 수정하고 하다보면 데이터가 없어질 가능성이 있음
                    만약에라도 페이지 이동하는데에 최초 고객정보가 없을 경우는
                    2Page에서 대표자 이름, 대표자 전화번호, 대표자 Email정보를 받아야 할듯.
                    이 처리가 어느정도 처리 완료되면 구글시트 업로드 처리를 해야함.
    
                    금요일 오후
                    
                    if (tmpLSToken0 !== null) {
                        console.log(tmpLSToken0)
                    }
                    
                    */
                }
                else {
                    if (getLocalStorageDecrypt('token0') !== null) {
                        let tmpLSToken0 = getLocalStorageDecrypt('token0');
                        // 기존데이터를 보여준다
                        setUserInfo({
                            UserName: tmpLSToken0.UserName,
                            UserPhone: tmpLSToken0.UserPhone,
                            UserEmail: tmpLSToken0.UserEmail,
                        })
                        
    
                        let result = null;
    
                        let ssProductList = getSessionStorageDecrypt('token-1')
                        if (ssProductList === null) {
                            result = await getMMProductList();
                            setProductListMm(result)
                            setSessionStorageEncrypt('token-1', result)
                        }
                        else {
                            setProductListMm(ssProductList)
                            result = ssProductList;
                        }
    
    
                        // 커스터마이징 초기화
                        setCustomHour(CUSTOM_INIT_HOUR)
                        setCustomPrice(getCalcCustomToPrice(result.Custom, CUSTOM_INIT_HOUR))
    
                        // LocalStorage NullCheck                
                        let tmpLSToken1 = getLocalStorageDecrypt('token1');
                        if (tmpLSToken1 !== null) {
                            setProductCategory(tmpLSToken1.productCategory)
                            setSelectedId(tmpLSToken1.selectedId)
                            setCustomHour(tmpLSToken1.customHour)
                            setCustomPrice(getCalcCustomToPrice(result.Custom, tmpLSToken1.customHour))
                            setFormData(tmpLSToken1.formData)
                        }
                    }
                    else {
                        alert('잘못된 접근입니다.\n이전 화면에서 기본정보를 입력한 후\n견적하기 버튼을 눌러주세요')
                        document.location.href = INIT_MOSILER_URL
                    }
    
                }
    
                setLoadingFlag(false)
            }
            else {
                setLoadingFlag(true)
                let tmpLSToken0 = getLocalStorageDecrypt('token0');
                if (tmpLSToken0 !== null) {
                    // 기존데이터를 보여준다
                    setUserInfo({
                        UserName: tmpLSToken0.UserName,
                        UserPhone: tmpLSToken0.UserPhone,
                        UserEmail: tmpLSToken0.UserEmail,
                    })
                }
                
                let result = null;
        
                let ssProductList = getSessionStorageDecrypt('token-1')
                if (ssProductList === null) {
                    result = await getMMProductList();
                    setProductListMm(result)
                    setSessionStorageEncrypt('token-1', result)
                }
                else {
                    setProductListMm(ssProductList)
                    result = ssProductList;
                }
    
    
                // 커스터마이징 초기화
                setCustomHour(CUSTOM_INIT_HOUR)
                setCustomPrice(getCalcCustomToPrice(result.Custom, CUSTOM_INIT_HOUR))
    
                // LocalStorage NullCheck                
                let tmpLSToken1 = getLocalStorageDecrypt('token1');
                if (tmpLSToken1 !== null) {
                    setProductCategory(tmpLSToken1.productCategory)
                    setSelectedId(tmpLSToken1.selectedId)
                    setCustomHour(tmpLSToken1.customHour)
                    setCustomPrice(getCalcCustomToPrice(result.Custom, tmpLSToken1.customHour))
                    // 새로운 항목 추가시, 아래에 대한 처리가 필요함.
                    // 아래의 처리를 하지 않으면 기존 Localstorage에 저장된 데이터와의 호환성이 맞지 않아서 
                    // 하얀색으로 화면이 나오면서 이용을 못하게 된다.
                    if (tmpLSToken1.formData.Concierge !== undefined) {
                        setFormData(tmpLSToken1.formData)    
                    }
                    else {
                        setFormData({
                            ...tmpLSToken1.formData,
                            Concierge:'0',
                            ConciergeList: [
                                { val: 0, name: '-선택해주세요-' },
                                { val: 1, name: '오전' },
                                { val: 2, name: '오후' },
                                { val: 3, name: '상관없음' },
                            ],
                        })
                    }

                    if (tmpLSToken1.formData.Needs !== undefined) {
                        setFormData(tmpLSToken1.formData)    
                    }
                    else {
                        setFormData({
                            ...tmpLSToken1.formData,
                            Needs:'0',
                            NeedsList: [
                                { val: 0, name: '-선택시, 적합한 상품을 추천드립니다.-' },
                                { val: 1, name: '비즈니스' },
                                { val: 2, name: '골프' },
                                { val: 3, name: '키즈/실버' },
                            ],
                        })
                    }
                    
                }
                setLoadingFlag(false)
            }

        }
        test();
    }, [])
    const getCalcCustomToPrice = (tcArr, hour) => {
        let retData = 0;
        for (let i in tcArr) {
            if (hour >= tcArr[i].min && hour <= tcArr[i].max - 1) {
                retData = tcArr[i].price
            }
        }
        return retData;
    }

    const validateChkCustomHour = (price) => {
        if (price === 0) return true;
        return false;
    }

    const ProductNodeComponentMm = (arrData) => {
        return arrData.map((data, idx) => {
            if (isBusanFlag()) {
                if (data.productHour === 80 || data.productHour === 112) {
                    return <></>
                }
                
                return (<Grid item xs={12} sm={6} md={6} lg={4} xl={2.4}>
                    <ButtonWithProduct
                        bgColor={productListColor.MmList[idx].color}
                        borderColor={productListColor.MmList[idx].borderColor}
                        className={selectedId === data.id ? 'product_node_mm button_selected' : 'product_node_mm'}
                        key={idx}
                        onClick={() => setSelectedId(data.id)}
                    >
                        <div className='pd_header'>
                            {data.productHour / data.oneDayHour >= 30 ? 
                                <span>{data.productHour / data.oneDayHour}일 {data.oneDayHour}시간 상품</span> : 
                                <span>{data.productHour / data.oneDayHour}일 상품</span>
                            }
                            
                        </div>
                        <div className="pd_body">
                            <div className='pd_content'>
                                파트너를 <br />
                                월 <b className="primary">{data.productHour}시간</b><br />
                                <div className="pd_oneDayHour">
                                    1일 최소 <b className="primary">{data.oneDayHour}시간</b> 이상
                                </div>
                            </div>
                        </div>
                    </ButtonWithProduct>
                    <div className="pd_checked">
                        {
                            selectedId === data.id && (
                                <img src={IconPrdChecked} alt="selected" />
                            )
                        }
                    </div>
                </Grid>)
                
            }
            return (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2.4}>
                    <ButtonWithProduct
                        bgColor={productListColor.MmList[idx].color}
                        borderColor={productListColor.MmList[idx].borderColor}
                        className={selectedId === data.id ? 'product_node_mm button_selected' : 'product_node_mm'}
                        key={idx}
                        onClick={() => setSelectedId(data.id)}
                    >
                        <div className='pd_header'>
                            {/* <span>{data.productHour}시간 상품</span> */}
                            {data.productHour / data.oneDayHour >= 30 ? 
                                <span>{data.productHour / data.oneDayHour}일 {data.oneDayHour}시간 상품</span> : 
                                <span>{data.productHour / data.oneDayHour}일 상품</span>
                            }
                            
                        </div>
                        <div className="pd_body">
                            <div className='pd_content'>
                                파트너를 <br />
                                월 <b className="primary">{data.productHour}시간</b><br />
                                <div className="pd_oneDayHour">
                                    1일 최소 <b className="primary">{data.oneDayHour}시간</b> 이상
                                </div>
                            </div>
                            {/* <div className='pd_content3'>
                                <strong>시간당 단가</strong>
                                {data.discountFlag ?
                                    <div className="price_container">
                                        <div className="delete_price"><del>{numberWithCommas(data.hourPrice)}</del></div>
                                        <div className="result_price">{numberWithCommas(data.discountHourPrice)}</div>
                                        <div className='discount_percent'>{data.discountPercent}% OFF</div>
                                    </div> :
                                    <div className='price_tbl2'>
                                        <div className="result_price">{data.discountHourPrice}</div>
                                    </div>
                                }
                            </div> */}
                        </div>
                        {/* <div className='pd_footer'>
                            월 <span>{data.allPrice}</span>만원
                        </div> */}
                    </ButtonWithProduct>
                    <div className="pd_checked">
                        {
                            selectedId === data.id && (
                                <img src={IconPrdChecked} alt="selected" />
                            )
                        }
                    </div>
                </Grid>
            )
        })
    }

    // 원본
    // const ProductNodeComponentMm = (arrData) => {
    //     return arrData.map((data, idx) => {
    //         return (
    //             <Grid item xs={12} sm={6} md={6} lg={4} xl={2.4}>
    //                 <ButtonWithProduct
    //                     bgColor={productListColor.MmList[idx].color}
    //                     className={selectedId === data.id ? 'product_node_mm button_selected' : 'product_node_mm'}
    //                     key={idx}
    //                     onClick={() => setSelectedId(data.id)}
    //                 >
    //                     <div className='pd_header'>
    //                         <span>{data.productHour}시간 상품</span>
    //                     </div>
    //                     <div className="pd_body">
    //                         <div className='pd_content'>
    //                             파트너를 <br />
    //                             월 <b className="primary">{data.productHour}시간</b> 동안 <br />
    //                             <div className="pd_oneDayHour">
    //                                 1일 최소 <b className="primary">{data.oneDayHour}시간</b> 이상
    //                             </div>
    //                         </div>
    //                         <div className='pd_content3'>
    //                             <strong>시간당 단가</strong>
    //                             {data.discountFlag ?
    //                                 <div className="price_container">
    //                                     <div className="delete_price"><del>{numberWithCommas(data.hourPrice)}</del></div>
    //                                     <div className="result_price">{numberWithCommas(data.discountHourPrice)}</div>
    //                                     <div className='discount_percent'>{data.discountPercent}% OFF</div>
    //                                 </div> :
    //                                 <div className='price_tbl2'>
    //                                     <div className="result_price">{data.discountHourPrice}</div>
    //                                 </div>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className='pd_footer'>
    //                         월 <span>{data.allPrice}</span>만원
    //                     </div>
    //                 </ButtonWithProduct>
    //                 <div className="pd_checked">
    //                     {
    //                         selectedId === data.id && (
    //                             <img src={IconPrdChecked} alt="selected" />
    //                         )
    //                     }
    //                 </div>
    //             </Grid>
    //         )
    //     })
    // }


    const ProductNodeComponentMmCustom = () => (
        <div
            className={selectedId === CUSTOM_ID ? 'product_node_mm selected_mm' : 'product_node_mm'}
            onClick={() => {
                setSelectedId(CUSTOM_ID)
                document.querySelector('#customTextBox').focus();
            }}
        >
            <div className='pd_header'>
                <span>커스터마이징</span>
            </div>
            <hr className='hr_custom' />
            <div className='pd_content_custom'>
                <span>상품을 자유롭게</span>
                <span><b>커스터마이징!</b></span>
                <span>1일 최소 <b>8시간</b> 이상</span>
                <span>&nbsp;</span>
                <span>
                    월
                    <input
                        type='text'
                        id='customTextBox'
                        value={customHour}
                        onChange={(e) => {
                            setCustomHour(e.target.value)
                            setCustomPrice(getCalcCustomToPrice(productListMm.Custom, e.target.value))
                        }}
                        style={{
                            width: '40px',
                            textAlign: 'center',
                            borderRadius: '5px',
                            fontWeight: 'bold',
                            margin: '0px 5px',
                            border: '2px solid #aaa'
                        }}
                    />
                    시간
                </span>
                {validateChkCustomHour(customPrice) ? <span className='warning_hour'>범위:40~749시간</span> : null}

            </div>

            <hr className='hr_custom' />
            <div className='pd_content3'>
                <span>시간당 단가</span>
                <div className='price_tbl2'>
                    <span className='result_price'>{numberWithCommas(customPrice)}</span>
                </div>

            </div>
            <div className='pd_footer'>
                <span>{((customPrice * customHour) + '').substring(0, 3)}만원</span>
            </div>
        </div>
    )


    const ProductNodeComponentYm = (arrData) => {
        return arrData.map((data, idx) => {
            return (
                <Grid item xs={12} sm={4} md={6} lg={4} xl={4}>
                    <ButtonWithProduct
                        bgColor={productListColor.YmList[idx].color}
                        borderColor={productListColor.YmList[idx].borderColor}
                        className={selectedId === data.id ? 'product_node_mm button_selected' : 'product_node_mm'}
                        key={idx}
                        onClick={() => setSelectedId(data.id)}
                    >
                        <div className='pd_header'>
                            <span>{data.productHour}시간 상품</span>
                        </div>
                        <div className="pd_body">
                            <div className='pd_content'>
                                파트너를 <br />
                                연 <b className="primary">{data.productHour}시간</b><br />
                                <div className="pd_oneDayHour">
                                    1일 최소 <b className="primary">{data.oneDayHour}시간</b> 이상
                                </div>
                                {(data.id === 1004 || data.id === 1005) ? <div><b className="event">(프로모션 행사중)</b></div> : <div>&nbsp;</div>}
                                
                            </div>
                            {/* <div className='pd_content3'>
                                <strong>시간당 단가</strong>
                                {data.discountFlag ?
                                    <div className="price_container">
                                        <div className="delete_price"><del>{numberWithCommas(data.hourPrice)}</del></div>
                                        <div className="result_price">{numberWithCommas(data.discountHourPrice)}</div>
                                        <div className='discount_percent'>{data.discountPercent}% OFF</div>
                                    </div> :
                                    <div className='price_tbl2'>
                                        <div className="result_price">{data.discountHourPrice}</div>
                                    </div>
                                }

                            </div> */}
                        </div>
                        {/* <div className='pd_footer'>
                            총 <span>{data.allPrice}</span>만원
                        </div> */}
                    </ButtonWithProduct>
                    <div className="pd_checked">
                        {
                            selectedId === data.id && (
                                <img src={IconPrdChecked} alt="selected" />
                            )
                        }
                    </div>
                </Grid>
            )
        })

    }

    // 원본
    // const ProductNodeComponentYm = (arrData) => {
    //     return arrData.map((data, idx) => {
    //         return (
    //             <Grid item xs={12} sm={4} md={6} lg={4} xl={4}>
    //                 <ButtonWithProduct
    //                     bgColor={productListColor.YmList[idx].color}
    //                     className={selectedId === data.id ? 'product_node_mm button_selected' : 'product_node_mm'}
    //                     key={idx}
    //                     onClick={() => setSelectedId(data.id)}
    //                 >
    //                     <div className='pd_header'>
    //                         <span>{data.productHour}시간 상품</span>
    //                     </div>
    //                     <div className="pd_body">
    //                         <div className='pd_content'>
    //                             파트너를 <br />
    //                             연 <b className="primary">{data.productHour}시간</b> 동안 <br />
    //                             <div className="pd_oneDayHour">
    //                                 1일 최소 <b className="primary">{data.oneDayHour}시간</b> 이상
    //                             </div>
    //                         </div>
    //                         <div className='pd_content3'>
    //                             <strong>시간당 단가</strong>
    //                             {data.discountFlag ?
    //                                 <div className="price_container">
    //                                     <div className="delete_price"><del>{numberWithCommas(data.hourPrice)}</del></div>
    //                                     <div className="result_price">{numberWithCommas(data.discountHourPrice)}</div>
    //                                     <div className='discount_percent'>{data.discountPercent}% OFF</div>
    //                                 </div> :
    //                                 <div className='price_tbl2'>
    //                                     <div className="result_price">{data.discountHourPrice}</div>
    //                                 </div>
    //                             }

    //                         </div>
    //                     </div>
    //                     <div className='pd_footer'>
    //                         총 <span>{data.allPrice}</span>만원
    //                     </div>
    //                 </ButtonWithProduct>
    //                 <div className="pd_checked">
    //                     {
    //                         selectedId === data.id && (
    //                             <img src={IconPrdChecked} alt="selected" />
    //                         )
    //                     }
    //                 </div>
    //             </Grid>
    //         )
    //     })

    // }
    const getListToValue = (val, list) => {
        let retData = ''
        for (let i in list) {
            if (Number(list[i].val) === Number(val)) {
                retData = list[i].name;
                break;
            }
        }
        return retData;

    }


    const getSelectedMmProduct = (id) => {
        let retData = {};
        // 커스터마이징
        if (id === CUSTOM_ID) {
            retData = {
                selectedHour: Number(customHour),
                selectedHourPrice: Number(customPrice),
                selectedOneDayHour: 8
            }
        }
        else {
            for (let i in productListMm.MmList) {
                if (productListMm.MmList[i].id === id) {
                    retData = {
                        selectedHour: productListMm.MmList[i].productHour,
                        selectedHourPrice: productListMm.MmList[i].discountHourPrice,
                        selectedOneDayHour: productListMm.MmList[i].oneDayHour
                    }
                }
            }
            for (let i in productListMm.YmList) {
                if (productListMm.YmList[i].id === id) {
                    retData = {
                        selectedHour: productListMm.YmList[i].productHour,
                        selectedHourPrice: productListMm.YmList[i].discountHourPrice,
                        selectedOneDayHour: productListMm.YmList[i].oneDayHour
                    }
                }
            }
        }
        return retData;
    }

    const ProductCategoryViewComponent = () => {
        if (ANNUAL_MOSILER) {
            return (<>
                <Grid item xs={12}>
                    <ButtonWithCheck className={productCategory === 1 ? 'button_selected' : ''} onClick={() => setProductCategory(1)} flex="true">
                        <span className="button-text">연간모시러</span>
                        <span className="button-subtext">1년 단위 정액권 서비스</span>
                    </ButtonWithCheck>
                </Grid>
            </>)
        }
        if (isBusanFlag()) {
            return (
                <>
                    <Grid item xs={12}>
                        <ButtonWithCheck className={productCategory === 0 ? ' button_selected' : ''} onClick={() => setProductCategory(0)} flex="true">
                            <span className="button-text">월간모시러</span>
                            <span className="button-subtext">월 단위 정기 구독 서비스</span>
                        </ButtonWithCheck>
                    </Grid>
                </>
            )
        }
        

        return (<>
            <Grid item xs={6}>
                <ButtonWithCheck className={productCategory === 0 ? ' button_selected' : ''} onClick={() => setProductCategory(0)} flex="true">
                    <span className="button-text">월간모시러</span>
                    <span className="button-subtext">월 단위 정기 구독 서비스</span>
                </ButtonWithCheck>
            </Grid>
            <Grid item xs={6}>
                <ButtonWithCheck className={productCategory === 1 ? 'button_selected' : ''} onClick={() => setProductCategory(1)} flex="true">
                    <span className="button-text">연간모시러</span>
                    <span className="button-subtext">1년 단위 정액권 서비스</span>
                </ButtonWithCheck>
            </Grid>
        </>)
    }

    return (
        <>
            {loadingFlag ? <Loading /> : null}
            <KakaoButton />
            <div className='main_component'>
                <Header step="1" history={props.history} />
                <div className='content'>
                    <Inner>
                        <FirstTitle title='상품 선택 및 기본정보 입력' />
                        <TitleWithBorder title="이용 서비스 선택" />
                        <Grid container spacing={2}>
                            {ProductCategoryViewComponent()}
                        </Grid>
                    </Inner>
                    <Inner>
                        {/* <TitleWithBorder title="상품 선택" subtext="* VAT 포함" /> */}
                        <TitleWithBorder title="상품 선택" subtext="" />
                        <Grid container spacing={{ xs: 1 }}>
                            {productCategory === 0 ? <>{ProductNodeComponentMm(productListMm.MmList)}</> : ProductNodeComponentYm(productListMm.YmList)}
                        </Grid>
                    </Inner>
                    <Inner>
                        <TitleWithBorder title="견적 기본정보" />
                        <Grid container spacing={{ xs: 1 }}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                <FloatLabel formId={'UserName'} name={'담당자 성함'} value={userInfo.UserName} originData={userInfo} onChange={setUserInfo} placeholder='' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                <FloatLabel formId={'UserPhone'} name={'담당자 연락처'} value={userInfo.UserPhone} originData={userInfo} onChange={setUserInfo} placeholder='' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                <FloatLabel formId={'UserEmail'} name={'Email'} value={userInfo.UserEmail} originData={userInfo} onChange={setUserInfo} placeholder='' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                <FloatLabel formId={'StartArea'} name={'예상 출발지역'} value={formData.StartArea} originData={formData} onChange={() => { }} 
                                onClick={(e) => {
                                    new window.daum.Postcode({
                                        oncomplete: function (data) {
                                            setFormData({
                                                ...formData,
                                                StartArea: `${data.sido} ${data.sigungu} ${data.bname}`
                                            })
                                        }
                                    }).open();
                                }} placeholder='클릭/터치하여 주소입력(동까지표시)' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                <FloatLabel formId={'EndArea'} name={'예상 종료지역'} value={formData.EndArea} originData={formData} onChange={() => { }} 
                                onClick={(e) => {
                                    new window.daum.Postcode({
                                        oncomplete: function (data) {
                                            setFormData({
                                                ...formData,
                                                EndArea: `${data.sido} ${data.sigungu} ${data.bname}`
                                            })
                                        }
                                    }).open();
                                }} placeholder='클릭/터치하여 주소입력(동까지표시)' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                <FloatLabelSelect formId={'UseService'} name={'서비스 이용시점'} value={formData.UseService} originData={formData} onChange={setFormData} list={formData.UseServiceList} inputFormCondition={5} inputFormPlaceHolder='서비스 이용시점' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                <FloatLabelSelect formId={'Route'} name={'알게된 경로'} value={formData.Route} originData={formData} onChange={setFormData} list={formData.RouteList} inputFormCondition={5} inputFormPlaceHolder='알게된 경로 선택' />
                            </Grid>
                            {Number(formData.Route) === ETC_ID ? (
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <FloatLabel formId={'RouteEtcStr'} name={'알게된 경로 기타'} value={formData.RouteEtcStr} originData={formData} onChange={setFormData} placeholder='검색' />
                                </Grid>
                            ) : null}

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                <FloatLabel formId={'EtcRequest'} name={'그외요청사항'} value={formData.EtcRequest} originData={formData} onChange={setFormData} placeholder='추가로 요청할 내용을 작성' noValidate />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                <FloatLabelSelect formId={'Concierge'} name={'상담가능시간대'} value={formData.Concierge} originData={formData} onChange={setFormData} list={formData.ConciergeList} inputFormCondition={5} inputFormPlaceHolder='상담가능시간대' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                <FloatLabelSelect formId={'Needs'} name={'이용목적'} value={formData.Needs} originData={formData} onChange={setFormData} list={formData.NeedsList} inputFormCondition={5} inputFormPlaceHolder='이용목적' />
                            </Grid>
                        </Grid>
                    </Inner>
                    <Inner>
                        <StepButtonGroup flex={true} alignItems="center" justifyContent="flexEnd">
                            <ButtonStandard id='Step1Next' onClick={async () => {
                                // Validation check
                                let checkArticleList = [];
                                if (!isChkString(userInfo.UserName)) {
                                    checkArticleList.push('담당자 성함')
                                }
                                if (!isChkPhone(userInfo.UserPhone)) {
                                    checkArticleList.push("담당자 연락처 (하이픈(-) 제외.)")
                                }
                                if (!isChkEmail(userInfo.UserEmail)) {
                                    checkArticleList.push('Email')
                                }
                                if (!isChkString(formData.StartArea)) {
                                    checkArticleList.push('예상 출발지역')
                                }
                                if (!isChkString(formData.EndArea)) {
                                    checkArticleList.push('예상 종료지역')
                                }
                                if (formData.UseService === '0') {
                                    checkArticleList.push('서비스 이용시점')
                                }
                                if (formData.Route === '0') {
                                    checkArticleList.push('알게된 경로')
                                }
                                if ((Number(formData.Route) === ETC_ID) && (!isChkString(formData.RouteEtcStr))) {
                                    checkArticleList.push('알게된 경로 기타')
                                }
                                if (formData.Concierge === '0') {
                                    checkArticleList.push('상담가능시간대')
                                }
                                if (formData.Needs === '0') {
                                    checkArticleList.push('이용목적')
                                }
                                
                                // if (!isChkString(formData.EtcRequest)) {
                                //     checkArticleList.push('그외요청사항')
                                // }

                                if (checkArticleList.length >= 1) {
                                    let msg = '아래 항목을 입력해주세요.\n'
                                    for (let i in checkArticleList) {
                                        msg += `  - ${checkArticleList[i]}\n`
                                    }
                                    alert(msg)
                                    return;
                                }
                                
                                // Step1 Process
                                setLoadingFlag(true)
                                
                                let selectedProductInfo = getSelectedMmProduct(selectedId)
                                let infoPage1 = {
                                    productCategory,
                                    selectedId,         // selectedId === CUSTOM_ID
                                    customHour,
                                    customPrice,
                                    ...selectedProductInfo,
                                    formData: formData,
                                    RouteString: getListToValue(formData.Route, formData.RouteList),
                                    UseServiceString: getListToValue(formData.UseService, formData.UseServiceList),
                                    ConciergeString: getListToValue(formData.Concierge, formData.ConciergeList),
                                    NeedsString: getListToValue(formData.Needs, formData.NeedsList),
                                }
                                
                                
                                let selectedProductString = '';
                                if (productCategory === 0) {
                                    selectedProductString += `월간모시러 ${selectedProductInfo.selectedHour / selectedProductInfo.selectedOneDayHour}일 ${selectedProductInfo.selectedOneDayHour}시간 상품`
                                }
                                else {
                                    selectedProductString += `연간모시러 ${Number(selectedProductInfo.selectedHour)}시간 상품`
                                }
                                localStorage.setItem('selectedProductString', selectedProductString);
                                
                                /*
                                CustomerTypeString: getListToValue(formData.CustomerType, formData.CustomerTypeList),
                                PurposeString: getListToValue(formData.Purpose, formData.PurposeList),
                                ContractMonthString: getListToValue(formData.ContractMonth, formData.ContractList),                                
                                */

                                let tmpLSToken0 = getLocalStorageDecrypt('token0');
                                if (tmpLSToken0 === null) setLocalStorageEncrypt('token0', userInfo)

                                setLocalStorageEncrypt('token1', infoPage1)

                                if (true) {
                                    let tmpInflow = localStorage.getItem('inflow');
                                    if (tmpInflow === null) {
                                        tmpInflow = '알수없음'
                                    }
                                    // 견적서1 알림톡은 최초 1번만 발송.
                                    let tmpeztocFlag2 = localStorage.getItem('eztocFlag2');
                                    if (tmpeztocFlag2 === null) {
                                        await SendEztoc({
                                            eztocType:'estimate',
                                            UserName : userInfo.UserName,
                                            UserPhone : userInfo.UserPhone,
                                            inflow: tmpInflow
                                        })
                                        localStorage.setItem('eztocFlag2', 1);
                                    }

                                    await setEstimatePage01Live(infoPage1, userInfo);
                                    
                                    
                                }
                               
                                setLoadingFlag(false)
                                props.history.push(`${BUILD_TARGET}/estimate_result`)
                            }}>견적 확인하기
                            
                            </ButtonStandard>

                        </StepButtonGroup>
                    </Inner>
                </div>
            </div>
        </>

    );
};

export default EstimateView;

