import React from "react";
import styled from "styled-components";
function ThreeColumnGrid({ children, ...props }) {
  return <GridList {...props}>{children}</GridList>;
}
const GridList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  @media (max-width: 1200px) {
    gap: 20px;
  }
  @media (max-width: 640px) {
    display: block;
    li + li {
      margin-top: 57px;
    }
  }
`;
export default ThreeColumnGrid;
