import React from "react";
import styled from "styled-components";
import ImageTarget01 from "../../assets/images/img-target-01.png";
import ImageTarget01_01 from "../../assets/images/img-target-01-01.png";
import ImageTarget02 from "../../assets/images/img-target-02.png";
import ImageTarget03 from "../../assets/images/img-target-03.png";
import GridItem from "./Layout/GridItem";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import ThreeColumnGrid from "./Layout/ThreeColumnGrid";
import SubTitle from "./Text/SubTitle";
import TextBlock from "./Text/TextBlock";
function TargetContainer({ children }) {
  return (
    <Section padding="100px 0">
      <InnerSection>
        <SubTitle fontSize="40" data-aos="fade-up" data-aos-duration="1000">
          이용 대상
        </SubTitle>
        <ColumnGrid>
          <GridItem data-aos="fade-up" data-aos-duration="1000">
            <TargetImage src={ImageTarget01_01} alt="icon" />
            <TextBlock fontSize="16" textAlign="center">
              수행기사 공백기간 동안 <br />
              단기 서비스가 필요하신 고객
            </TextBlock>
          </GridItem>
          <GridItem
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="50"
          >
            <TargetImage src={ImageTarget02} alt="icon" />
            <TextBlock fontSize="16" textAlign="center">
              수행기사를 고용/관리 <br />
              고민하지 않고 편하게 쓰고 싶은 고객
            </TextBlock>
          </GridItem>
          <GridItem
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <TargetImage src={ImageTarget03} alt="icon" />
            <TextBlock fontSize="16" textAlign="center">
              개인 사생활 노출 없이 <br />
              자유롭게 이용하고 싶은 고객
            </TextBlock>
          </GridItem>
        </ColumnGrid>
      </InnerSection>
    </Section>
  );
}
const TargetImage = styled.img`
  display: block;
  width: 378px;
  max-width: 100%;
  margin: 0 auto 29px;
  @media (max-width: 640px) {
    width: 100%;
    margin: 0 auto 16px;
  }
`;
const ColumnGrid = styled(ThreeColumnGrid)`
  margin-top: 53px;
  @media (max-width: 640px) {
    margin-top: 24px;
  }
`;
export default TargetContainer;
