import React from 'react';
import styled from '@emotion/styled';

export const FormContainer = styled.div`
    margin-top: 32px;
`;
const FormTitle = styled.h4`
    font-size: 16px;
    margin-bottom: 16px;
`;

const TitleFormLabel = ({  title, ...props }) => {
    return (
        <FormTitle>{title}</FormTitle>
    );
};

export default TitleFormLabel;
