import React from 'react';
import '../css/components.css';
import styled from '@emotion/styled';

const ButtonGroup = styled.div`
    width: 100%;
    margin-top: 60px;
    @media only screen and (max-width: 900px) {
      margin-top: 0;
    }
    @media only screen and (max-width: 600px) {
      button {
        width: 100%;
      }
    }
    button + button {
      margin-left: 20px;
    }
    ${({ flex }) => {
        if (flex) {
          return `
            display: flex;
          `;
        }
        return ``;
    }}
    ${({ alignItems }) => {
        if (alignItems === `center`) {
          return `
            align-items: center;
          `;
        }
        if (alignItems === `flexStart`) {
          return `
            align-items: flex-start;
          `;
        }
        if (alignItems === `flexEnd`) {
          return `
            align-items: flex-end;
          `;
        }
        return ``;
    }}
    ${({ justifyContent }) => {
        if (justifyContent === `center`) {
          return `
            justify-content: center;
          `;
        }
        if (justifyContent === `flexStart`) {
          return `
            justify-content: flex-start;
          `;
        }
        if (justifyContent === `flexEnd`) {
          return `
            justify-content: flex-end;
          `;
        }
        return ``;
    }}
`;

const StepButtonGroup = ({ flex, alignItems, justifyContent, children, ...props }) => {
    return (
        <ButtonGroup
            flex={flex}
            alignItems={alignItems}
            justifyContent={justifyContent}
            {...props}>
            {children}
        </ButtonGroup>
    );
};

export default StepButtonGroup;

