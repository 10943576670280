import React from "react";
import { useMediaQuery } from "react-responsive";

function Pc({ children, ...props }) {
  const isPc = useMediaQuery({
    query: "(min-width:1200px)",
  });
  return <React.Fragment {...props}>{isPc && children}</React.Fragment>;
}

function Tablet({ children, ...props }) {
  const isTablet = useMediaQuery({
    query: "(min-width: 641px) and (max-width:1199px) ",
  });
  return <React.Fragment {...props}>{isTablet && children}</React.Fragment>;
}

function Mobile({ children, ...props }) {
  const isMobile = useMediaQuery({
    query: "(max-width:640px) ",
  });
  return <React.Fragment {...props}>{isMobile && children}</React.Fragment>;
}

export { Pc, Tablet, Mobile };
