import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import { renderToString } from "react-dom/server";
import { getLocalStorageDecrypt, setLocalStorageEncrypt, numberWithCommas, getQueryStringObject, promotionDisCountMonth } from '../../lib/Util'
import { INIT_MOSILER_URL } from '../../InitConst';

import dojang from '../../img/vutler_dojang.png'
import Loading from '../../components/Loading';

const EstimateExportView = (props) => {
    const [reportData, setReportData] = useState({
        productCategoryName             :'월간모시러',
        useUnit                         :'1년',
        oneDayHour                      :'8',
        UseHour                         :'80',
        HourPrice                       :'10000',
        AllPrice                        :'1000000',
        UserName                        :'아무개',
        productCategory                 : 0,
        promotionDiscount               : 0,
        discountAmount                  : 0,
        contractViewFlag                : false,
    })
    
    // 630 x 891
    // window size로 따지나봄.. 사이즈와 상관없이 처리되어야함.   
    //https://threeyears.tistory.com/224
    
    const print = async () => {

        let canvas = await html2canvas(document.querySelector('#save_pdf'),{
            width:635,
            // height:891
            height:950
        })
        let imgData = canvas.toDataURL('image/png')
        let pdf = new jsPDF('p','mm','a4')
        pdf.addImage(imgData, 'PNG', 10,10,190,260)
        pdf.save(`견적서_${moment().format('yyyyMMDDHHssmm')}`)

        //result.toDataURL
        /**, {
            onrendered: function(canvas) {
                let imgData = canvas.toDataURL('image/png')
                let pdf = new jsPDF('p','mm','a4')
                pdf.addImage(imgData, 'PNG', 10,10,190,95)
                pdf.save()
            }
        } */



/*
        
        console.log(window.customFont.length)
        pdf.addFileToVFS('malgun-normal.ttf', window.customFont);
        pdf.addFont('malgun-normal.ttf', 'malgun', 'normal');
        pdf.setFont('malgun');

        pdf.html(document.querySelector('#save_pdf'), {
            width:635,
            windowWidth:1920,
            callback: function(doc) {
                doc.save()
            }
        })*/
    }
    const sleep = (time) => {
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve()
            }, time);
        })
        
    }



    useEffect(()=>{
        async function init() {
            let tokenChkResult = false;
            let token0 = getLocalStorageDecrypt('token0');      // 고객정보
            let token1 = getLocalStorageDecrypt('token1');      // 견적 정보
            let token2 = getLocalStorageDecrypt('token2');      // 계약 정보
            
            let contractFlag = false;
            try {
                let obj = getQueryStringObject();
                if (obj.contract !== undefined) {
                    contractFlag = true;
                }
            }
            catch (e) {
                contractFlag = false;
            }


            if (token0 !== null && token1 !== null) {
                tokenChkResult = true;
            }

            if (tokenChkResult) {
                
                setReportData({
                    productCategoryName  : token1.productCategory === 0 ? '월간모시러':'연간모시러',
                    useUnit              : token1.productCategory === 0 ? '1개월':'1년',
                    oneDayHour           : token1.selectedOneDayHour,
                    UseHour              : token1.selectedHour,
                    HourPrice            : token1.selectedHourPrice,
                    AllPrice             : (Number(token1.selectedHour) * Number(token1.selectedHourPrice)),
                    UserName             : token0.UserName,
                    productCategory      : token1.productCategory,
                    // promotionDiscount    : (token2 !== null) ? promotionDisCountMonth(token2.ContractMonth) : 0,
                    promotionDiscount    : 0,
                    discountAmount       : 0,
                    contractViewFlag     : contractFlag
                })

                
                
                await sleep(1000)
                print()
                await sleep(1000)
                
                window.close()
                

            }
            else {
                alert('견적서를 작성할 수 없습니다. \n기본정보 입력 후 다시 시도해주세요.')
                document.location.href = INIT_MOSILER_URL
            }
        }
        init();
    },[])


    let discount = Math.floor((reportData.HourPrice * (reportData.promotionDiscount / 100)) *  reportData.UseHour);
    let discountHourPrice = Math.ceil(reportData.HourPrice - (reportData.HourPrice * (reportData.promotionDiscount / 100)))
    let resultAmount = reportData.AllPrice - discount
    let promotionDiscountFlag = ( reportData.promotionDiscount >= 1)
    
    return (
        <>
            <div style={{width:'100%', height:'100%', position:'absolute', backgroundColor:'white', textAlign:'center', fontSize:'1.2em', paddingTop:'30px'}}>
                <div>현재 견적서 다운로드 처리를 위해 작업중입니다.</div>
                <div>잠시만 기다려주십시오.</div>
                <div>이 창을 닫지 말아주세요.</div>
                <div className="loader loader-9"></div>
            </div>
            
            <div className='estimate_component' id="save_pdf">
                <div className='title'>
                    {reportData.productCategoryName} 견적서
                </div>
                <div className='sub_title'>
                    ({reportData.UseHour}시간 상품)
                </div>
                <div className='sub_row_component padding_top'>
                    <div className='left'>
                        {moment().format('yyyy년 MM월 DD일')}
                    </div>
                    <div className='right'>
                        <div>{reportData.UserName} 고객님</div>
                        <div>아래와 같이 견적서를 발송합니다.</div>
                    </div>
                </div>
                <div className='price_info'>
                    <table >
                        <thead>
                            <tr>
                                <th style={{ width: '20%' }}>품목</th>
                                <th style={{ width: '15%' }}>이용단위</th>
                                <th style={{ width: '15%' }}>1일(건)<br />최소이용시간</th>
                                <th style={{ width: '15%' }}>기본이용시간</th>
                                <th style={{ width: '20%' }}>단가<br />(per hour)</th>
                                <th style={{ width: '40%' }}>금액</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{reportData.productCategoryName} {reportData.UseHour}시간</td>
                                <td>{reportData.useUnit}</td>
                                <td>{reportData.oneDayHour}</td>
                                <td>{reportData.UseHour}시간</td>
                                <td>{numberWithCommas(reportData.HourPrice)}원</td>
                                <td>{numberWithCommas(reportData.AllPrice)}원</td>
                            </tr>
                            <tr>
                                <td colSpan={6}>&nbsp;</td>
                            </tr>
                            <tr>
                                <td colSpan={6}>&nbsp;</td>
                            </tr>

                            {(reportData.promotionDiscount >= 1 && reportData.contractViewFlag && reportData.productCategory === 0) ?
                                <>
                                    <tr className='total'>
                                        <td colSpan={5} style={{ paddingLeft: '15px', fontWeight: 'bold' }}>SUM</td>
                                        <td colSpan={5} style={{ paddingLeft: '10px', fontWeight: 'bold' }}>{numberWithCommas(reportData.AllPrice)}원</td>
                                    </tr>
                                    <tr className='total2'>
                                        <td colSpan={5} style={{ paddingLeft: '15px', fontWeight: 'bold' }}>DISCOUNT PROMOTION</td>
                                        <td colSpan={5} style={{ paddingLeft: '10px', fontWeight: 'bold', color: 'red' }}>{reportData.promotionDiscount}% OFF -{numberWithCommas( discount)}원</td>
                                    </tr>
                                    <tr className='total2'>
                                        <td colSpan={5} style={{ paddingLeft: '15px', fontWeight: 'bold' }}>ESTIMATED TOTAL</td>
                                        <td colSpan={5} style={{ paddingLeft: '10px', fontWeight: 'bold' }}>{numberWithCommas(resultAmount)}원</td>
                                    </tr>
                                </>
                                :
                                <tr className='total'>
                                    <td colSpan={5} style={{ paddingLeft: '15px', fontWeight: 'bold' }}>ESTIMATED TOTAL</td>
                                    <td colSpan={5} style={{ paddingLeft: '10px', fontWeight: 'bold' }}>{numberWithCommas(reportData.AllPrice)}원</td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
                {/* 월간모시러 */}
                {reportData.productCategory === 0 ? 
                <div className='info'>
                    
                    {(reportData.contractViewFlag && discountHourPrice >= 1 && promotionDiscountFlag) ? <div><b>본 견적 내 항목은 모두 VAT 포함이며, 할인기간 동안 시간당 단가는 '{numberWithCommas(discountHourPrice)}원'이 적용됩니다.</b></div> : <div><b>본 견적 내 항목은 모두 VAT 포함입니다.</b></div> }
                    <div><b>실탑승자 5인</b>까지를 기준으로 한 견적이며, 6인 이상 이용이 필요하실 경우 컨시어지로 문의 바랍니다.</div>
                    <div>기본 이용시간 외 추가 이용도 가능하며, 시간당 단가가 반영되어 추가 청구됩니다.</div>
                    <div>본 견적서 내 포함되지 않은 항목이라도 실제 서비스 이용에 따라 할증 요금이 사전 안내 후 청구될 수 있습니다.</div>
                    <div>예상 출발지/종료지가 서울 이외일 경우, 지역할증이 부과될 수 있습니다.</div>
                    <div>본 견적서의 내용은 <b>{moment().add(30,'days').format('yyyy년 MM월 DD일')}</b>까지 유효합니다.</div>
                </div> : 
                // 연간모시러
                <div className='info'>
                    <div><b>본 견적 내 항목은 모두 VAT 포함입니다.</b></div>
                    <div><b>실탑승자 5인</b>까지를 기준으로 한 견적이며, 6인 이상 이용이 필요하실 경우 컨시어지로 문의 바랍니다.</div>
                    <div>구입 시간 모두 소진 시 추가 구입이 가능합니다.</div>
                    <div>본 견적서 내 포함되지 않은 항목이라도 실제 서비스 이용에 따라 할증 요금이 사전 안내 후 청구될 수 있습니다.</div>
                    <div>예상 출발지/종료지가 서울 이외일 경우, 지역할증이 부과될 수 있습니다.</div>
                    <div>본 견적서의 내용은 <b>{moment().add(30,'days').format('yyyy년 MM월 DD일')}</b>까지 유효합니다.</div>
                </div>}
                

                
                <div className='seperator'>&nbsp;</div>
                <div className='sub_row_component2'>
                    <div className='left'>
                        <div><b>주식회사 버틀러</b></div>
                        <div>서울특별시 강남구 역삼로 160, 5층</div>
                    </div>
                    <div className='right'>
                        <div>463-87-00162</div>
                        <div>이근우</div>
                    </div>
                    <div className='dojang'>
                        <img src={dojang} />
                    </div>
                </div>
            </div>

        </>
    );
};

export default EstimateExportView;