import "aos/dist/aos.css";
import React from "react";
import styled from "styled-components";
import IconIntro01 from "../../assets/images/icon-intro-01.png";
import IconIntro02 from "../../assets/images/icon-intro-02.png";
import IconIntro03 from "../../assets/images/icon-intro-03.png";
import GridItem from "./Layout/GridItem";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import ThreeColumnGrid from "./Layout/ThreeColumnGrid";
import SubTitle from "./Text/SubTitle";
import TextBlock from "./Text/TextBlock";
function IntroductionContainer({ type, children }) {
  return (
    <Section padding="100px 0" background="#FAFAFA">
      <InnerSection>
        <SubTitle
          fontSize="40"
          fontWeight="300"
          data-aos="fade-up"
          data-offset="120"
        >
          <strong>{type === 'mm' ? '월간 모시러' : '연간 모시러'}</strong>는 수행기사 서비스를 <br />
          가장 효율적으로 이용할 수 있는 구독 프로그램입니다.
        </SubTitle>
        <ColumnGrid>
          <GridItem data-aos="fade-up" data-aos-delay="100">
            <IntroductionIcon src={IconIntro01} alt="icon" />
            <ItemTitle textAlign="center">{type === 'mm' ? '월' : '연'} 단위의 자유로운 이용</ItemTitle>
            <TextBlock fontSize="16" textAlign="center">
              {type === 'mm' ? <>
              최소 1개월부터 필요한 기간만큼 선택하여 <br />
              이용할 수 있습니다.
              </> : <>
              1년 내 필요한 일정에 맞춰 유연하고<br />
              합리적으로 이용할 수 있습니다.
              </>}
              
            </TextBlock>
          </GridItem>
          <GridItem data-aos="fade-up" data-aos-delay="150">
            <IntroductionIcon src={IconIntro02} alt="icon" />
            <ItemTitle textAlign="center">
              관리 비용이 포함된 합리적인 요금
            </ItemTitle>
            <TextBlock fontSize="16" textAlign="center">
              드라이버 보험, 식비 등 관리 비용에 <br />
              대한 고민없이 이용이 가능합니다.
            </TextBlock>
          </GridItem>
          <GridItem data-aos="fade-up" data-aos-delay="200">
            <IntroductionIcon src={IconIntro03} alt="icon" />
            <ItemTitle textAlign="center">선호하는 드라이버 선택</ItemTitle>
            <TextBlock fontSize="16" textAlign="center">
              고객 성향에 맞는 최적의 드라이버를 <br />
              제안해 드립니다.
            </TextBlock>
          </GridItem>
        </ColumnGrid>
      </InnerSection>
    </Section>
  );
}
const IntroductionIcon = styled.img`
  display: block;
  width: 348px;
  max-width: 100%;
  margin: 0 auto 42px;
  @media (max-width: 640px) {
    width: 150px;
    margin: 0 auto 24px;
  }
`;
const ColumnGrid = styled(ThreeColumnGrid)`
  margin-top: 82px;
  @media (max-width: 640px) {
    margin-top: 60px;
  }
`;
const ItemTitle = styled(TextBlock).attrs({
  fontWeight: `bold`,
  fontSize: `24`,
})`
  margin-bottom: 12px;
`;
export default IntroductionContainer;
