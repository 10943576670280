import React from "react";
import styled from "styled-components";
import YearlyPlanImageMobile from "../../assets/images/img-yearly-plan-mobile.png";
import YearlyPlanImage from "../../assets/images/img-yearly-plan.png";
import { Mobile, Pc, Tablet } from "../../hooks/useMediaQuery";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import TextBlock from "./Text/TextBlock";
import SubTitle from "./Text/SubTitle";
function MonthlyMosilerContainer() {
  return (
    <Section padding="100px 0">
      <InnerSection>
        <SubTitle fontSize="40" data-aos="fade-up">
          월간 모시러 이용 예
        </SubTitle>
        <div style={{paddingTop:'20px'}}></div>
        <Box data-aos="fade-up">
          <Pc>
            <PartnerProcessImage src={YearlyPlanImage} />
          </Pc>
          <Tablet>
            <PartnerProcessImage src={YearlyPlanImage} />
          </Tablet>
          <Mobile>
            <PartnerProcessImage src={YearlyPlanImageMobile} />
          </Mobile>
        </Box>
        <TextBlock
          textAlign="center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          월간 모시러는 고용하지 않고 <strong>월마다 원하는 기간</strong>에
          편하게 이용할 수 있습니다.
        </TextBlock>
      </InnerSection>
    </Section>
  );
}
const Box = styled.div`
  text-align: center;
  margin: 0 0 28px 0;
`;
const PartnerProcessImage = styled.img`
  max-width: 100%;
  @media (max-width: 640px) {
    width: 281px;
  }
`;
export default MonthlyMosilerContainer;
