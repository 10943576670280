import React from 'react';
import styled from '@emotion/styled';

const InputFileContainer = styled.div`
    position: relative;
`;
const InputFile = styled.input`
    &::file-selector-button {
        width: 66px;
        height: 27px;
        font-size: 12px;
        line-height: 25px;
        background: #f7f7fc;
        border: 1px solid #2d2d2d;
        border-radius: 4px;
        position: absolute;
        right: 10px;
        cursor: pointer;
        color: #2d2d2d;
    }
`;
const FloatLabelFile = ({ formId, placeholder, name, value, onChange }) => {
    
    return (
        <>
            <InputFileContainer className='floatlabel_component' >
                <label htmlFor={formId}><span className="default-focus">{name}</span>
                <InputFile 
                    type="file" 
                    className='floatlabel'
                    id={formId} 
                    name={formId} 
                    placeholder={placeholder} 
                    onChange={(e)=>{
                        onChange(e.target.files[0])
                    }} 
                    accept="image/png, image/jpeg, application/pdf"
                    autoComplete='off'
                />
                </label>
            </InputFileContainer>
        </>
    );
};

export default FloatLabelFile;

