import React from "react";
import styled from "styled-components";
function Section({ padding, background, children, ...props }) {
  return (
    <SectionContainer
      padding={padding ? padding : `0px`}
      background={background ? background : `transparent`}
      {...props}
    >
      {children}
    </SectionContainer>
  );
}
const SectionContainer = styled.div`
  padding: ${({ padding }) => padding};
  background: ${({ background }) => background};
  width: 100%;
  @media (max-width: 640px) {
    padding: ${({ padding }) => {
      if (padding === `100px 0`) return `60px 0`;
      if (padding === `120px 0`) return `60px 0`;
    }}px;
  }
`;
export default Section;
