import React from "react";
import styled from "styled-components";
function Table({ children, ...props }) {
  return <TableWrapper {...props}>{children}</TableWrapper>;
}
const TableWrapper = styled.table`
  border: 1px solid #ecebeb;
  width: 100%;
  thead {
    th {
      font-weight: bold;
    }
  }
  th {
    background: #4b4b4b;
    color: #fff;
  }
  th,
  td {
    border: 1px solid #ebebeb;
    text-align: center;
    font-weight: 300;
    height: 70px;
    vertical-align: middle;
    position: relative;
  }
  @media (max-width: 640px) {
    th,
    td {
      font-size: 12px;
      height: 38px;
    }
  }
`;
export default Table;
