import React from "react";
import styled from "styled-components";
import IconTooltip from "../../assets/images/icon-tooltip.png";
import { Mobile, Pc, Tablet } from "../../hooks/useMediaQuery";
import DriverTooltips from "./DriverTooltips";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import Table from "./Table/Table";
import SubTitle from "./Text/SubTitle";
function TeamSystemIntegratedContainer({ children }) {
  return (
    <Section padding="100px 0" background="#FAFAFA">
      <InnerSection>
        <SubTitle fontSize="40" data-aos="fade-up" data-aos-duration="1000">
          월간모시러 상품
        </SubTitle>
        <TableContainer data-aos="fade-up" data-aos-duration="1000">
          <Table>
            <colgroup>
              <Pc>
                <col style={{ width: `30%` }} />
                <col style={{ width: `17.5%` }}/>
                <col style={{ width: `17.5%` }}/>
                <col style={{ width: `17.5%` }}/>
                <col style={{ width: `17.5%` }}/>
                
              </Pc>
              <Tablet>
              <col style={{ width: `30%` }} />
                <col style={{ width: `17.5%` }}/>
                <col style={{ width: `17.5%` }}/>
                <col style={{ width: `17.5%` }}/>
                <col style={{ width: `17.5%` }}/>
              </Tablet>
              <Mobile>
              <col style={{ width: `30%` }} />
                <col style={{ width: `17.5%` }}/>
                <col style={{ width: `17.5%` }}/>
                <col style={{ width: `17.5%` }}/>
                <col style={{ width: `17.5%` }}/>
              </Mobile>
              
            </colgroup>
            <thead>
              <tr>
                <th>상품</th>
                <th>10일</th>
                <th>14일</th>
                <th>20일</th>
                <th>30일</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>기본시간</th>
                <td>80</td>
                <td>112</td>
                <td>160</td>
                <td>240</td>
              </tr>
              <tr>
                <th>최소 이용 시간</th>
                <td>8</td>
                <td>8</td>
                <td>8</td>
                <td>8</td>
              </tr>
              <tr>
                <th>
                  드라이버 풀{" "}
                  <ButtonTooltip data-tooltip-id="driverPoolTip01">
                    <Image src={IconTooltip} alt="tooltip" />
                  </ButtonTooltip>
                </th>
                <td>5</td>
                <td>3</td>
                <td>3</td>
                <td>3</td>
              </tr>
              <tr>
                <th>
                  추천 드라이버{" "}
                  <ButtonTooltip data-tooltip-id="driverPoolTip02">
                    <Image src={IconTooltip} alt="tooltip" />
                  </ButtonTooltip>
                </th>
                <td>5</td>
                <td>5</td>
                <td>10</td>
                <td>10</td>
              </tr>
            </tbody>
          </Table>
        </TableContainer>
      </InnerSection>
      <DriverTooltips />
    </Section>
  );
}
const TableContainer = styled.div`
  margin-top: 40px;
  @media (max-width: 640px) {
    margin-top: 24px;
  }
`;
const ButtonTooltip = styled.button`
  background: transparent;
  width: 18px;
  @media (max-width: 640px) {
    width: 12px;
  }
  
  outline: none;
  border: none;
  vertical-align: middle;
  cursor: pointer;
`;
const Image = styled.img`
  width: 100%;
`;
export default TeamSystemIntegratedContainer;
