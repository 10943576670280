import "aos/dist/aos.css";
import React from "react";
import styled from "styled-components";
import IconBenefit01 from "../../assets/images/icon-benefit-01.png";
import IconBenefit02 from "../../assets/images/icon-benefit-02.png";
import IconBenefit03 from "../../assets/images/icon-benefit-03.png";
import GridItem from "./Layout/GridItem";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import ThreeColumnGrid from "./Layout/ThreeColumnGrid";
import SubTitle from "./Text/SubTitle";
import TextBlock from "./Text/TextBlock";
function MosilerBenefitContainer({ children }) {
  return (
    <Section padding="100px 0" background="#FAFAFA">
      <InnerSection>
        <SubTitle fontSize="40" data-aos="fade-up">
          월간 모시러 장점
        </SubTitle>
        <ThreeColumnGrid style={{ marginTop: `53px` }}>
          <GridItem data-aos="fade-up" data-aos-delay="100">
            <BenefitIcon src={IconBenefit01} alt="icon" />
            <ItemTitle textAlign="center">고용</ItemTitle>
            <TextBlock fontSize="16" textAlign="center">
              퇴직금 등 부대비용 <br />
              부담없이
            </TextBlock>
          </GridItem>
          <GridItem data-aos="fade-up" data-aos-delay="150">
            <BenefitIcon src={IconBenefit02} alt="icon" />
            <ItemTitle textAlign="center">관리</ItemTitle>
            <TextBlock fontSize="16" textAlign="center">
              주 52시간, 인력관리 <br />
              걱정없이
            </TextBlock>
          </GridItem>
          <GridItem data-aos="fade-up" data-aos-delay="200">
            <BenefitIcon src={IconBenefit03} alt="icon" />
            <ItemTitle textAlign="center">맞춤</ItemTitle>
            <TextBlock fontSize="16" textAlign="center">
              필요에 맞게 스케줄 <br />
              커스터마이징
            </TextBlock>
          </GridItem>
        </ThreeColumnGrid>
      </InnerSection>
    </Section>
  );
}
const BenefitIcon = styled.img`
  display: block;
  width: 292px;
  max-width: 100%;
  margin: 0 auto 28px;
  @media (max-width: 640px) {
    width: 150px;
    margin: 0 auto 24px;
  }
`;
const ItemTitle = styled(TextBlock).attrs({
  fontWeight: `bold`,
  fontSize: `24`,
})`
  margin-bottom: 12px;
  @media (max-width: 640px) {
    margin-bottom: 4px;
  }
`;
export default MosilerBenefitContainer;
