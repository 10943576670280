import "aos/dist/aos.css";
import React from "react";
import styled from "styled-components";
import MainSectionBg from "../../assets/images/bg-header.jpg";
import MainSectionBgYm from "../../assets/images/bg-header-ym.jpg";
import Logo from "../../assets/images/icon-logo.png";
import InnerSection from "./Layout/InnerSection";
import Section from "./Layout/Section";
import TextBlock from "./Text/TextBlock";
import Title from "./Text/Title";

function MainContainer({type}) {
  return (
    <>
      {type === 'mm' ? 
        <Container>
          <MosilerLogo src={Logo} alt="Mosiler" />
          <Section>
            <InnerSection>
              <Title color="#fff" data-aos="fade-up" data-offset="120">
              월간 모시러
              </Title>
              <TextBlock
                color="#fff"
                fontSize="24"
                data-aos="fade-up"
                data-aos-delay="200"
                data-offset="120"
              >
                시간제 수행기사 서비스 구독형 프로그램
              </TextBlock>
            </InnerSection>
          </Section>
        </Container>  
        :
        <ContainerYm>
          <MosilerLogo src={Logo} alt="Mosiler" />
          <Section>
            <InnerSection>
              <Title color="#fff" data-aos="fade-up" data-offset="120">
              연간 모시러
              </Title>
              <TextBlock
                color="#fff"
                fontSize="24"
                data-aos="fade-up"
                data-aos-delay="200"
                data-offset="120"
              >
                시간제 수행기사 서비스 구독형 프로그램
              </TextBlock>
            </InnerSection>
          </Section>
        </ContainerYm>  
    }
      
    </>
  );
}
const Container = styled.div`
  position: relative;
  height: 520px;
  background: url(${MainSectionBg}) no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 640px) {
    height: 292px;
  }
  ${InnerSection} {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
`;
const ContainerYm = styled.div`
  position: relative;
  height: 520px;
  background: url(${MainSectionBgYm}) no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 640px) {
    height: 292px;
  }
  ${InnerSection} {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
`;
const MosilerLogo = styled.img`
  width: 76px;
  position: absolute;
  right: 24px;
  bottom: 32px;
  @media (max-width: 640px) {
    width: 51px;
    right: 16px;
    bottom: 16px;
  }
`;

export default MainContainer;
