export const INIT_MOSILER_URL = 'http://mosiler.com/monthly_221028.html#page8'
export const API_ADMIN_URL = 'http://apitest.admin.mosiler.com'
// export const API_ADMIN_URL = 'http://localhost:24315'

export const MANAGE_ADMIN_URL = 'http://test.manage.mosiler.com'
export const AZURE_BLOB_URL = 'https://mosilerattachfile.blob.core.windows.net/business-license/'
export const SLACK_URL = "https://hooks.slack.com/services/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";
export const CUSTOM_ID = 99
export const ETC_ID = 100
//export const API_ADMIN_URL = 'http://api.admin.mosiler.com'

export const BUILD_TARGET_URI = ""
