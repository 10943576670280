import styled from "styled-components";
function FormItem({ button, children, ...props }) {
  return <Item {...props}>{children}</Item>;
}
const Item = styled.li`
  & + li {
    margin-top: 24px;
  }
`;
export default FormItem;
