import React from "react";
import styled from "styled-components";

function InnerSection({ children, ...props }) {
  return <InnerContainer {...props}>{children}</InnerContainer>;
}
const InnerContainer = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 4%;
    box-sizing: border-box;
  }
`;
export default InnerSection;
