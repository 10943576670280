import React from 'react';
import styled from '@emotion/styled';

const Title = styled.div`
    border-bottom: 1px solid #D2DCE6;
    padding-bottom: 20px;
    margin-bottom: 20px;
    line-height: 28px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media only screen and (max-width: 600px) {
        padding-bottom: 8px;
        margin-bottom: 12px;
    }
`;
const TitleText = styled.h3`
    font-weight: bold;
    color: #000;
    font-size: 28px;
    span {
        color: #888;
        font-size: 16px;
        display: inline-block;
        vertical-align: bottom;
        padding-left: 8px;
    }
    @media only screen and (max-width: 900px) {
        font-size: 24px;
        span {
            font-size: 14px;
        }
    }
    @media only screen and (max-width: 600px) {
        font-size: 20px;
    }
`;

const TitleWithBorder = ({ title, subtext, children }) => {
    return (
        <Title>
            <TitleText>
                {title}
                {subtext && (<span>{subtext}</span>)}
            </TitleText>
            {children}
        </Title>
    );
};

export default TitleWithBorder;

