import React, { useEffect, useState } from 'react';
import { getQueryStringObject } from '../../lib/Util'
import Header from '../../components/Header';
import StepButtonGroup from '../../components/StepButtonGroup';
import ButtonStandard from '../../components/ButtonStandard';
import KakaoButton from '../../components/KakaoButton';
// import { SendSlack } from '../../lib/SlackApi';
// import { JoinFormMmExternal } from '../../lib/MosilerJoinApi';
// import { setEstimatePage02, setEstimatePage03 } from '../../lib/GoogleSheetApi';
// import { ETC_ID } from '../../InitConst';
// import Loading from '../../components/Loading';
// import moment from 'moment';

// import TitleWithBorder from '../../components/TitleWithBorder';
// import ButtonDownload from '../../components/ButtonDownload';
// import Inner from '../../components/Inner';
// import { Grid } from '@mui/material';
// import ListInformation, { InfoContainer } from '../../components/ListInformation';
// import ResultPrice from '../../components/ResultPrice';
import { BUILD_TARGET_URI } from '../../InitConst';
const BUILD_TARGET = BUILD_TARGET_URI
const ANNUAL_MOSILER = (document.location.hostname ==='year.mosiler.com' || document.location.hostname ==='yeartest.mosiler.com' || (document.location.href.indexOf('http://mosiler.com/annual') >= 0))

const ComplateView = (props) => {
    const [Intermediate, setIntermediate] = useState(false)
    useEffect(() => {
        function test() {
            const htmlTitle = document.querySelector("title");
            //htmlTitle.innerHTML = '월간모시러 웹 견적서'
            htmlTitle.innerHTML = ANNUAL_MOSILER ? '연간모시러 웹 견적서' : '월간모시러 웹 견적서'
            let obj = {}
            try {
                obj = getQueryStringObject();
                // 중도 입력자라면
                if (obj.intermediate !== undefined && obj.intermediate === '1') {
                    setIntermediate(true)
                }

            }
            catch (e) {
                setIntermediate(false)
            }
        }
        test();
    })
    return (
        <>
            <KakaoButton />
            <div className='main_component'>
                <Header step="5" history={props.history} Intermediate={Intermediate} />
                <div className='content'>
                    <div className='complate_component'>
                        <div className='title'>계약서 초안 작성이 완료되었습니다.</div>
                        <div className='title'>계약은 모두싸인으로 진행됩니다.</div>
                        <br /><br />
                        <div className='test'>
                            <div className='comment'>※ 월~금 09:00 ~ 16:00내에 초안 작성 완료건에 대해서는 당일 내에 메일주소로 계약서를 보내드립니다.</div>
                            <div className='comment'>※ 16시 이후에는 익일 내 메일주소로 계약서를 보내드립니다.(주말일 경우 차주 월요일에 계약서 발송)</div>
                        </div>
                    </div>
                    <div className='footer_button'>
                        <StepButtonGroup flex={true} alignItems="center" justifyContent="center">
                            <ButtonStandard onClick={() => {
                                // document.location.href = "kakaotalk://inappbrowser/close"
                                // window.close()
                                if (Intermediate) {
                                    props.history.replace(`${BUILD_TARGET}/contract`)
                                }
                                else {
                                    props.history.replace(`${BUILD_TARGET}/estimate`)
                                }
                                
                            }}>
                                확인
                            </ButtonStandard>
                        </StepButtonGroup>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ComplateView;

