import axios from 'axios';
const TIME_OUT = 10000

const getInflowTypeToLink = (type) => {
    let retData = '';
    switch(type) {
        case 'instagram_w':
        case 'facebook_w':
            retData = 'http://mosiler.com/busan'
            break;
        case 'homepage_auto':
            retData = 'http://mosiler.com/web_estimate'
            break;
        case 'monthly':
            retData = 'http://mosiler.com/monthly'
            break;
        case 'annual':
            retData = 'http://mosiler.com/annual'
            break;
        case 'instagram_auto':
        case 'monthly_estimate':
            retData = 'http://estimate.mosiler.com'
            break;
        case 'annual_year':
            retData = 'http://year.mosiler.com'
            break;
        default:
            retData = 'http://mosiler.com/monthly'
            break;
    }
    return retData;
}

const keyToObj =(key, name, phone, inflow) => {
    var retData = {};
    /*
    key 매개변수 설명
    introduce : 소개서
    estimate : 견적서
    contract : 계약서
    */
    switch(key){
        case 'introduce':
            retData = {
                template_code: 'mosiler_230306_498',
                receiver_num: phone,
                message: `안녕하십니까 ${name} 고객님\n시간제 수행기사 서비스\n모시러입니다.\n\n월간모시러 서비스 문의 주셔서\n감사합니다.\n\n검토하신 후 아래 버튼을 눌러 \n견적을 확인해보세요.\n\n감사합니다.\n\n\n컨시어지 : 1522-4556`,
                button1: {
                    name: '견적 확인하기',
                    type: 'WL',
                    url_mobile: getInflowTypeToLink(inflow) + '/estimate',
                    url_pc: getInflowTypeToLink(inflow) + '/estimate'
                }
            }
            break;

        case "estimate":
            retData = {
                template_code: 'mosiler_230306_503',
                receiver_num: phone,
                message: `안녕하십니까 ${name} 고객님\n시간제 수행기사 서비스 모시러입니다.\n\n견적 확인해주셔서 감사합니다.\n계약을 원하시면 아래 버튼을 눌러\n내용 작성 부탁드립니다.\n\n추가 문의사항은 채팅창 또는\n컨시어지로 연락 주시면\n상담 도와드리겠습니다.\n\n\n컨시어지: 1522-4556`,
                button1: {
                    name: '계약정보 입력',
                    type: 'WL',
                    url_mobile: getInflowTypeToLink(inflow) + '/contract',
                    url_pc: getInflowTypeToLink(inflow) + '/contract'
                }
            }
            break;

        default:
            break;
    }
    return retData;
}

export const SendEztoc = (obj) => {
    let objData = keyToObj(obj.eztocType, obj.UserName, obj.UserPhone, obj.inflow)
    if (!Object.keys(objData).length) {
        return new Promise( (resolve, reject)=>{
            resolve({})
        })
    }
    return new Promise( (resolve, reject)=>{
        axios({
            url : 'http://vtest.mosiler.com/api/eztalk/general/msgbtn',
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type':'application/json',
            },
            data: objData
        }).then( async res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}