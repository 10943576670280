import React from 'react';
import styled from '@emotion/styled';

export const InfoContainer = styled.div`
    background-color: #fff;
    border: 1px solid #ECEEF2;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 30px 32px;
    @media only screen and (max-width: 600px) {
        padding: 24px;
    }
`;
const InfoItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    & + div {
        margin-top: 16px;
    }
    @media only screen and (max-width: 600px) {
        font-size: 14px;;
    }
`;
const InfoName = styled.div`
    width: 100%;
`;
const InfoData = styled.div`
    color: #888;
    width: 100%;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ListInformation = ({  name, children, ...props }) => {
    return (
        <>
            <InfoItem>
                <InfoName>{name}</InfoName>
                <InfoData>{children}</InfoData>
            </InfoItem>
        </>
    );
};

export default ListInformation;

