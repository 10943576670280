import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Mobile, Pc, Tablet } from "../../../hooks/useMediaQuery";
function StyledTooltip({ id, children, ...props }) {
  return (
    <>
      <Pc>
        <Tooltip
          id={id}
          effect="solid"
          place="right"
          noArrow
          variant="light"
          style={{
            padding: `0px`,
            overFlow: `hidden`,
            opacity: `1`,
            borderRadius: `8px`,
            zIndex: 10,
          }}
          {...props}
        >
          {children}
        </Tooltip>
      </Pc>
      <Tablet>
        <Tooltip
          id={id}
          effect="solid"
          place="right"
          noArrow
          variant="light"
          style={{
            padding: `0px`,
            overFlow: `hidden`,
            opacity: `1`,
            borderRadius: `8px`,
            zIndex: 10,
          }}
          {...props}
        >
          {children}
        </Tooltip>
      </Tablet>
      <Mobile>
        <Tooltip
          id={id}
          effect="solid"
          place="bottom"
          noArrow
          variant="light"
          style={{
            padding: `0px`,
            overFlow: `hidden`,
            opacity: `1`,
            borderRadius: `8px`,
            zIndex: 10,
          }}
          {...props}
        >
          {children}
        </Tooltip>
      </Mobile>
    </>
  );
}
export default StyledTooltip;
